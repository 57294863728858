
import { Pie } from '@ant-design/plots';
import Heading from './Heading';


const PieGraph = ({ heading, subheading, M1, M2, M3, M4,slack ,HOC,TFC,VS}) => {
  let slackValue 
  slack?slackValue=slack
  :
  slackValue = slackValue < 0 ? slackValue = 0 : Number(M1) - (Number(M2)+ Number(M3) + Number(M4));; 

     M4= Number(M4)<0?M4=0:M4;
     M3= Number(M3)<0?M3=0:M3;

     M2= Number(M2)<0?M2=0:M2;
     HOC= Number(HOC)<0?0:HOC;

     slackValue= slackValue>0?slackValue:0
    //  const temp=M2?M2:HOC
    //  const t=temp===HOC?"New Housing Expenses": "Monthly Expenses"
    //  const t2=M4?"Housing | Rent":"Monthly Expenses"
    //  const t2Value=M4?M4:M2
   const data = [
    {
      type: "Monthly Slack",
      value: Number(slackValue),
    },
    {
      type: "Housing | Rent",
      value: Number(M4),
    },
    {
       type:"Monthly Expenses",
       value:Number(M2)
    },
    {
      type: "Monthly Debt",
      value: Number(M3),
    },
    {
      type:"New Housing Expenses",
      value: Number(HOC),
    },

  ];
  {
  TFC?data.push({
    type:'Fixed Costs',
    value:Number(TFC)
  }):TFC=null
  }

  {
    VS?data.push({
      type:'Value Spending',
      value:Number(VS)
    }):VS=null
    }

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#5b67ea', 'rgba(64, 78, 237, 0.35)', '#9ea1c2', '#219F94','#719F94'],
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name} \n {percentage} ({value}) ',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <div className='space-y-4'>
      {
        heading?
        <Heading text={heading} />:''
      }
      {
        subheading &&
        <div className='flex justify-center'>
          <h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>{subheading}</h2>
        </div>
      }
      <Pie {...config} />
    </div>
  );
};

export default PieGraph;