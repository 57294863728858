import React, { useEffect, useState, useRef } from "react";
import BottomNav from "../components/BottomNav";
import Container from "../components/Container";
import Description from "../components/Description";
import FinancialHealthCheckup from "../components/FinancialHealthCheckup";
import Heading from "../components/Heading";
import Input from "../components/Input";
import PieGraph from "../components/PieGraph";
import PieGraphINV from "../components/PieGraphINV";
import ResultInput from "../components/ResultInput";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Cell, ResponsiveContainer } from 'recharts';
import LineChart from "../components/LineChart";
import DMPieGraph from "../components/DMPieGraph";
import { Form } from "react-router-dom";
import DescriptionItem from "../components/DescriptionItem";
import { DMLineChart } from "../components/DMLineChart";
import { INVLineChart } from "../components/INVLineChart";
import Test from "../components/MultipleAxisGraph";
import MultipleAxisGraph from "../components/MultipleAxisGraph";
// import  DemoDMLineChart  from "../components/DemoDMLineChart.";
import LineChartPrac from "../components/LineChartPrac";
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../store/index'
import YourFHC from "../components/YourFHC";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faDownload } from '@fortawesome/free-solid-svg-icons'
 import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import emailjs from '@emailjs/browser';  

const KFormater = (num) => Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#0A1827" textAnchor="middle" dy={value < 0 ? 16 : -6} >{`${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</text>;
};

const INV = () => {
	const form = useRef();
	  const [status, setStatus] = useState('null'); /*New change */	 
	  const sendEmail = (e) => {
		e.preventDefault();
		setStatus("sending"); /*New change */
		
		emailjs.sendForm('service_ykjjhjm', 'template_k11stse', form.current, 'wwqQKgCmrGkZJXbt6')
		  .then((result) => {
			  //console.log(result.text);
			  setStatus("sent");
		  }, (error) => {
			  //console.log(error.text);
			  setStatus("error");
		  });
	  };
	  
    const counter = useSelector((state) => state.formdata)
    const dispatch = useDispatch()
    const hasCodeExecuted = useRef(false);

    const [selects, setselects] = useState();


    const [formData, setFormData] = useState({
        M1: null,
        M2: null,
        M3: null,
        M4: null,
        T1: null,
        T2: null,
        T3: null,
        T4: null,


    });

    const [dataINV, setDataINV] = useState({
        STAR: 8,
        STA: 70,
        BDAR: 3,
        BDA: 10,
        REAR: 7,
        REA: 15,
        ALAR: 5,
        ALA: 5,
        
        MA: 0,
        MT: 0,
        months: 0,
		display: "false"
    })



    const [resultData, setResultData] = useState({
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        R5: 0,
        R6: 0, //Added to calculate Debt to Asset Ratio. -VN
        R7: 0, //Added to calculate Savings Ratio. -VN
        R2Less: 0,
        saving: 0
    });

    const inputElement = document.getElementById('ELA');
	


    const [LineChartValues, setLineChartValues] = useState([])


    const [months, setMonths] = useState([])
    const [PayableLoans, setPayableLoans] = useState([])

    function getNextMonths(n) {
        const today = new Date();

        setMonths([])
        let totalMonths = []
        for (let i = 0; i < n; i++) {
            const nextMonth = new Date(today.getFullYear(), today.getMonth() + i + 1, 1);
            const month = nextMonth.toLocaleString('default', { month: 'long' });
            const year = nextMonth.getFullYear();
            totalMonths.push(`${month} ${year}`)
        }

        setMonths(totalMonths)
    }
	
	const [form1Data, setForm1Data] = useState({
			EFT: 3,
			DTI: 40,
			DTA: 30,
			DCA: 6,
			HCI: 30,
			HOCR: 20,
			SR: 10,
			DP: 20,
			HOCC: 6,
			HIRT: 5,
			PDPM: 'Lowest Balance first',
			EWRR: 4,
			
		  
		});
	useEffect(() => {
			if (sessionStorage.getItem('form1Data')){
					setForm1Data(JSON.parse(sessionStorage.getItem('form1Data')))
				}	
				
		},[])
		
    const [Slack, setSlack] = useState(0)
    const [Slack2, setSlack2] = useState(0)
    const [Networth, setNetworth] = useState(0);
    const [Networth2, setNetworth2] = useState(0);


    const changeHandler = (e) => {
        // if(e.target.id==="T1")
        // {
        //     window.alert(e.target.value)
        //     // e.target.value=0

        // }
		 let formValues = {...formData, [e.target.id]: e.target.value}
		setFormData(formValues)
		sessionStorage.setItem('formData', JSON.stringify(formValues))
		
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const dataChangeHandler = (e) => {
        // if(e.target.id==="T1")
        // {
        //     window.alert(e.target.value)
        //     // e.target.value=0

        // }
		let formValues = {...dataINV, [e.target.id]: e.target.value}
		setDataINV(formValues)
		sessionStorage.setItem('dataINV', JSON.stringify(formValues))
		
        setDataINV({
            ...dataINV,
            [e.target.id]: e.target.value
        });
    };

    const [selectedOption, setSelectedOption] = useState("Snowball");

    const [check, setCheck] = useState(false)

    const handleSelectChange = (event) => {
        // console.log(selectedOption)
        setSelectedOption(event.target.value);
    };

    const update = () => {
        Object.keys(counter).length > 0 ?
            setFormData(counter) :
            setFormData(formData)
    }
	
	useEffect(() => {
		if (sessionStorage.getItem('dataINV')){
				setDataINV(JSON.parse(sessionStorage.getItem('dataINV')))
			}
		if (sessionStorage.getItem('formData')){
				setFormData(JSON.parse(sessionStorage.getItem('formData')))
			}	
	},[]);

    useEffect(() => {
		document.title = 'SYMR | Investing';
        if (!hasCodeExecuted.current) {

            //update()
            hasCodeExecuted.current = true;
            window.scrollTo(0, 0);

        }
        const { M1, M2, M3, M4, T1, T2, T3, T4 } = formData;
        setResultData({
            R1: (Number(M3) / Number(M1)) * 100,
            R2: Number(T1) / (Number(M2) + Number(M3) + Number(M4)) * 100,
            R3: ((Number(T1) + Number(T2)) /  (Number(T3)) * 100),	// Fixed to check if T3 is null. If null, the denominator is set to 1. VN
            R4: ((Number(T1) + Number(T2)) / (Number(M3) + Number(M4)) * 100), // Fixed to add assets T2 to the calculation. VN
            R5: (Number(M4) / Number(M1) * 100),
            R6: (Number(T3) / (Number(T1) + Number(T2))) * 100, //New Result variable added to check the debt ratio if high. - VN
            R7: (Number(Slack) / Number(M1)) * 100,  //New Result variable added to calculate the savings ratio. - VN
            R2Less: ((3 * (Number(formData.M2) + (Number(formData.M3)) + (Number(formData.M4)))) - Number(formData.T1)) / Number(Slack),
            saving: Number(formData.T1) - (3 * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))),
            R1a: (0 / Number(M1)) * 100, //M3 is zero when debt is paid off.
            R2a: Number(T1) / (Number(M2) + 0 + Number(M4)) * 100,
            R3a: ((Number(T1) + Number(T2)) / (1 ) * 100),	// Fixed to check if T3 is null. If null, the denominator is set to 1. VN
            R4a: ((Number(T1) + Number(T2)) / (0 + Number(M4)) * 100), //M3 is zero when debt is payed off.
            R5a: (Number(M4) / Number(M1) * 100),
            R6a: (0 / (Number(T1) + Number(T2))) * 100, //New Result variable added to check the debt ratio if high. - VN
            R7a: (Number(Slack) / Number(M1)) * 100,  //New Result variable added to calculate the savings ratio. - VN

        });


        setSlack(Number(M1) - (Number(M2) + Number(M3) + Number(M4)));
        setSlack2(Number(M1) - (Number(M2) + Number(M4)));   // setting Slack 2



        

        checkAllocation()

      

 
        dispatch(actions.updateFormData(formData))


        setNetworth((Number(T1) + Number(T2)) - Number(T3));
        setNetworth2((Number(T1) + Number(T2)));
        setSlack2(Number(M1) - (Number(M2) + Number(M4)));   // setting Slack 2
    }, [formData, Slack, Slack2, selectedOption, resultData.saving, dataINV]);

	function checkAllocation() {
            if (dataINV.STA || dataINV.BDA || dataINV.REA || dataINV.ALA)  {

                const sum = (Number(dataINV.STA) + Number(dataINV.BDA) + Number(dataINV.REA) + Number(dataINV.ALA))
                if ((sum) !== 100) {
                    setCheck(true)
                }
                else {
                    setCheck(false)
                }
            }
            else {
                setCheck(false)
            }
        }
		
	
	
    const [chartData, setCartData] = useState([{
        name: 'Car Loan',
        value: Number(dataINV.CLA),
        color: "#404EED"
    },
    {
        name: 'Credit Card Debt',
        value: Number(dataINV.CCDA),
        color: "#404EED"
    },
    {
        name: 'Personal Loan',
        value: Number(dataINV.PLA),
        color: "#404EED"
    },
    {
        name: 'Educational Loan',
        value: Number(dataINV.ELA),
        color: "#404EED"
    }]);
//console.log("dataINV.STAR "+dataINV.STAR)
    let INVValues = [];
// for dynamic addition of statments to show in recommendation section
    let recoStats = ''
    let extra_savings = 0
    let debt_to_reduce = 0
	let FVS = 0
	let FVB = 0
	let FVR = 0        
	let FVA = 0 
	let LineVals = []
	
    //function for calculating goal allotments
    function InvestReco() {        
		let FV = 0	
		let RT = 0	
		let i = 12
        let stock_ret = dataINV.STAR
        let stock_allo = dataINV.STA
		let bond_ret = dataINV.BDAR
        let bond_allo = dataINV.BDA
		let real_ret = dataINV.REAR
        let real_allo = dataINV.REA
		let alt_ret = dataINV.ALAR
        let alt_allo = dataINV.ALA
		let init_inv = (Number(formData.T1)-3*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4)))+Number(formData.T2)
		let addl_inv = Slack
		//Below are the variables for calculating p, r and s for Stocks, Bonds, Real Estate, Alternatives respectively.
		let ps = 0
		let rs = 0
		let ss = 0
		let pb = 0
		let rb = 0
		let sb = 0
		let pr = 0
		let rr = 0
		let sr = 0
		let pa = 0
		let ra = 0
		let sa = 0
		
		recoStats = ''
			
        let monthly_exp = (Number(formData.M2)+Number(formData.M3)+Number(formData.M4)).toFixed(0)
		let ret_amt = Math.ceil((Number(formData.M2)+Number(formData.M3)+Number(formData.M4))*12*(100/(Number(form1Data.EWRR))))
		
		recoStats+=`Based on your monthly expenses ${monthly_exp}, you will need ${ret_amt} to retire.\n`
        if (Number(resultData.R2) > (Number(form1Data.EFT)*100)){
			extra_savings = Number(formData.T1)-Number(form1Data.EFT)*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4))
            recoStats+=`You can invest ${extra_savings} from savings after setting aside ${Number(form1Data.EFT)} months of emergency funds.\n`        
		}
        if (Number(formData.T2) > 0){
			recoStats+= `You can invest ${Number(formData.T2)} from Investments according to the asset allocation provided.\n`	
		}	
        if (Slack > 0){
			recoStats+= `You can invest ${Slack} from Slack every month according to the asset allocation provided.\n`	
		}

		if (Number(formData.T1) > Number(form1Data.EFT)*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4))){
			init_inv = (Number(formData.T1)-3*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4)))+Number(formData.T2)
		}		
		else {
			init_inv = Number(formData.T2)
		}	
		//console.log("init_inv "+init_inv)
		if (init_inv > 0) {
			// Setting the values
			if (stock_ret > 0 && stock_allo > 0) {
				ps = init_inv * (stock_allo/100)
				rs = (stock_ret/100)/12
				ss = Slack *(stock_allo/100)
				LineVals.push({ name:'Stocks',ps: ps, rs: rs, ss:ss})
			}
			if (bond_ret > 0 && bond_allo > 0) {
				pb = init_inv * (bond_allo/100)
				rb = (bond_ret/100)/12
				sb = Slack *(bond_allo/100)
			}	
			if (real_ret > 0 && real_allo > 0) {
				pr = init_inv * (real_allo/100)
				rr = (real_ret/100)/12
				sr = Slack *(real_allo/100)
			}	
			if (alt_ret > 0 && alt_allo > 0) {
				pa = init_inv * (alt_allo/100)
				ra = (alt_ret/100)/12
				sa = Slack *(alt_allo/100)
			}			
		
			//Calculation of the Retirement Time (RT)
			while(RT === 0){
				let t = i
				if (stock_ret > 0 && stock_allo > 0) {
					var FVS1 = Number(ps*(Math.pow((1+rs),t)))
					var FVS2 = Number(ss*((Math.pow((1+rs),t) - 1)/rs))
					FVS = (parseInt(FVS1))+(parseInt(FVS2))
					
				}
				if (bond_ret > 0 && bond_allo > 0) {			
					let FVB1 = pb*(Math.pow((1+rb),t))
					let FVB2 = sb*((Math.pow((1+rb),t) - 1)/rb)
					FVB = parseInt(FVB1)+parseInt(FVB2)	
					
				}
				if (real_ret > 0 && real_allo > 0) {
					let FVR1 = pr*(Math.pow((1+rr),t))
					let FVR2 = sr*((Math.pow((1+rr),t) - 1)/rr)
					FVR = parseInt(FVR1)+parseInt(FVR2)	
						
				}
				if (alt_ret > 0 && alt_allo > 0) {				
					let FVA1 = pa*(Math.pow((1+ra),t))
					let FVA2 = sa*((Math.pow((1+ra),t) - 1)/ra)
					FVA = parseInt(FVA1)+parseInt(FVA2)	
					
				}
				
				FV = parseInt(FVS)+parseInt(FVB)+parseInt(FVR)+parseInt(FVA)
				if (FV >= ret_amt){
					//console.log("Yeah")
					RT = i	
					INVValues = { STAR: dataINV.STAR, STA: dataINV.STA , BDAR: dataINV.BDAR, BDA: dataINV.BDA, REAR: dataINV.REAR, REA: dataINV.REA, ALAR: dataINV.ALAR , ALA: dataINV.ALA, Slack: Slack, ret_amt: ret_amt, init_inv:init_inv };	
				}	
				else {
					RT = 0
					i = i+12
				}
					
				
			}
			
		}
		if (RT > 0){
			recoStats+= `Your chosen asset allocation will take ${RT/12} years to build the retirement corpus.\n`	
		}

		
        return recoStats;
	}
	
	let Initial_Inv = 0
	function CalculateInitInv() {
		let ES = Number(formData.T1)-3*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4))
		
		if (ES > 0){
			Initial_Inv = (Number(formData.T1)-3*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4)))+Number(formData.T2)
		}
		else {
			Initial_Inv = Number(formData.T2)
		}
		return Initial_Inv
	}


    return (
        <div className="w-full ">
			<form ref={form} onSubmit={sendEmail}>
            <div className="bg-gradient-to-b h-72 from-primary to-patric-blue">
                <Container classes={"flex flex-col p-4 px-5 space-y-6 md:space-y-0  md:py-0 md:flex-row items-center w-full justify-between"}>
                    <div >
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-6 h-auto " >
							<div>
								<Link to={'/'}>
								<img src="./assets/images/LOGO_White.png" alt="logo" className = "object-scale-down w-[160px] h-[160px]" />
								</Link>								
							</div>
						</div>
					</div>	
					<div className=" -ml-12 " >
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-6 mt-10 h-auto " >
							<a href="https://www.loom.com/share/f65e9e68f6da4f7c83f705679a15cd1d?sid=77d6b897-6b7e-4536-b5f2-c28a8b98221e" download="SYMR-Glossary" target='_blank'>
							 <center><FontAwesomeIcon icon={faYoutube} style={{color: "white", fontSize: '18px',  marginTop: '5px' }} /> How-To  </center> 
							</a>
						</div>
						<div className="w-auto py-1 mt-[5%] text-white text-l bg-[#646cff] h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<a href="https://drive.google.com/file/d/16K6MSx9zpJxhAGi5ohJvTqZO_qiLoZww/view" download="SYMR-Glossary" target='_blank'>
							 <center> <FontAwesomeIcon icon={faDownload} style={{color: "white", fontSize: '20px'}} />  Glossary  </center> 
							</a>
						</div>
					</div>		
					<div className=" -ml-12 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-12 h-auto " >
							<h1 className="text-white font-bold text-center text-2xl md:text-4xl">Invest your Money </h1>
						</div>
						<div className="w-auto py-1 mt-[6%] text-white text-l h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<div className="text-white  text-center mb:mx-0 mx-5 md:ml-30 -mt-3 md:-mt-7 text-lg md:text-xl text-bold">Make Your Money Work for You - Unleash the Power of Compounding Returns</div> 
						</div>
					</div>	
					
                    <div className="-ml-12 w-full md:w-auto cursor-pointer">
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-3 mt-10 h-auto cursor-pointer " >
							<input type="submit" className = "cursor-pointer" value="Send my Inputs" />
						</div>	
						{status === "sending" && (
							<div style={{ color: "white" }}>Sending...</div>
							)
						}
						{status === "sent" && (
							<div style={{ color: "green" }}>Data sent!</div>
						)
						}
						{status === "error" && (
							<div style={{ color: "red" }}>Failed to send data.</div>
						)
						
						}	
                    </div>
                </Container>
                

            </div>
            <Container classes={"-mt-24 md:-mt-32 py-10 space-y-6"}>

                <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                        <div className="shadow-md p-6 bg-white space-y-6 md:w-1/2">
                            <Heading text={"Basic Financial Inputs"} icon={"financial-input"} />
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
                                <Input type={"number"} id={"M1"} value={formData.M1} label={"Monthly Income"} tooltip={"Enter your total monthly income from all sources: salary, business income, rental income etc."} name={"M1"} min={0} onChange={changeHandler} required />
                                <Input type={"number"} id={"T1"} value={formData.T1} label={"Total Savings (Liquid Funds)"} tooltip={"Refers to readily accessible funds held in cash or highly liquid assets, such as savings accounts or money market accounts, that can be quickly and easily utilized for immediate financial needs or emergencies."} name={"T1"} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M2"} value={formData.M2} label={"Monthly Expenses"} tooltip={"Your total monthly expenses for everything except loans and debt payments and rent. It should include everything else such as food, utilities, shopping, healthcare, transportation, education etc."} name={"M2"} min={0} onChange={changeHandler} required/>
                                <Input type={"number"} id={"T2"} value={formData.T2} label={"Total Investments (Non-Cash)"} name={"T2"} tooltip={"Total amount invested in stocks, bonds, 401K, IRA, real estate, or other financial instruments."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/}
                                <Input type={"number"} id={"M3"} value={formData.M3} label={"Monthly Debt Payments"} name={"M3"} tooltip={"Monthly payments you make for all loans such as credit card, car payments, etc."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/ }
                                <Input type={"number"} id={"T3"} value={formData.T3} label={"Total Debt (Except Mortgage)"} name={"T3"} tooltip={"Total amount of debt you need to repay. This includes credit card debt, car loan etc."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M4"} value={formData.M4} label={"Housing/Rent, Tax, Insurance"} name={"M4"} tooltip={"This includes your monthly rent or mortgage payment + property taxes + home insurance + home warranty etc. Include this for all your properties."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"T4"} value={formData.T4} label={"Max Debt Interest Rate (%)"}  name={"T4"} tooltip={"This is the highest interest rate on any of your loans or debts other than mortgage."} min={0} onChange={changeHandler} />
                            </div>
                        </div>


                        <YourFHC R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} Slack={Slack} />




                    </div>
					<Input type={"hidden"} id = {"page_name"} value = {"Invest your Money"} name={"page_name"} onChange={changeHandler}/>
                </div>
                <Heading text={"Basic Asset Allocation"} />
                <div >
                    <div className="md:flex hidden ml-28 ">
                        <h1 className="text-primary text-center text-l font-bold  md:ml-96 md:mr-12">Average Returns per Year (%)</h1>
                        <h1 className="text-primary text-center text-l font-bold  md:ml-52 md:mr-12 ">Allocation (%)</h1>
                        
						

                    </div>
<br/>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-5 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden ">
                        <h1 className="text-primary text-center text-xl font-bold  mt-2 -md:mt-7 md:ml-28 ">Stocks</h1>

                        <Input type={"text"} id={"STAR"} name={"STAR"} value={dataINV.STAR} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"STA"} name={"STA"} value={dataINV.STA} onChange={dataChangeHandler} />
                       


                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:hidden grid">
                        <h1 className="text-primary text-center text-l font-bold  md:mt-3 md:ml-28 ">Stocks</h1>
                        <Input label={"Apr"} type={"text"} id={"STAR"} name={"STAR"} value={dataINV.STAR} defaultValue="1" onChange={dataChangeHandler} />
                        <Input label={"Amount"} type={"number"} id={"STA"} name={"STA"} value={dataINV.STA} onChange={dataChangeHandler} />
                        

                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden">
                        <h1 className="text-primary text-center  text-xl font-bold mt-2 -md:mt-7 md:ml-28">Bonds</h1>
                        <Input type={"number"} id={"BDAR"} name={"BDAR"} value={dataINV.BDAR} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"BDA"} name={"BDA"} value={dataINV.BDA} onChange={dataChangeHandler} />                        

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1  md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className="text-primary text-center text-l font-bold  md:mt-3 md:ml-28 ">Bonds</h1>
                        <Input label={"Apr"} type={"number"} id={"BDAR"} name={"BDAR"} value={dataINV.BDAR} onChange={dataChangeHandler} />
                        <Input label={"Amount"} type={"number"} id={"BDA"} name={"BDA"} value={dataINV.BDA} onChange={dataChangeHandler} />
                        
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden">
                        <h1 className="text-primary text-center text-xl font-bold mt-2 -md:mt-7 md:ml-28">Real Estate</h1>
                        <Input type={"number"} id={"REAR"} name={"REAR"} value={dataINV.REAR} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"REA"} name={"REA"} value={dataINV.REA} onChange={dataChangeHandler} />
                        

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className="text-primary text-center text-l font-bold  md:mt-3 md:ml-28 ">Real Estate</h1>
                        <Input label={"Apr"} type={"number"} id={"REAR"} name={"REAR"} value={dataINV.REAR} onChange={dataChangeHandler} />
                        <Input label={"Amount"} type={"number"} id={"REA"} name={"REA"} value={dataINV.REA} onChange={dataChangeHandler} />                     

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0  md:grid hidden">
                        <h1 className="text-primary text-center text-xl font-bold mt-2 -md:mt-7 md:ml-28">Alternatives</h1>
                        <Input type={"number"} id={"ALAR"} name={"ALAR"} value={dataINV.ALAR} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"ALA"} name={"ALA"} value={dataINV.ALA} onChange={dataChangeHandler} />
                       

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className="text-primary text-center text-l font-bold  md:mt-3 md:ml-28 ">Alternatives</h1>
                        <Input label={"Apr"} type={"number"} id={"ALAR"} name={"ALAR"} value={dataINV.ALAR} onChange={dataChangeHandler} />

                        <Input label={"Amount"} type={"number"} id={"ALA"} name={"ALA"} value={dataINV.ALA} onChange={dataChangeHandler} />

                        
                    </div>


                    {check ?
                        <div className="flex justify-center md:ml-96 mt-5 text-red-devil">
                            <p>The allocation percentage should add up to 100.</p>
                        </div> : ''

                    }
					<p className="hidden">{ dataINV.display = "false" }</p>




                    <div>

                    </div>
                </div>

                
					
				<p className="hidden">
					{ Initial_Inv = CalculateInitInv() }
						
					</p>
				{
						Initial_Inv >0 ?
						<div>
				<div className="block md:flex   px-5">
					
					
						
						<div className="hidden md:block w-1/2">
							<PieGraphINV heading="First Asset Allocation" subheading=""  ST = {(Initial_Inv)* (dataINV.STA/100)} BD={(Initial_Inv)* (dataINV.BDA/100)} RE={(Initial_Inv)* (dataINV.REA/100)} AL = {(Initial_Inv)* (dataINV.ALA/100)}/>
						</div>
						
						
					
                   
                    <div className="md:pl-14 mt-10 md:mt-24">
						 <div className="space-y-3">
						{Slack > 0 ? <DescriptionItem falseText={""} rightText={`You have a monthly slack of ${Slack} to invest.`} condition={Slack > 0} /> : null}
						{Number(formData.T2) > 0 ? <DescriptionItem falseText={""} rightText={`You can invest ${Number(formData.T2)} in the provided asset allocation.`} condition={Number(formData.T2) > 0} /> : null}
						{Number(formData.M2) > 0 ? <DescriptionItem falseText={""} rightText={`Based on your monthly expenses ${Number(formData.M2)+Number(formData.M3)+Number(formData.M4)}, you will need ${(Number(formData.M2)+Number(formData.M3)+Number(formData.M4))*12*(100/(Number(form1Data.EWRR)))} to retire.`} condition={Number(formData.M2) > 0} /> : null}
                        </div>
						{
							Number(formData.M1) > 0?
							<Description R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} R6={resultData.R6} R7={resultData.R7} Networth={Networth} Slack={Slack} /> 
							:''
						}
						
                    </div>

                </div>
				
				<div className="block md:flex   px-5 space-y-12">
				<p className="hidden">
					{InvestReco()}  
				</p>
					
                    <div className="hidden md:block w-1/2">
						<PieGraphINV heading="Final Asset Allocation" subheading=""  ST = {FVS} BD={FVB} RE={FVR} AL = {FVA}/>
                    </div>
                    <div className="md:hidden">
                        <PieGraphINV heading="Final Asset Allocation" subheading=""  ST = {FVS} BD={FVB} RE={FVR} AL = {FVA}/>
                    </div>
                    <div className="md:pl-14 mt-24 md:w-7/12" >						
							<Heading text={"Recommendations"} />
						 <div className=" md:mt-4 space-y-6">
							<h3 className = "text-center font-bold mb-2" style = {{color: "red"}}><i>These are only for illustration purpose and not investment advice. Please consult a certified investment professional before investing.</i></h3>
							<div className="space-y-4">
							
							{/* Check for Emergency Fund Coverage */}
								
							{
								((Number(resultData.R2)).toFixed(0) < (Number(form1Data.EFT)*100) )?
									<div className="text-left  space-y-3 -mt-4 mb-4">
									<h1><b>Emergency Fund Coverage</b></h1>
										<p className="hidden">{ dataINV.display = "true" }</p>
										<p>You need to boost your emergency fund to cover {Number(form1Data.EFT)} months of expenses.</p>
										{(Slack > 0) ? 
											<p>You have {Slack} surplus in your budget every month, you can use this to boost your Emergency 
												Fund to at least {Number(form1Data.EFT)*100}% of expenses. This will take { ((((Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4)))-Number(formData.T1))/Slack).toFixed(0))=== '0'? '1':(((Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4)))-Number(formData.T1))/Slack).toFixed(0) } month(s). 
											</p>
											: <p>You have zero or negative slack in your budget. 
												Reduce your expenses to have a positive slack to first build your emergency fund.
											</p>
										}
									</div>
									:''
							}
							
							{/* Check for Debt */}
							
							{
								((Number(formData.T3)).toFixed(0) > 0.3*(Number(formData.T1)+Number(formData.T2)))?												
									<div className="text-left space-y-3 -mt-4 mb-4;">	
										{/* If emergency fund available, check for debt. */}
										<p className="hidden">
											{extra_savings = Number(formData.T1)-(Number(form1Data.EFT))*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4))}
											{debt_to_reduce = Number(formData.T3)-(Number(form1Data.DTA)/100)*(Number(formData.T1)+Number(formData.T2))}
										</p>
										<h1><b>High Debt</b></h1>
										<p className="hidden">{ dataINV.display = "true" }</p>
										<div>
											<p>You have high debt. You need to pay off your debt before planning for asset allocation.</p>
										</div>	
									   {
										extra_savings > 0?   
									
										(extra_savings > debt_to_reduce)?
											<p>You can use {extra_savings} from your savings to reduce your debt.</p>
											:<div>
												<p>You can use {extra_savings} from your savings to partially reduce your debt.</p>
												{Slack > 0 ? 
													<p>You can further reduce your debt using your slack in {((Number(debt_to_reduce)-Number(extra_savings))/Slack).toFixed(0)} month(s).</p>
													: ''
												}
											
										</div>		
										:<div>
										{Slack > 0 ? 
											<p>You can reduce your debt using your slack in {Number(debt_to_reduce/Slack).toFixed(0)} month(s).</p>
											: ''
											}
										</div>		
										}	
				
										
								    </div>
									: ''
							}
							
							{/*Asset Allocation Recommendations*/}
							
							{
								<div className="text-left">	
								{ dataINV.display === "true"?
									<h1 className = "mb-4"><b>Asset Allocation</b></h1>
								:''}	
								  <p className="hidden">
								  
									{/* Actual Investment Recommendations start here*/}
										{InvestReco()}  
										<p className="hidden">
											{resultData.flag=(true)}
										</p>
								  </p>
								  
								  <p className="text-left  space-y-12  -mt-4;"><div style={{ whiteSpace: 'pre-line' }}>{recoStats}</div></p>
								</div>		
							}
							
							
							
							</div>
                        </div>
						
                    </div>

                </div>	
			{Initial_Inv > 0 ?
					<div className="flex-1 mt-10 w-full md:flex-none ">
						<div className='flex justify-center'>
							<h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>Growth Chart</h2>
						</div>
						<div className="p-10  flex justify-center"><INVLineChart INVValues={INVValues}  />
						</div>
					</div> : ''
			}
			</div>
			:''
					}			
				

                <div className="space-y-10">             
               





                    <BottomNav />
                </div>




            </Container>
			</form>
        </div>
    );
};

export default INV;
