import React, { useEffect, useState, useRef } from "react";
import BottomNav from "../components/BottomNav";
import Container from "../components/Container";
import Description from "../components/Description";
import FinancialHealthCheckup from "../components/FinancialHealthCheckup";
import Heading from "../components/Heading";
import Input from "../components/Input";
import PieGraph from "../components/PieGraph";
import ResultInput from "../components/ResultInput";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Cell, ResponsiveContainer } from 'recharts';
import LineChart from "../components/LineChart";
import DMPieGraph from "../components/DMPieGraph";
import { Form } from "react-router-dom";
import DescriptionItem from "../components/DescriptionItem";
import { DMLineChart } from "../components/DMLineChart";
import Test from "../components/MultipleAxisGraph";
import MultipleAxisGraph from "../components/MultipleAxisGraph";
// import  DemoDMLineChart  from "../components/DemoDMLineChart.";
import LineChartPrac from "../components/LineChartPrac";
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../store/index'
import YourFHC from "../components/YourFHC";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
 import { faDownload } from '@fortawesome/free-solid-svg-icons'
 import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import emailjs from '@emailjs/browser';  

const KFormater = (num) => Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#0A1827" textAnchor="middle" dy={value < 0 ? 16 : -6} >{`${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</text>;
};

const DM = () => {
	const form = useRef();
	  const [status, setStatus] = useState('null'); /*New change */	 
	  const sendEmail = (e) => {
		e.preventDefault();
		setStatus("sending"); /*New change */
		
		emailjs.sendForm('service_ykjjhjm', 'template_k11stse', form.current, 'wwqQKgCmrGkZJXbt6')
		  .then((result) => {
			  console.log(result.text);
			  setStatus("sent");
		  }, (error) => {
			  console.log(error.text);
			  setStatus("error");
		  });
	  };

    const counter = useSelector((state) => state.formdata)
    const dispatch = useDispatch()
    const hasCodeExecuted = useRef(false);

    const [selects, setselects] = useState();


    const [formData, setFormData] = useState({
        M1: null,
        M2: null,
        M3: null,
        M4: null,
        T1: null,
        T2: null,
        T3: null,
        T4: null,


    });

    const [dataDM, setDataDM] = useState({
        CLAPR: 0,
        CLA: 0,
        CLT: 0,
        CCDAPR: 0,
        CCDA: 0,
        CCDT: 0,
        PLAPR: 0,
        PLA: 0,
        PLT: 0,
        ELAPR: 0,
        ELA: 0,
        ELT: 0,
        MAPR: 0,
        MA: 0,
        MT: 0,
        months: 0
    })



    const [resultData, setResultData] = useState({
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        R5: 0,
        R6: 0, //Added to calculate Debt to Asset Ratio. -VN
        R7: 0, //Added to calculate Savings Ratio. -VN
        R2Less: 0,
        saving: 0
    });
	
	const [form1Data, setForm1Data] = useState({
			EFT: 3,
			DTI: 40,
			DTA: 30,
			DCA: 6,
			HCI: 30,
			HOCR: 20,
			SR: 10,
			DP: 20,
			HOCC: 6,
			HIRT: 5,
			PDPM: 'Lowest Balance first',
			
		  
		});
	const[MethodName,setMethodName]=useState('Snowball')	
	useEffect(() => {
			if (sessionStorage.getItem('form1Data')){
					setForm1Data(JSON.parse(sessionStorage.getItem('form1Data')))
				}	
			if (sessionStorage.getItem('MethodName')){
				setMethodName(sessionStorage.getItem('MethodName'))
			}		
				
		},[])
		
    const inputElement = document.getElementById('ELA');



    const [LineChartValues, setLineChartValues] = useState([])


    const [months, setMonths] = useState([])
    const [PayableLoans, setPayableLoans] = useState([])

    function getNextMonths(n) {
        const today = new Date();

        setMonths([])
        let totalMonths = []
        for (let i = 0; i < n; i++) {
            const nextMonth = new Date(today.getFullYear(), today.getMonth() + i + 1, 1);
            const month = nextMonth.toLocaleString('default', { month: 'long' });
            const year = nextMonth.getFullYear();
            totalMonths.push(`${month} ${year}`)
        }

        setMonths(totalMonths)
    }

    const [Slack, setSlack] = useState(0)
    const [Slack2, setSlack2] = useState(0)
    const [Networth, setNetworth] = useState(0);
    const [Networth2, setNetworth2] = useState(0);


    const changeHandler = (e) => {
		let formValues = {...formData, [e.target.id]: e.target.value}
		setFormData(formValues)
		sessionStorage.setItem('formData', JSON.stringify(formValues))
		
        // if(e.target.id==="T1")
        // {
        //     window.alert(e.target.value)
        //     // e.target.value=0

        // }
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const dataChangeHandler = (e) => {
        // if(e.target.id==="T1")
        // {
        //     window.alert(e.target.value)
        //     // e.target.value=0

        // }
		let formValues = {...dataDM, [e.target.id]: e.target.value}
		setDataDM(formValues)
		sessionStorage.setItem('dataDM', JSON.stringify(formValues))
		
        setDataDM({
            ...dataDM,
            [e.target.id]: e.target.value
        });
    };

    const [selectedOption, setSelectedOption] = useState("Snowball");

    const [check, setCheck] = useState(false)
	
	const [Intcheck, setIntCheck] = useState(false)

    const handleSelectChange = (event) => {
        // console.log(selectedOption)
        setSelectedOption(event.target.value);
		setMethodName(event.target.value);
		sessionStorage.setItem('MethodName', event.target.value)
    };

    const update = () => {
        Object.keys(counter).length > 0 ?
            setFormData(counter) :
            setFormData(formData)
    }
	useEffect(() => {
		if (sessionStorage.getItem('dataDM')){
				setDataDM(JSON.parse(sessionStorage.getItem('dataDM')))
			}
		if (sessionStorage.getItem('formData')){
				setFormData(JSON.parse(sessionStorage.getItem('formData')))
			}	
	},[]);	

    useEffect(() => {
		document.title = 'SYMR | Pay Off Debt';
        if (!hasCodeExecuted.current) {

            //update()
            hasCodeExecuted.current = true;
            window.scrollTo(0, 0);

        }
		
		if (selectedOption === "Snowball") {
			console.log("Snowball")
			setIntCheck(false)
		}
		else {
			console.log("avalanche")
			checkInterest()
		}
		
        const { M1, M2, M3, M4, T1, T2, T3, T4 } = formData;
        setResultData({
            R1: (Number(M3) / Number(M1)) * 100,
            R2: Number(T1) / (Number(M2) + Number(M3) + Number(M4)) * 100,
            R3: ((Number(T1) + Number(T2)) /  (Number(T3)) * 100),	// Fixed to check if T3 is null. If null, the denominator is set to 1. VN
            R4: ((Number(T1) + Number(T2)) / (Number(M3) + Number(M4)) * 100), // Fixed to add assets T2 to the calculation. VN
            R5: (Number(M4) / Number(M1) * 100),
            R6: (Number(T3) / (Number(T1) + Number(T2))) * 100, //New Result variable added to check the debt ratio if high. - VN
            R7: (Number(Slack) / Number(M1)) * 100,  //New Result variable added to calculate the savings ratio. - VN
            R2Less: ((Number(form1Data.EFT) * (Number(formData.M2) + (Number(formData.M3)) + (Number(formData.M4)))) - Number(formData.T1)) / Number(Slack),
            saving: Number(formData.T1) - (Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))),
            R1a: (0 / Number(M1)) * 100, //M3 is zero when debt is payed off.
            R2a: Number(T1) / (Number(M2) + 0 + Number(M4)) * 100,
            R3a: (((Number(T1) + Number(T2)) / 0) * 100),	// Fixed to check if T3 is null. If null, the denominator is set to 1. VN
            R4a: ((Number(T1) + Number(T2)) / (0 + Number(M4)) * 100), //M3 is zero when debt is payed off.
            R5a: (Number(M4) / Number(M1) * 100),
            R6a: (0 / (Number(T1) + Number(T2))) * 100, //New Result variable added to check the debt ratio if high. - VN
            R7a: (Number(Slack) / Number(M1)) * 100,  //New Result variable added to calculate the savings ratio. - VN

        });
        const T11 = Number(formData.T1)
        setCartData([{
            name: 'Car Loan',
            value: Number(dataDM.CLA),
            color: "orange"
        },
        {
            name: 'Credit Card Debt',
            value: Number(dataDM.CCDA),
            color: "orange"
        },
        {
            name: 'Personal Loan',
            value: Number(dataDM.PLA),
            color: "orange"
        },
        {
            name: 'Educational Loan',
            value: Number(dataDM.ELA),
            color: "orange"
        }]);



        setSlack(Number(M1) - (Number(M2) + Number(M3) + Number(M4)));
        setSlack2(Number(M1) - (Number(M2) + Number(M4)));   // setting Slack 2
        



        

        checkAmount()
		
		
		checkInterest()
        // inputElement.addEventListener('focusout', (event) => {
        // Code to be executed when the input element loses focus
        const sum = (Number(dataDM.CLA) + Number(dataDM.CCDA) + Number(dataDM.PLA) + Number(dataDM.ELA))
        console.log(sum)
		
        if (sum == Number(formData.T3)) {


            if (Number(resultData.R2).toFixed(0) > (Number(form1Data.EFT)*100)) {
                //alert(">300");
                //alert("selectedOption " + selectedOption)
                if (selectedOption === "Snowball") {
                    Snowball([
                        { name: 'Car Loan', apr: dataDM.CLAPR, amount: dataDM.CLA },
                        { name: 'Credit Card Loan', apr: dataDM.CCDAPR, amount: dataDM.CCDA },
                        { name: 'Personal Loan', apr: dataDM.PLAPR, amount: dataDM.PLA },
                        { name: 'Educational Loan', apr: dataDM.ELAPR, amount: dataDM.ELA }]
                    )
                }

                else {
					
                    Avalanche([
                        { name: 'Car Loan', apr: dataDM.CLAPR, amount: dataDM.CLA },
                        { name: 'Credit Card Loan', apr: dataDM.CCDAPR, amount: dataDM.CCDA },
                        { name: 'Personal Loan', apr: dataDM.PLAPR, amount: dataDM.PLA },
                        { name: 'Educational Loan', apr: dataDM.ELAPR, amount: dataDM.ELA }]
                    )
                }

            }
            if (Number(resultData.R2).toFixed(0) <= (Number(form1Data.EFT)*100)) {
                if (selectedOption === "Snowball") {
                    Snowball([
                        { name: 'Car Loan', apr: dataDM.CLAPR, amount: dataDM.CLA },
                        { name: 'Credit Card Loan', apr: dataDM.CCDAPR, amount: dataDM.CCDA },
                        { name: 'Personal Loan', apr: dataDM.PLAPR, amount: dataDM.PLA },
                        { name: 'Educational Loan', apr: dataDM.ELAPR, amount: dataDM.ELA }]
                    )
                }

                else {
                    Avalanche([
                        { name: 'Car Loan', apr: dataDM.CLAPR, amount: dataDM.CLA },
                        { name: 'Credit Card Loan', apr: dataDM.CCDAPR, amount: dataDM.CCDA },
                        { name: 'Personal Loan', apr: dataDM.PLAPR, amount: dataDM.PLA },
                        { name: 'Educational Loan', apr: dataDM.ELAPR, amount: dataDM.ELA }]
                    )
					
                }

            }
        }

        // if(Number(formData.T3)>0 && Number(data.CLA) >0 &&Number(data.CCDA)>0 && Number(data.PLA)>0 &&Number(data.ELA)>0 )
        // {
        else if (sum > Number(formData.T3) || sum < Number(formData.T3)) {
            // if(check===false)
            // {


            // window.alert('Your total loan amount should be equal total of all loans.')

            //     setCheck(true)
            // }
            if (Number(resultData.R2).toFixed(0) > (Number(form1Data.EFT)*100)) {
                //alert(">300");
                if (selectedOption === "Snowball") {
                    Snowball([
                        { name: 'Car Loan', apr: dataDM.CLAPR, amount: dataDM.CLA },
                        { name: 'Credit Card Loan', apr: dataDM.CCDAPR, amount: dataDM.CCDA },
                        { name: 'Personal Loan', apr: dataDM.PLAPR, amount: dataDM.PLA },
                        { name: 'Educational Loan', apr: dataDM.ELAPR, amount: dataDM.ELA }]
                    )
                }

                else {
                    Avalanche([
                        { name: 'Car Loan', apr: dataDM.CLAPR, amount: dataDM.CLA },
                        { name: 'Credit Card Loan', apr: dataDM.CCDAPR, amount: dataDM.CCDA },
                        { name: 'Personal Loan', apr: dataDM.PLAPR, amount: dataDM.PLA },
                        { name: 'Educational Loan', apr: dataDM.ELAPR, amount: dataDM.ELA }]
                    )
                }

            }
            if (Number(resultData.R2).toFixed(0) <= (Number(form1Data.EFT)*100)) {
                //alert("<=300");
                if (selectedOption === "Snowball") {
                    Snowball([
                        { name: 'Car Loan', apr: dataDM.CLAPR, amount: dataDM.CLA },
                        { name: 'Credit Card Loan', apr: dataDM.CCDAPR, amount: dataDM.CCDA },
                        { name: 'Personal Loan', apr: dataDM.PLAPR, amount: dataDM.PLA },
                        { name: 'Educational Loan', apr: dataDM.ELAPR, amount: dataDM.ELA }]
                    )
                }

                else {
                    Avalanche([
                        { name: 'Car Loan', apr: dataDM.CLAPR, amount: dataDM.CLA },
                        { name: 'Credit Card Loan', apr: dataDM.CCDAPR, amount: dataDM.CCDA },
                        { name: 'Personal Loan', apr: dataDM.PLAPR, amount: dataDM.PLA },
                        { name: 'Educational Loan', apr: dataDM.ELAPR, amount: dataDM.ELA }]
                    )
                }

            }

        }

        // }


        //   });
        console.log('payable Loans ', PayableLoans)
        dispatch(actions.updateFormData(formData))


        setNetworth((Number(T1) + Number(T2)) - Number(T3));
        setNetworth2((Number(T1) + Number(T2)));
        setSlack2(Number(M1) - (Number(M2) + Number(M4)));   // setting Slack 2
    }, [formData, Slack, Slack2, selectedOption, resultData.saving, dataDM]);
	
	function checkInterest() {
			console.log("Inside checkInterest ")
			console.log("dataDM.CLAPR "+dataDM.CLAPR)
			if (selectedOption === "Avalanche")  {
				if (dataDM.CLAPR === 0 || dataDM.CCDAPR ===0 || dataDM.PLAPR === 0 || dataDM.ELAPR === 0){
					console.log("dataDM.CLAPR inside first if"+dataDM.CLAPR)	
					console.log("dataDM.CLA inside first if"+dataDM.CLA)	
					if ((dataDM.CLA > 0)&& (dataDM.CLAPR === 0)) {
							console.log("Indise second")
							setIntCheck(true)
					}
					else if ((dataDM.CCDA >0) && (dataDM.CCDAPR	=== 0)) {
							setIntCheck(true)
					}	
					else if ((dataDM.CCDA > 0) && (dataDM.CCDAPR === 0)) {
							setIntCheck(true)
					}
					else if ((dataDM.PLA > 0) && (dataDM.PLAPR	=== 0)) {
							setIntCheck(true)
					}
					else if ((dataDM.ELA > 0) && (dataDM.ELAPR === 0)) {
							setIntCheck(true)
					}
					else {
						setIntCheck(false)
					}		
				}
				else {
					setIntCheck(false)	
				}	
			}      
            
        }

	function checkAmount() {
            if ((dataDM.ELA || dataDM.CLA || dataDM.CCDA || dataDM.PLA) && formData.T3) {

                const sum = (Number(dataDM.CLA) + Number(dataDM.CCDA) + Number(dataDM.PLA) + Number(dataDM.ELA))
                if ((sum) !== Number(formData.T3)) {
                    setCheck(true)
                }
                else {
                    setCheck(false)
                }
            }
            else {
                setCheck(false)
            }
        }
	
	function Snowball(values) {
            setLineChartValues([])
            setPayableLoans([])
            // console.log("Extra Savings " + resultData.saving)
            let savings = resultData.saving
            let ex_saving = resultData.saving
            //alert('hi')
            let flag = 0
            let month = 0
            let LineVals = []
            let loans = []
            let flag2 = 0
            let stat = ``
            let fund_months = 0
            let final_months = 0
            loans.push(stat)
            values = values.filter(value => value.amount > 0).sort((a, b) => a.amount - b.amount);
            // console.log(values)
            // console.log("Extra Savings " + resultData.saving);
            if (ex_saving > 0) {
                stat += `You have ${ex_saving} in extra savings after setting aside an emergency fund of ${Number(form1Data.EFT)} months' expenses, debt payments and rent.\n\n `
                loans.push(stat)
            }
            console.log("savings 1" + savings);
            values.map((value) => {
                console.log("savings 2" + savings);
                if (savings > Number(value.amount)) {
                    savings = savings - Number(value.amount)
                    stat = ``
                    stat = `${value.name} of ${value.amount} can be paid off immediately using extra savings.\n `
                    loans.push(stat)
                    //setSlack2(Slack)
                    //    alert(value.name)

                }
                else if (savings === Number(value.amount)) {
                    stat = ``
                    stat = `${value.name} of ${value.amount} can be paid off immediately using extra savings.\n `
                    value.amount = Number(value.amount) - savings
                    savings = -1
                    loans.push(stat)
                    flag = 1
                    flag2 = -1



                }
                

                else if (savings > 0) {
                   console.log("Savings " + savings)
                    console.log("stat " + stat)
                   flag = 1
                   value.amount = Number(value.amount) - Number(savings)
                   //// window.alert(value.name)
                   stat = ``
                    stat += `A part of ${value.name} of ${savings} can be paid off using extra savings.`
                    loans.push(stat)
                   // window.alert("Before Number(value.amount) - Number(Slack) " + (Number(value.amount) - Number(Slack)))
                    if (Number(Slack) > 0) {
                        if (Number(value.amount) - Number(Slack) <= 0) {
                            // setslack2(number(slack)-number(value.amount))
                            stat += `Remaining ${value.name} of ${value.amount} can be paid off immediately using slack this month. `
                            loans.push(stat)
                        }
                    }
                    else if (Number(Slack) <= 0) {
                        stat += ` \nYou do not have any extra savings beyond emergency fund or slack in your budget to pay off debt. Please increase your income or decrease your expenses to create positive slack in your budget.`
                         loans.push(stat)

                    }
                    
                    
                    flag2 = 1


                    // loans.push(stat)
                    savings = -1
                    stat=""
                    console.log("stat " + stat)
                    //alert("hello");
                }
                // Added below section of code to print Debt schedule with slack when extra savings not available
                else if (ex_saving <= 0) {
                    console.log("ex_saving " + ex_saving)
                    fund_months=((Number(form1Data.EFT) * (Number(formData.M2) + (Number(formData.M3)) + (Number(formData.M4)))) - Number(formData.T1)) / Number(Slack)
                    console.log("fund_months " + fund_months)
                    if (Number(Slack) > 0) {
                        stat = ``
                        if (flag2 === 0) {
                            //    setSlack(0)
                            let mon1 = ((Number(value.amount) / Number(Slack)).toFixed(0))
                            if (Number(mon1) === 0) {
                                stat = ` \n${value.name} of ${value.amount} can be paid off with slack immediaely. `
                            }
                            else {
                                stat = ` \n${value.name} of ${value.amount} can be paid off with slack in ${(Number(value.amount) / Number(Slack)).toFixed(0)} months. `
                            }
                            
                        }
                        else {
                            let mon = ((Number(value.amount) - Number(Slack)) / Number(Slack)).toFixed(0)

                            if (mon > 0) {
                                // setSlack2(Number(Slack)-Number(value.amount))
                                stat = ` Remaining ${value.name} of ${Number(value.amount)} can be paid off with slack in ${((Number(value.amount) - Number(Slack)) / Number(Slack)).toFixed(0)} months. `
                            }
                            flag2 = 0

                        }
                        loans.push(stat)
                        if (value.amount > 0) {

                            LineVals.push({ name: value.name, amount: Number(value.amount) })
                            setLineChartValues(LineVals)
                            month = Number(month) + (Number(value.amount) / Number(Slack))
                        }
                        // console.log("month " + month)
                    }
                     else if (Number(Slack) <= 0) {
                        stat = ` \n\nYou do not have any extra savings beyond emergency fund or slack in your budget to pay off debt. Please increase your income or decrease your expenses to create positive slack in your budget.`
                        loans.push(stat)
                     }
                     savings = -1
                    
                }

                if (flag === 1) {
                    stat = ``
                    // stat=` ${value.name} of $${value.amount} can be paid off with slack in ${Number(value.amount)/Number(Slack)} months. `
                    if (flag2 === 0) {
                        //    setSlack(0)                        
                        if (Number(Slack) > 0) {
                            let mon2 = ((Number(value.amount) / Number(Slack)).toFixed(0))
                            if (Number(mon2) === 0) {
                                stat += ` \n${value.name} of ${value.amount} can be paid off with slack immediately. `
                            }
                            else {
                                stat += ` \n${value.name} of ${value.amount} can be paid off with slack in ${(Number(value.amount) / Number(Slack)).toFixed(0)} months. `
                            }
                            
                        }
                        //else if (Number(Slack) <= 0) {
                        //    stat += ` \nYou do not have any extra savings beyond emergency fund or slack in your budget to pay off debt. Please increase your income or decrease your expenses to create positive slack in your budget.`
                        //}
                        
                    }
                    
                    else {
                        if (Number(Slack) > 0) {
                            let mon = ((Number(value.amount) - Number(Slack)) / Number(Slack)).toFixed(0)
                            if (mon > 0) {

                                // setSlack2(Number(Slack)-Number(value.amount))
                                stat = ` Remaining ${value.name} of ${Number(value.amount)} can be paid off with slack in ${((Number(value.amount) - Number(Slack)) / Number(Slack)).toFixed(0)} months. `
                            }
                        }
                        

                        
                        flag2 = 0

                    }
                    // alert(stat).A part of Credit Card Loan of 110000 can be paid off using extra savings.
                    loans.push(stat)



                    LineVals.push({ name: value.name, amount: Number(value.amount) })
                    setLineChartValues(LineVals)
                    if (Number(Slack) > 0) {
                        month = Number(month) + (Number(value.amount) / Number(Slack))
                    }
                    
                    //  console.log(month)
                 }
            })
            
            if ((month).toFixed(0) > 0) {
                stat = ""
                if (fund_months.toFixed(0) > 0) {
                    final_months = Number((month).toFixed(0)) + Number(fund_months.toFixed(0))
                }
                else {
                    final_months = (month).toFixed(0)
                }
            stat += `\n\nYou will be debt free in ${final_months} months. See the chart below for a visual schedule of paying off debt.`
                loans.push(stat)
            }
            // console.log(loans)
            setPayableLoans(loans)

            dataDM.months = Number(month)
            getNextMonths(Number(month))
            // console.log(months)
           // console.log("savings " + savings)

        }

        function Avalanche(values) {
            setLineChartValues([])
            setPayableLoans([])
            
            let LineVals = []
            let savings = resultData.saving
            let flag = 0
            let month = 0
            let loans = []
            let flag2 = 0
            let stat = ``
            let fund_months = 0
            let final_months = 0
            loans.push(stat)
            values = values.filter(value => value.apr > 0).sort((a, b) => b.apr - a.apr);
            // console.log(values)
            // console.log("Extra Savings " + resultData.saving)
            let ex_saving = resultData.saving
            if (ex_saving > 0) {
                stat += `You have ${ex_saving} in extra savings after setting aside an emergency fund of ${Number(form1Data.EFT)} months' expenses, debt payments and rent.\n\n `
                loans.push(stat)
            }
            
            values.map((value) => {
                console.log(value.amount)
                
                if (savings > Number(value.amount)) {
                    savings = Number(savings) - Number(value.amount)

                    stat = ``
                    stat += `${value.name} of ${value.amount} can be paid off immediately using extra savings.\n `
                    loans.push(stat)
                    // value.apr=0
                }
                 else if(savings===Number(value.amount))
                 {
                     value.amount=Number(value.amount)-savings
                     savings=-1
                     let stat=`${value.name} can be paid off immediately. `
                     loans.push(stat)
                     flag=1

                 }

                else if (savings === Number(value.amount) && savings > 0) {
                    stat = ``
                    stat += `${value.name} of ${value.amount} can be paid off immediately using extra savings.`
                    value.amount = Number(value.amount) - savings
                    savings = -1
                    loans.push(stat)
                    flag = 1
                    flag2 = -1
                    // value.apr=0
                    // alert(value.apr)

                }

                else if (savings >= 0) {

                    value.amount = Number(value.amount) - Number(savings)
                    stat = ``
                    stat += `A part of ${value.name} of ${savings} can be paid off using extra savings.`

                    if (Number(value.amount) - Number(Slack) <= 0) {
                        stat += ` Remaining ${value.name} of ${value.amount} can be paid off immediately using slack this month. `
                    }
                    flag2 = 1


                    savings = -1
                    loans.push(stat)
                    flag = 1
                }
                // Added below section of code to print Debt schedule with slack when extra savings not available
                
                else if (ex_saving <= 0) {
                    console.log("hi savings < 0 amount " + value.amount)
                    fund_months = ((Number(form1Data.EFT) * (Number(formData.M2) + (Number(formData.M3)) + (Number(formData.M4)))) - Number(formData.T1)) / Number(Slack)
                    if (Number(Slack) > 0) {
                        stat = ``
                        if (flag2 === 0) {
                            //    setSlack(0)
                            let mon1 = ((Number(value.amount) / Number(Slack)).toFixed(0))
                            console.log("mon1 " + mon1)
                            if (Number(mon1) === 0) {
                                stat = ` \n${value.name} of ${value.amount} can be paid off with slack immediately. `
                                
                            }
                            else {
                                stat = ` \n${value.name} of ${value.amount} can be paid off with slack in ${(Number(value.amount) / Number(Slack)).toFixed(0)} months. `
                            }
                        }
                        else {
                            let mon = ((Number(value.amount) - Number(Slack)) / Number(Slack)).toFixed(0)
                            console.log("mon " + mon)
                            if (mon > 0) {
                                // setSlack2(Number(Slack)-Number(value.amount))
                                stat = ` Remaining ${value.name} of ${Number(value.amount)} can be paid off with slack in ${((Number(value.amount) - Number(Slack)) / Number(Slack)).toFixed(0)} months. `
                            }
                            flag2 = 0

                        }
                        loans.push(stat)

                        if (value.amount > 0) {

                            LineVals.push({ name: value.name, amount: Number(value.amount), apr: Number(value.apr) })
                            setLineChartValues(LineVals)
                            month = Number(month) + (Number(value.amount) / Number(Slack))
                        }
                        console.log("month " + month)
                    }
                    else if (Slack <= 0) {
                         console.log("PRINTINH")
                        stat = ` \n\nYou do not have any extra savings beyond emergency fund or slack in your budget to pay off debt. Please increase your income or decrease your expenses to create positive slack in your budget.`
                        
                        loans.push(stat)    
                     }

                }

                if (flag === 1) {

                    stat = ``
                    if (flag2 === 0) {
                        if (Number(Slack) > 0) {
                            let mon2 = ((Number(value.amount) / Number(Slack)).toFixed(0))
                            console.log("mon2 " + mon2)
                            if (Number(mon2) === 0) {
                                stat += ` \n${value.name} of ${value.amount} can be paid off with slack immediately. `
                            }
                            else {
                                stat += ` \n${value.name} of ${value.amount} can be paid off with slack in ${(Number(value.amount) / Number(Slack)).toFixed(0)} months. `
                            }
                            
                        }
                        //else if (Number(Slack) <= 0) {
                        //    stat += ` \nYou do not have any extra savings beyond emergency fund or slack in your budget to pay off debt. Please increase your income or decrease your expenses to create positive slack in your budget.`
                        //}
                        
                    }
                    else {
                        if (Number(Slack) > 0) {
                            let mon = ((Number(value.amount) - Number(Slack)) / Number(Slack)).toFixed(0)
                            console.log("mon " + mon)
                            if (mon > 0) {
                                stat += `\n Remaining ${value.name} of ${Number(value.amount) - Number(Slack)} can be paid off with slack in ${((Number(value.amount) - Number(Slack)) / Number(Slack)).toFixed(0)} months. `
                            }
                           
                        }
                        else if (Number(Slack) <= 0) {
                            console.log("PRINTINH 22")
                            stat += ` \nYou do not have any extra savings beyond emergency fund or slack in your budget to pay off debt. Please increase your income or decrease your expenses to create positive slack in your budget.`
                        }
                        flag2 = 0

                    }
                    loans.push(stat)
                    if (value.amount > 0) {

                        LineVals.push({ name: value.name, amount: Number(value.amount), apr: Number(value.apr) })
                        setLineChartValues(LineVals)
                        if (Number(Slack) > 0) {
                            month = Number(month) + (Number(value.amount) / Number(Slack))
                        }
                    }
                    //  console.log(month)
                }
            })
            console.log("month " + month)
            if ((month).toFixed(0) > 0) { //Added to add a new line to the recommendations based on the number of months. VN
                stat = ""
                if (fund_months.toFixed(0) > 0) {
                    final_months = Number((month).toFixed(0)) + Number(fund_months.toFixed(0))
                }
                else {
                    final_months = (month).toFixed(0)
                }
                stat += `\n\nYou will be debt free in ${final_months} months. See the chart below for a visual schedule of paying off debt.`
                loans.push(stat)
            }
            setPayableLoans(loans)

            dataDM.months = Number(month)
            getNextMonths(Number(month))
            console.log("months "+months)
            // alert(LineVals[0])


        }
	
    const [chartData, setCartData] = useState([{
        name: 'Car Loan',
        value: Number(dataDM.CLA),
        color: "#404EED"
    },
    {
        name: 'Credit Card Debt',
        value: Number(dataDM.CCDA),
        color: "#404EED"
    },
    {
        name: 'Personal Loan',
        value: Number(dataDM.PLA),
        color: "#404EED"
    },
    {
        name: 'Educational Loan',
        value: Number(dataDM.ELA),
        color: "#404EED"
    }]);





    return (
        <div className="w-full ">
			<form ref={form} onSubmit={sendEmail}>
            <div className="bg-gradient-to-b h-72 from-primary to-patric-blue">
                <Container classes={"flex flex-col p-4 px-5 space-y-6 md:space-y-0  md:py-0 md:flex-row items-center w-full justify-between"}>
                    <div className=" -ml-6 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-6 h-auto " >
							<div>
								<Link to={'/'}>
								<img src="./assets/images/LOGO_White.png" alt="logo" className = "object-scale-down w-[160px] h-[160px]" />
								</Link>								
							</div>
						</div>
					</div>	
					<div className=" -ml-48 ">
						<div className="w-auto py-1 mt-[5%]  text-white text-l bg-[#646cff] rounded-lg px-6 mt-12 h-auto " >
							<a href="https://www.loom.com/share/be5f674fff8449a097cc594533c1a5a8?sid=557afaf1-7c3c-46ba-b808-64461daa8291" download="SYMR-Glossary" target='_blank'>
							 <center><FontAwesomeIcon icon={faYoutube} style={{color: "white", fontSize: '18px',  marginTop: '5px' }} /> How-To  </center> 
							</a>
						</div>
						<div className="w-auto py-1 mt-[5%] text-white text-l bg-[#646cff] h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<a href="https://drive.google.com/file/d/16K6MSx9zpJxhAGi5ohJvTqZO_qiLoZww/view" download="SYMR-Glossary" target='_blank'>
							 <center> <FontAwesomeIcon icon={faDownload} style={{color: "white", fontSize: '20px'}} />  Glossary  </center> 
							</a>
						</div>
					</div>	
					<div className=" -ml-48 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-12 h-auto " >
							<h1 className="text-white font-bold text-center text-2xl md:text-4xl">Pay off Debt</h1>
						</div>
						<div className="w-auto py-1 mt-[6%] text-white text-l h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<div className="text-white  text-center mb:mx-0 mx-5 md:ml-30 -mt-3 md:-mt-7 text-lg md:text-2xl">Take control of your debt. You can pay it off and be debt-free </div> 
						</div>
					</div>	
                    <div className="-ml-48 md:mr-24 w-full md:w-auto cursor-pointer">
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-3 mt-10 h-auto cursor-pointer " >
							<input type="submit" className = "cursor-pointer" value="Send my Inputs"/>
						</div>	
						{status === "sending" && (
							<div style={{ color: "white" }}>Sending...</div>
							)
						}
						{status === "sent" && (
							<div style={{ color: "green" }}>Data sent!</div>
						)
						}
						{status === "error" && (
							<div style={{ color: "red" }}>Failed to send data.</div>
						)
						
						}		
                    </div>
                </Container>
              

            </div>
            <Container classes={"-mt-24 md:-mt-32 py-10 space-y-6"}>

                <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                        <div className="shadow-md p-6 bg-white space-y-6 md:w-1/2">
                            <Heading text={"Basic Financial Inputs"} icon={"financial-input"} />
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
                                <Input type={"number"} id={"M1"} value={formData.M1} label={"Monthly Income"} tooltip={"Enter your total monthly income from all sources: salary, business income, rental income etc."} name={"M1"} min={0} onChange={changeHandler} required />
                                <Input type={"number"} id={"T1"} value={formData.T1} label={"Total Savings (Liquid Funds)"} tooltip={"Refers to readily accessible funds held in cash or highly liquid assets, such as savings accounts or money market accounts, that can be quickly and easily utilized for immediate financial needs or emergencies."} name={"T1"} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M2"} value={formData.M2} label={"Monthly Expenses"} tooltip={"Your total monthly expenses for everything except loans and debt payments and rent. It should include everything else such as food, utilities, shopping, healthcare, transportation, education etc."} name={"M2"} min={0} onChange={changeHandler} required/>
                                <Input type={"number"} id={"T2"} value={formData.T2} label={"Total Investments (Non-Cash)"} name={"T2"} tooltip={"Total amount invested in stocks, bonds, 401K, IRA, real estate, or other financial instruments."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/}
                                <Input type={"number"} id={"M3"} value={formData.M3} label={"Monthly Debt Payments"} name={"M3"} tooltip={"Monthly payments you make for all loans such as credit card, car payments, etc."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/ }
                                <Input type={"number"} id={"T3"} value={formData.T3} label={"Total Debt (Except Mortgage)"} name={"T3"} tooltip={"Total amount of debt you need to repay. This includes credit card debt, car loan etc."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M4"} value={formData.M4} label={"Housing/Rent, Tax, Insurance"} name={"M4"} tooltip={"This includes your monthly rent or mortgage payment + property taxes + home insurance + home warranty etc. Include this for all your properties."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"T4"} value={formData.T4} label={"Max Debt Interest Rate (%)"}  name={"T4"} tooltip={"This is the highest interest rate on any of your loans or debts other than mortgage."} min={0} onChange={changeHandler} />
                            </div>
                        </div>


                        <YourFHC R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} Slack={Slack} />




                    </div>
					<Input type={"hidden"} id = {"page_name"} value = {"Pay off Debt"} name={"page_name"} onChange={changeHandler}/>
                </div>
                <Heading text={"Loan Information"} />
                <div >
                    <div className="md:flex hidden ml-96 ">
						<h1 className="text-primary text-left text-l font-bold  ">Amount</h1>
                        <h1 className="text-primary text-left text-l font-bold md:ml-[300px] md:mr-12 ">Interest Rate (%)</h1>                        
                        <h1 className="text-primary text-left text-l font-bold  md:ml-48 ">Tenure (Years)</h1>


                    </div>
<br/>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden ">
                        <h1 className="text-primary text-center text-xl font-bold mt-2 -md:mt-7 md:ml-28 ">Car Loan</h1>
						<Input type={"number"} id={"CLA"} name={"CLA"} value={dataDM.CLA} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"CLAPR"} name={"CLAPR"} value={dataDM.CLAPR} onChange={dataChangeHandler} />                        
                        <Input type={"number"} id={"CLT"} name={"CLT"} value={dataDM.CLT} onChange={dataChangeHandler} />


                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:hidden grid">
                        <h1 className="text-primary text-center text-l font-bold  md:mt-3 md:ml-28 ">Car Loan</h1>						
                        <Input label={"Amount"} type={"number"} name={"CLA"} id={"CLA"} value={dataDM.CLA} onChange={dataChangeHandler} />
                        <Input label={"Apr"} type={"number"} name={"CLAPR"} id={"CLAPR"} value={dataDM.CLAPR} onChange={dataChangeHandler} />
                        <Input label={"Tenure"} type={"number"} name={"CLT"} id={"CLT"} value={dataDM.CLT} onChange={dataChangeHandler} />

                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden">
                        <h1 className="text-primary text-center  text-xl font-bold mt-2 -md:mt-7 md:ml-5">Credit Card Debt</h1>
                        <Input type={"number"} id={"CCDA"} name={"CCDA"} value={dataDM.CCDA} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"CCDAPR"} name={"CCDAPR"} value={dataDM.CCDAPR} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"CCDT"} name={"CCDT"} value={10} disable onChange={dataChangeHandler} />

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1  md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className="text-primary text-center text-l font-bold  md:mt-3 md:ml-28 ">Credit Card Debt</h1>
                        <Input label={"Amount"} type={"number"} id={"CCDA"} name={"CCDA"} value={dataDM.CCDA} onChange={dataChangeHandler} />
                        <Input label={"Apr"} type={"number"} id={"CCDAPR"} name={"CCDAPR"} value={dataDM.CCDAPR} onChange={dataChangeHandler} />
                        <Input label={"Tenure"} type={"number"} id={"CCDT"} name={"CCDT"} value={10} disable onChange={dataChangeHandler} />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden">
                        <h1 className="text-primary text-center text-xl font-bold mt-2 -md:mt-7 md:ml-14">Personal Loan</h1>
                        <Input type={"number"} id={"PLA"} name={"PLA"} value={dataDM.PLA} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"PLAPR"} name={"PLAPR"} value={dataDM.PLAPR} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"PLT"} name={"PLT"} value={dataDM.PLT} onChange={dataChangeHandler} />

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className="text-primary text-center text-l font-bold  md:mt-3 md:ml-28 ">Personal Loan</h1>
                        <Input label={"Amount"} type={"number"} id={"PLA"} name={"PLA"} value={dataDM.PLA} onChange={dataChangeHandler} />
                        <Input label={"Apr"} type={"number"} id={"PLAPR"} name={"PLAPR"} value={dataDM.PLAPR} onChange={dataChangeHandler} />
                        <Input label={"Tenure"} type={"number"} id={"PLT"} name={"PLT"} value={dataDM.PLT} onChange={dataChangeHandler} />

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0  md:grid hidden">
                        <h1 className="text-primary text-center text-xl font-bold mt-2 -md:mt-7 md:ml-5">Educational Loan</h1>
                        <Input type={"number"} id={"ELA"} name={"ELA"} value={dataDM.ELA} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"ELAPR"} name={"ELAPR"} value={dataDM.ELAPR} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"ELT"} name={"ELT"} value={dataDM.ELT} onChange={dataChangeHandler} />

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className="text-primary text-center text-l font-bold  md:mt-3 md:ml-28 ">Educational Loan</h1>

                        <Input label={"Amount"} type={"number"} id={"ELA"} name={"ELA"} value={dataDM.ELA} onChange={dataChangeHandler} />
                        <Input label={"Apr"} type={"number"} id={"ELAPR"} name={"ELAPR"} value={dataDM.ELAPR} onChange={dataChangeHandler} />

                        <Input label={"Tenure"} type={"number"} id={"ELT"} name={"ELT"} value={dataDM.ELT} onChange={dataChangeHandler} />
                    </div>

					<div className="md:flex hidden ml-0 ">
						 {check ?
							<div className="flex justify-center md:ml-48 mt-3 text-red-devil">
								<p>Your Total amount should be equal to the Total Debt</p>
							</div> : ''

						}
						{Intcheck ?
							<div className="flex justify-center md:ml-12 mt-3 text-red-devil">
								<p>Please enter the interest rates </p>
							</div> : ''

						}
                        

                    </div>
                   
					

                    {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-16 md:grid hidden">
                        <h1 className="text-primary text-center text-2xl font-bold mt-5 md:mt-7 md:ml-28">Mortgage</h1>
                        <Input type={"number"} id={"MAPR"} value={data.MAPR}  onChange={dataChangeHandler} isLast/>
                        <Input type={"number"} id={"MA"} value={data.MA}  onChange={dataChangeHandler} isLast/>
                        <Input type={"number"} id={"MT"} value={data.MT}  onChange={dataChangeHandler} isLast/>

                    </div> */}



                    {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-16 md:hidden grid">
                        <h1 className="text-primary text-center text-2xl font-bold mt-5 md:mt-3 md:ml-28">Mortgage</h1>
                        <Input label={"Apr"} type={"number"} id={"MAPR"} value={data.MAPR}  onChange={dataChangeHandler} isLast/>
                        <Input label={"Amount"} type={"number"} id={"MA"} value={data.MA}  onChange={dataChangeHandler} isLast/>
                        <Input label={"Tenure"}type={"number"} id={"MT"} value={data.MT}  onChange={dataChangeHandler} isLast/>

                    </div> */}
					{console.log("form1Data.PDPM "+form1Data.PDPM)}
                    <div className="mb-24 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 md:px-10 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-24 ml-10 md:ml-60   md:grid  flex-col">
                        <Tooltip title="A) The snowball method (lowest balance first) of debt payment is a strategy where a person prioritizes paying off their debts starting with the smallest balance first, while making minimum payments on other debts, gradually gaining momentum as each debt is paid off, and using the freed-up funds to tackle larger debts until all debts are eventually cleared. B) The avalanche method (highest interest first) of debt payment is a strategy where a person focuses on paying off their debts by prioritizing the one with the highest interest rate first, while making minimum payments on other debts, aiming to minimize overall interest paid and accelerate debt repayment by targeting the most costly debt first.">   
						<label className="peer-focus:text-primary  text-chinese-black/70  text-2xl  font-bold mt-5 md:mt-3 md:ml-96 w-10/12 ">Preferred Debt Payoff Method <FontAwesomeIcon className = "ml-2" icon={faCircleInfo} size="s" style={{color: "#9c9fa5",}} /></label>
						
                        </Tooltip>   
						<select value = {MethodName} className=" bg-primary text-white  w-6/12 md:ml-64 text-gray-900 text-sm text-center text-lg py-5 rounded-2xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white focus:outline-primary outline-primary" onChange={handleSelectChange} name = {"method"}>
                            <option value="Snowball" >Snowball</option>
                            <option value="Avalanche" >Avalanche</option>
                        </select>

                    </div>

                    {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-0 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-16 mt-10 md:hidden grid ">
                    <label className="peer-focus:text-primary  text-chinese-black/70 font-bold mt-5 md:mt-3 md:ml-96 w-full ">Preferred Debt Payoff Method</label>
                    <select className=" bg-primary text-white  w-full md:ml-64 text-gray-900 text-sm text-center text-lg py-5 rounded-2xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white focus:outline-primary outline-primary"onChange={handleSelectChange} >
                    <option selected value="Snowball">Snowball</option>
                    <option value="Avalanche">Avalanche</option>
                    </select>   

                    </div> */}

                    <div>

                    </div>
                </div>

                <div className="block md:flex   px-5">

                    <div className="hidden md:block w-7/12">
                        <PieGraph heading="Monthly Expenses vs Savings" subheading="" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} slack={Slack} />
                    </div>
                    <div className="md:hidden">
                        <PieGraph heading="Monthly Expenses vs Savings" subheading="" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} slack={Slack} />
                    </div>
                    <div className="md:pl-14 mt-10 md:mt-24">
						
                        <Description R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} R6={resultData.R6} R7={resultData.R7} Networth={Networth} Slack={Slack} /> {/* Added R6, R7 to print the recommendation based on Debt/Asset ratio & Savings ratio.*/}
                    </div>

                </div>

                <div className="space-y-10">
                    <div className='flex justify-center'>
                        <h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>Loan Amounts and Interest Rates</h2>
                    </div>
                    <div className="bg-primary/5  rounded-xl p-4">


                        {/* <DoubleAxisGraph Loans={[data.CLA, data.CCDA, data.PLA, data.ELA]} APRs={[data.CLAPR, data.CCDAPR
                                , data.PLAPR, data.ELAPR]} /> */}

                        <MultipleAxisGraph Loans={[dataDM.CLA, dataDM.CCDA, dataDM.PLA, dataDM.ELA]} APRs={[dataDM.CLAPR, dataDM.CCDAPR
                            , dataDM.PLAPR, dataDM.ELAPR]} />




                    </div>


						<div className="shadow-md p-6 bg-white space-y-12">
                                    <Heading text={"How to Pay off Debt?"}  /> {/* Spelling Change. VN */}
						{Number(resultData.R2).toFixed(0) > 300 ?
							<div className="w-full md:flex-1  mt-0">
								<div className="flex justify-left pt-3 pb-5 ">
									<div className="md:w-10/12 md:px-0 space-y-2">
										{(((Number(dataDM.CLA) + Number(dataDM.CCDA) + Number(dataDM.PLA) + Number(dataDM.ELA)) <= 0) && (Number(formData.T3 <= 0))) ?
                                                <p className="text-center font-bold text-jungle-green"><div style={{ whiteSpace: 'pre-line' }}>{`You do not have any debt to pay off.\n Congratulations and stay debt free.`}</div></p>
                                                : <div style={{ whiteSpace: 'pre-line' }}>
                                                    {
                                                        PayableLoans.length > 0 ? <p className="text-left"> {PayableLoans}</p>
                                                            : ''
                                                    }
                                                </div>
                                        }						

									</div>
								</div>

							</div> : ''
						} 
						{(Number(resultData.R2).toFixed(0) === '300') ?
								<div className="w-full md:flex-1 -mt-12">									                                
                                    <div className="flex justify-left pt-3 pb-5 ">
                                        <div className="md:w-10/12 md:px-0 px-10 space-y-7">
                                            {(((Number(dataDM.CLA) + Number(dataDM.CCDA) + Number(dataDM.PLA) + Number(dataDM.ELA)) <= 0) && (Number(formData.T3 <= 0))) ?
                                                <p className="text-center font-bold text-jungle-green"><div style={{ whiteSpace: 'pre-line' }}>{`You do not have any debt to pay off.\n Congratulations and stay debt free.`}</div></p>
                                                : Slack <= 0 ?
														<>
														<h1><b>Emergency Fund Coverage</b></h1>
                                                        <p className="text-left"><div style={{ whiteSpace: 'pre-line' }}>{`\nYou do not have any extra savings beyond emergency fund or slack in your budget to pay off debt.\n Please increase your income or decrease your expenses to create positive slack in your budget.`}</div> </p>
														</>
                                                        : 
														<>
														<h1><b>Emergency Fund Coverage</b></h1>
														<p className="text-left"><div style={{ whiteSpace: 'pre-line' }}>{`You have to use slack ${Slack} to first build your emergency fund. \n You need ${Math.ceil(resultData.R2Less)} month(s) to build your emergency fund.`}</div>
                                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                                {
                                                                    PayableLoans.length > 0 ? <> <h1 className="justify-left mt-6"><b>Next Steps</b></h1><p className="justify-left mt-2"> {PayableLoans}</p></>
                                                                        : ''
                                                                }
                                                            </div></p>  
														</>			
                                            }
                                        </div>                                    
                                    </div>
								</div>  : ''
                        }
						{(Number(resultData.R2).toFixed(0)) < 300 ?                               
							<div className="flex justify-left -mt-6">
								<div className="md:w-full md:px-0 px-10 space-y-7">
									{(((Number(dataDM.CLA) + Number(dataDM.CCDA) + Number(dataDM.PLA) + Number(dataDM.ELA)) <= 0) && (Number(formData.T3 <= 0))) ?
										<p className="text-center font-bold text-jungle-green"><div style={{ whiteSpace: 'pre-line' }}>{`You do not have any debt to pay off. Congratulations and stay debt free.`}</div></p>
											:Slack <= 0 ?
												<>
													<h1><b>Emergency Fund Coverage</b></h1>
													<p className="text-left mt-6"><div style={{ whiteSpace: 'pre-line' }}>{`\nYou do not have any extra savings beyond emergency fund or slack in your budget to pay off debt.\n Please increase your income or decrease your expenses to create positive slack in your budget.`}</div> </p>
												</>
												: 
												<>
													<div style={{ whiteSpace: 'pre-line' }}>
													<h1><b>Emergency Fund Coverage</b></h1>
													<p className="text-left mt-4">{`You have to use slack ${Slack} to first build your emergency fund. \n You need ${Math.ceil(resultData.R2Less)} month(s) to build your emergency fund.`}</p></div>
														<div style={{ whiteSpace: 'pre-line' }}>
															{
																PayableLoans.length > 0 ?<> <h1 className="justify-left mt-6"><b>Next Steps</b></h1><p className="justify-left -mt-4"> {PayableLoans}</p></>
																	: ''
															}
														</div>
												</>	
											
									}

									
									
								</div>
							</div> : ''
                        }
					</div>		
					
					

                    {months.length > 1 ?
                        <div>

                            <div className="flex-1 w-full md:flex-none space-y-6">
                                <div className="shadow-md p-6 bg-white space-y-12">
                                    <Heading text={"Key Financial Metrics (Post Paying off Debt)"} icon={"financial-input"} /> {/* Spelling Change. VN */}


                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-4 gap-4 md:gap-y-8 lg:gap-x-28 md:ml-7">

                                        <ResultInput label={"Debt / Income ratio"} value={isNaN(resultData.R1a) ? 0 : resultData.R1a === Infinity ? 'High' : resultData.R1a.toFixed(0) + '%'} tooltip={"How much of your income is spent on Debt Payments? Recommended < 20%"} />
                                        <ResultInput label={"Expense Coverage Ratio"} value={isNaN(resultData.R2a) ? 0 : resultData.R2a === Infinity ? 'High' : resultData.R2a.toFixed(0) + '%'} tooltip={"How many times does your savings cover monthly expenses(expenses, debt and rent)? Recommended>300% (3 months)"} />
                                        <ResultInput label={"Assets / Liabilities"} value={isNaN(resultData.R3a) ? 0 : resultData.R3a === Infinity ? 'High' : resultData.R3a.toFixed(0) + '%'} tooltip={"Ratio of what you have vs what you owe. Recommended>100% (positive net worth)"} />
                                        <ResultInput label={"Assets / (Rent + Debt)"} value={isNaN(resultData.R4a) ? 0 : resultData.R4a === Infinity ? 'High' : resultData.R4a.toFixed(0) + '%'} tooltip={"How many times can your obligations be covered by assets? Recommended > 1200% (one year) "} />
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 gap-4 md:gap-y-4 lg:gap-x-32 md:ml-96     ">

                                        <ResultInput label={"Housing Cost Ratio"} value={isNaN(resultData.R5a) ? 0 : resultData.R5a === Infinity ? 'High' : resultData.R5a.toFixed(0) + '%'} tooltip={"How much of your income is spent on housing costs? Recommended < 30% "} />
                                       
                                        {months.length > 0 ?
                                            <ResultInput label={"Slack in Budget"} value={isNaN(Slack2) || Number(Slack2) < 0 || Slack2 === Infinity ? 0 : '' + Slack2.toFixed(0)} isLast tooltip={"This is the surplus each month which can be used for goal planning"} /> :
                                            <ResultInput label={"Slack in Budget"} value={isNaN(Slack2) || Number(Slack2) < 0 || Slack2 === Infinity ? 0 : '' + Slack2.toFixed(0)} isLast tooltip={"This is the surplus each month which can be used for goal planning"} />
                                        }
                                    </div>
                                    <div className='flex justify-center '>
                                        <h2 className='bg-primary/10 text-primary mt-10 rounded-xl py-2 px-4 text-base md:text-xl'>Cash Flow Distribution<br></br>(Pay off Debt)</h2>
                                    </div>



                                    <div className="block md:flex   px-5">

                                        <div className="hidden md:block w-9/12">
                                            {
                                                months.length > 0 ?
                                                    <PieGraph M1={formData.M1} M2={formData.M2} M3={0} M4={formData.M4} slack={Slack2} /> :
                                                    <PieGraph M1={formData.M1} M2={formData.M2} M3={0} M4={formData.M4} slack={Slack2} />
                                            }
                                        </div>
                                        <div className="md:hidden">
                                            {
                                                months.length > 0 ?
                                                    <PieGraph heading="Current Cash Flow Distribution" subheading="" M1={formData.M1} M2={formData.M2} M3={0} M4={formData.M4} slack={-1} /> :

                                                    <PieGraph heading="Current Cash Flow Distribution" subheading="" M1={formData.M1} M2={formData.M2} M3={0} M4={formData.M4} slack={Slack2} />
                                            }
                                        </div>
                                        <div className="md:pl-14 mt-10 md:mt-24">
                                            <Description R1={resultData.R1a} R2={resultData.R2a} R3={resultData.R3a} R4={resultData.R4a} R5={resultData.R5a} R6={resultData.R6a} R7={resultData.R7a} Networth={Networth2} Slack={Slack2} /> {/* Added R6, R7 to print the recommendation based on Debt/Asset ratio & Savings ratio.*/}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {months.length > 1 ?
                                <div className="flex-1 mt-10 w-full md:flex-none ">
                                    <div className='flex justify-center'>
                                        <h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>Debt Reduction & Increase in Net Worth</h2>
                                    </div>


                                    <div className="p-10  flex justify-center">

                                        <DMLineChart labelMonths={months} LineValues={LineChartValues} slack={Slack} />
                                    </div>
                                </div> : ''
                            }

                            
                        </div>
                        : ''}                  





                    <BottomNav />
                </div>




            </Container>
			</form>	
        </div>
    );
};

export default DM;
