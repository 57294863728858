
import { Pie } from '@ant-design/plots';
import Heading from './Heading';


const PieGraph2 = ({ heading, subheading, F1, F2, F3, F4,Surplus,AVS }) => {
 // let slackValue = Number(M1) - Number(M2) - Number(M3) - Number(M4);
 // slackValue = slackValue < 0 ? slackValue = null : slackValue;
    Surplus = Number(Surplus) ? Number(Surplus) < 0 ? 0 : Surplus:0
  AVS=Number(AVS)?AVS:0
  const data = [
    {
      type: "Surplus",
      value: Number(Surplus),
    },
    {
      type: "Transportation",
      value: Number(F1),
    },
    {
      type: "Food",
      value: Number(F2),
    },
    {
      type: "Utilities",
      value: Number(F3),
    },
    {
       type: "Any other",
       value: Number(F4),
    },
  

  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
      color: ['#2085ec', '#72b4eb', '#0a417a', '#8464a0', '#cea9bc'],
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name} \n {percentage} ({value})',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <div className='space-y-4 justify-center'>
          {
              heading ?
                  <Heading text={heading} classes={'md:w-4/4 '} /> : ''
          }
        {/*<Heading text={heading} classes={'md:w-4/4 '}/>*/}
      {
        subheading &&
        <div className='flex justify-center'>
           <h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>{subheading}</h2>
        </div>
      }
      <Pie {...config}/>

    </div>
  );
};

export default PieGraph2;