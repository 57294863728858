

const DescriptionItem = ({ falseText, rightText, condition }) => {
    return (

        <div className="flex items-center space-x-2">
            <img src={`./assets/icons/${condition ? "tick" : "cross"}.svg`} alt={condition ? "tick" : "cross"} />
            <h3 className={`${condition ? "text-jungle-green" : "text-red-devil"} font-medium text-sm flex-1`}>{condition ? rightText : falseText}</h3>
        </div >
    );
};

export default DescriptionItem;