import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Layout from './components/Layout';
import Home from './pages/Home';
import FHC from "./pages/FHC";
import reportWebVitals from './reportWebVitals';
import './index.css';
import BH from "./pages/BH";
import AS from "./pages/AS";
import SFG from "./pages/SFG";
import VP from "./pages/VP";
import DM from "./pages/DM";
import INV from "./pages/INV";
import How from "./pages/HowTo";
import Preferences from "./pages/Preferences";
import { Provider } from 'react-redux';
import store from './store';
import LD from "./pages/LD";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/fhc",
        // element: <Home />
        element: <FHC />
      },
      {
        path: "/bh",
        // element: <Home />
        element: <BH />
      },
      {
        path:'/as',
        element:<AS/>
      },
      {
        path:'/dm',
        element:<DM/>
      },
      {
        path:'/vp',
        element:<VP/>
      },
      {
        path:'/sfg',
        element:<SFG/>
      },
      {

        path:'/ld',
        element:<LD/>
      },
	  {
        path:'/inv',
        element:<INV/>
      },
	  {
        path:'/defaults',
        element:<Preferences/>
      },

    ]
  },
]);


createRoot(document.getElementById("root")).render(
	
  <Provider store={store}>
  <RouterProvider router={router} />
  </Provider>
);

reportWebVitals();
