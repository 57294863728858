

const Toast= ({ H1, H2,color,description,textcolor }) => {
    return (
        <div className={`rounded-3xl w-5/6 md:w-3/12 md:ml-32 md:mt-1 justify-center  absolute ${color} `}>
        <div className="text-center ml-4 {textcolor}  bg-white w-5 h-5 rounded-full mt-4 mb-2  ">i</div>
        <p className="mb-5 ml-4 mr-6 text-white text-xs md:text-sm">{description}</p>
    </div>
    );
};

export default Toast;
