import Container from "./Container";
import Navbar from "./Navbar";
import SocialIcons from "./SocialIcons";


const Footer = () => {
    return (
        <div className="bg-primary py-2 drop-shadow-2xl">
            <Container classes={"flex items-center justify-center md:justify-between"}>
                <div className="hidden md:block">
                    <Navbar />
                </div>
                
                <div className="hidden md:block">
                    <SocialIcons />
                </div>
            </Container>
        </div>
    );
};

export default Footer;