const FeatureCircle = ({
    heading,
    classes,
    isLargeText = false
}) => {
    return (
        <div className={`w-24 h-24 shadow-xl md:w-32 md:h-32 group bg-gradient-to-r from-philipine-silver  text-dark-jungle-green/50 hover:scale-110 duration-150 cursor-pointer to-cultured flex items-center justify-center rounded-full ${classes}`}>
            <h1 className={`block group-hover:hidden text-center font-bold ${isLargeText ? "text-xs md:text-base" : "text-base md:text-xl"} `}>
                {heading}
            </h1>
            <p className='hidden group-hover:block text-[10px] md:text-xs text-center text-dark-jungle-green'>
                Click Here To
                Start Planning
            </p>
        </div>

    );
};

export default FeatureCircle;
