import { NavLink } from "react-router-dom";

const BottomNav_Def = () => {
    return (
        <ul className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-7 gap-1 md:gap-1 xl:gap-1">
            <NavItem text={"Know Your Metrics"} to={"/fhc"} />
            <NavItem text={"Buy a Home"} to={"/bh"} />
            <NavItem text={"Allocate Savings"} to={"/as"} />
            <NavItem text={"Pay off Debt"} to={"/dm"} />
            <NavItem text={"Create a Budget"} to={"/vp"} />
            <NavItem text={"Save for a Goal"} to={"/sfg"} />
            <NavItem text={"Investing"} to={"/inv"} />
        </ul>
    );
};

export default BottomNav_Def;

const NavItem = ({ text, to }) => {

    return <NavLink
        to={to}
        className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ?
                "hidden bg-gradient-to-r from-primary to-patric-blue rounded-xl py-2 px-2 text-white text-center flex items-center justify-center hover:from-primary/10 hover:to-primary/10 hover:text-primary duration-300" :
                "bg-primary/10 text-primary rounded-xl py-2 px-2 text-center flex items-center justify-center hover:bg-gradient-to-r hover:from-primary hover:to-patric-blue hover:text-white duration-300"}
    >
        {text}
    </NavLink>;
};
