// import insta from '../../public/assets/icons/insta.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
 
const SocialIcons = () => {
    return (
        <ul className="flex items-center space-x-4">
            <li className="cursor-pointer">
                <a href="https://www.facebook.com/kingshukfinancialcoach/" target="blank">

                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.0879 0.393066H6.58789C3.13611 0.393066 0.337891 3.19129 0.337891 6.64307V34.1431C0.337891 37.5948 3.13611 40.3931 6.58789 40.3931H34.0879C37.5397 40.3931 40.3379 37.5948 40.3379 34.1431V6.64307C40.3379 3.19129 37.5397 0.393066 34.0879 0.393066Z" fill="#1877F2" />
                    <path d="M30.3379 20.3931C30.3379 14.8931 25.8379 10.3931 20.3379 10.3931C14.8379 10.3931 10.3379 14.8931 10.3379 20.3931C10.3379 25.3931 13.9629 29.5181 18.7129 30.2681V23.2681H16.2129V20.3931H18.7129V18.1431C18.7129 15.6431 20.2129 14.2681 22.4629 14.2681C23.5879 14.2681 24.7129 14.5181 24.7129 14.5181V17.0181H23.4629C22.2129 17.0181 21.8379 17.7681 21.8379 18.5181V20.3931H24.5879L24.0879 23.2681H21.7129V30.3931C26.7129 29.6431 30.3379 25.3931 30.3379 20.3931Z" fill="white" />
                </svg>
                </a>
            </li>
            <li className="cursor-pointer">
                <a href="https://www.instagram.com/kingshukfinancialcoach/" target="blank">

                <img src={process.env.PUBLIC_URL + "assets/icons/insta.png"} width={"41"} height={"41"}/>

                </a>
                
            </li>
            <li className="cursor-pointer">
                <a href="https://www.linkedin.com/company/start-your-money-right/" target="blank">

                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.6821 0.52478H7.18213C3.73035 0.52478 0.932129 3.323 0.932129 6.77478V34.2748C0.932129 37.7266 3.73035 40.5248 7.18213 40.5248H34.6821C38.1339 40.5248 40.9321 37.7266 40.9321 34.2748V6.77478C40.9321 3.323 38.1339 0.52478 34.6821 0.52478Z" fill="#2867B2" />
                    <path d="M15.4321 30.5248H11.1821V17.1498H15.4321V30.5248ZM13.3071 15.2748C11.9321 15.2748 10.9321 14.2748 10.9321 12.8998C10.9321 11.5248 12.0571 10.5248 13.3071 10.5248C14.6821 10.5248 15.6821 11.5248 15.6821 12.8998C15.6821 14.2748 14.6821 15.2748 13.3071 15.2748ZM30.9321 30.5248H26.6821V23.2748C26.6821 21.1498 25.8071 20.5248 24.5571 20.5248C23.3071 20.5248 22.0571 21.5248 22.0571 23.3998V30.5248H17.8071V17.1498H21.8071V19.0248C22.1821 18.1498 23.6821 16.7748 25.8071 16.7748C28.1821 16.7748 30.6821 18.1498 30.6821 22.2748V30.5248H30.9321Z" fill="white" />
                </svg>
                </a>
            </li>
			<li className="cursor-pointer">
				<a href="mailto:info@startyourmoneyright.com">
				  <FontAwesomeIcon
						type="button"
						icon ={faEnvelope}
						size="2x"
						color="white"
						title="info@startyourmoneyright.com"
					  
					> </FontAwesomeIcon>
				</a>
			</li>	

        </ul>
    );
};

export default SocialIcons;