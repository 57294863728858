
import { Pie } from '@ant-design/plots';
import Heading from './Heading';


const ASPieGraph = ({ heading, subheading, L, L1, M2, M3, M4, T1, T3, GL1, GL2, GL3, GL4, R2  }) => {
//   let slackValue = Number(M1) - (Number(M2)+ Number(M3) + Number(M4));
//   slack?slackValue=slack
//   :
//   slackValue = slackValue < 0 ? slackValue = null : slackValue;

//   L1>5?L=T3-L:L
// L>0 && R3<3? (Number(T1)+Number(L))>3*(Number(M2)+Number(M3)*Number(M4))?  :

var POD=L
var t=L
if (Number(L1) >= 5) {
   
    if (Number(L) >= Number(T3)) {
        L = Number(L) - Number(T3);
        POD=Number(T3)
        // alert(L)
        T3=0
    } else {
      POD=Number(T3)-Number(L)
      L = 0;
    }
  }

  // alert(R2)
 console.log('L',L)
 // Emergency Fund coverage
let EFC = T1 / (M2 + M3 + M4);
if (L > 0 && R2 < 300) {
  let additionalEFC = (3 * (M2 + M3 + M4)) - T1;
  if (Number(L) + Number(T1) >= additionalEFC) {
    T1 =Number(T1)+ Number(additionalEFC);
    L =Number(L)- Number(additionalEFC);
  } else  {
    // T1 += L;
    L = 0;
  }
}

  var GA=0

  function allocateGoals(L, goals) {

    // let val
    if(L>0){
    goals.sort((a,b)=>a-b)
    for(let i=0;i<goals.length;i++)
    {
      if(L<0)
      {
        break
      }
      if(goals[i]!='X')
      {
        if(Number(L)-Number(goals[i])>0)
        {
          // alert(L)

          L =Number(L)- Number(goals[i]);
          GA=Number(GA)+Number(goals[i])
          // alert(GA)
          console.log('value ',L)
        }
        else if(L>0)
        {
          GA=Number(GA)+Number(L)
          L=0
        }
        else
        {
          break;
        }
      }
    }
    //   if(GL4==='X')
    //   {
        
      
    //   if(GL3==='X')
    //   {
    //     if(GL1!='X'&&GL2!='X')
    //     {
    //       val= Math.min(GL1,GL2)
    //       if(Math.min(GL1,GL2)==GL1)
    //       {
    //         GL1='X'
    //       }
    //       else
    //       {
    //         GL2='X'
    //       }


    //     }
    //     else
    //     {
    //     if(GL1!='X')
    //     {
    //       val=GL1
    //       GL1='X'

    //     }
    //     else if(GL2!='X')
    //     {
    //       val=GL1
    //       GL2='X'
    //     }
    //     else
    //     {
    //       return 0
    //     }
     
    //   }

    //   }
    //   else
    //   {
    //     val=Math.min(GL1,GL2,GL3)
    //   }

    // }
    // else
    // {
    //   val=Math.min(GL1,GL2,GL3,GL4)
      
    // }
        // if(GL3!='X')
        // {
        //   if(GL4!='X')
        //   {
        //    val= Math.min(Number(GL1),Number(GL2),Number(GL3),Number(GL4))
        //    GL4='X'

        //   }
        //   else
        //   {
        //    val= Math.min(Number(GL1),Number(GL2),Number(GL3))
        //    GL3='X'

        //   }
        // }
        // else{
        //   if(GL2!='X')
        //   {
        //     val= Math.min(Number(GL1),Number(GL2))
        //     GL2='X'
        //   }
        //   else if(GL1!='X')
        //   {
        //     val=GL1
        //     GL1='X'
        //   }
      
        // }
  

      
    
     
        // L =L- val;
        // GA=Number(GA)+Number(val)
        // console.log('value ',val)
        console.log(goals)
        return 0
        allocateGoals(L,GL1,GL2,GL3,GL4)

      }
        // console.log(`Allocated ${allocation} to ${goal.name}.`);
      } 
      
    
    // console.log(`Remaining L: ${remainingL}`);
  

allocateGoals(L,[GL1,GL2,GL3,GL4])
 t=L-GA
POD=POD<0?0:POD 
EFC=EFC<0?0:EFC
GA=GA<0?0:GA
t=t<0?t:t
   const data = [
    {
      type: 'Pay off Debt',
      value: Number(POD),
    },
    {
      type: 'Build Emergency Fund',
      value: Number(EFC),
    },
    {
      type: 'Financial Goals',
      value: Number(GA),
    },
    {
      type: 'Allocation of returning amount',
      value: Number(t),
    },

  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#5b67ea', 'rgba(64, 78, 237, 0.35)', '#9ea1c2', '#219F94'],
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name} \n {percentage}',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <div className='space-y-4'>
      <Heading text={heading} />
      {
        subheading &&
        <div className='flex justify-center'>
          <h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>{subheading}</h2>
        </div>
      }
      <Pie {...config} />
    </div>
  );
};

export default ASPieGraph;