

const Heading = ({ text, icon,classes }) => {
    return (
        <div className={`bg-gradient-to-r from-primary to-patric-blue w-full text-white font-bold text-xl p-2 flex items-center justify-between ${classes}`}>
            <h1 className="flex-1">{text}</h1>
            {
                icon && <img src={`./assets/icons/${icon}.svg`} alt="icon" />
            }
        </div>
    );
};

export default Heading;