import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
  );



 export function DMLineChart(props) {

     console.log("apr "+props.LineValues[0].apr)
    let CLA= props.LineValues.find(p => p.name === 'Car Loan')?props.LineValues.find(p => p.name === 'Car Loan'):0
    let CCLA= props.LineValues.find(p => p.name === 'Credit Card Loan')?props.LineValues.find(p => p.name === 'Credit Card Loan'):0
    let PLA= props.LineValues.find(p => p.name === 'Personal Loan')?props.LineValues.find(p => p.name === 'Personal Loan'):0
    let ELA= props.LineValues.find(p => p.name === 'Educational Loan')?props.LineValues.find(p => p.name === 'Educational Loan'):0
  
    // console.log(props.LineValues[1].apr)
    if(CLA){CLA=Number(CLA.amount)}else{CLA=0}
    if(CCLA){CCLA=Number(CCLA.amount)}else{CCLA=0}
    if(PLA){PLA=Number(PLA.amount)}else{PLA=0}
    if(ELA){ELA=Number(ELA.amount)}else{ELA=0}
   
    let aprFlag=0
    let aprCLA,aprCCLA,aprPLA,aprELA
    if(props.LineValues[0].apr)
    {
      
    let CLA= props.LineValues.find(p => p.name === 'Car Loan')?props.LineValues.find(p => p.name === 'Car Loan'):0
    let CCLA= props.LineValues.find(p => p.name === 'Credit Card Loan')?props.LineValues.find(p => p.name === 'Credit Card Loan'):0
    let PLA= props.LineValues.find(p => p.name === 'Personal Loan')?props.LineValues.find(p => p.name === 'Personal Loan'):0
    let ELA= props.LineValues.find(p => p.name === 'Educational Loan')?props.LineValues.find(p => p.name === 'Educational Loan'):0
  
    // alert(props.LineValues[0].apr)
      aprFlag=1
      if(CLA){ aprCLA=Number(CLA.apr) }else{aprCLA=0}
      if(CCLA){ aprCCLA=Number(CCLA.apr) }else{aprCCLA=0}
      if(PLA) {aprPLA=Number(PLA.apr) } else{aprPLA=0}
      if(ELA){ aprELA=Number(ELA.apr) } else{aprELA=0}
    
    }
    else
    {
      aprFlag=0
    }
    const CLData=[]
    const CCData=[]
    const PLData=[]
    const ELData=[]


    function findMin(CLA,CCLA,PLA,ELA)
    {
      let val=Infinity
    
      val=CLA>0?CLA:CCLA>0?CCLA:PLA>0?PLA:ELA>0?ELA:0
      if(CLA!==0 && CLA<val)
      {
       val=CLA
      }
       if (CCLA !== 0 && CCLA < val) {
         val = CCLA;
       }
       if (PLA !== 0 && PLA < val) {
         val = PLA;
       }
       if (ELA !== 0 && ELA < val) {
         val = ELA;
       }

       return val
    } 

    function findMaxAPR(aprCLA,aprCCLA,aprPLA,aprELA)
    {
      let aprVal=aprCLA>0?aprCLA:aprCCLA>0?aprCCLA:aprPLA>0?aprPLA:aprELA>0?aprELA:0
      // console.log(aprCLA,aprCCLA,aprPLA,aprELA)
      if(aprCLA>aprVal && aprCLA!=0) 
      {
        aprVal=aprCLA
      }
      if(aprCCLA>aprVal && aprCCLA!=0) 
      {
        aprVal=aprCCLA
      }
      if(aprPLA>aprVal && aprPLA!=0) 
      {
        aprVal=aprPLA
      }
      if(aprELA>aprVal && aprELA!=0) 
      {
        aprVal=aprELA
      }
      return aprVal
    }




    let flag1=0,flag2=0,flag3=0,flag4=0,flagCLA=0,flagCCLA=0,flagPLA=0,flagELA=0
    for(let i=0;i<props.labelMonths.length;i++)
    {
      flagCLA=0
      flagCCLA=0
      flagPLA=0
      flagELA=0

  
    
    //  val=CLA>0?CLA:CCLA>0?CCLA:PLA>0?PLA:ELA>0?ELA:0
    //  if(CLA!==0 && CLA<val)
    //  {
    //   val=CLA
    //  }
    //   if (CCLA !== 0 && CCLA < val) {
    //     val = CCLA;
    //   }
    //   if (PLA !== 0 && PLA < val) {
    //     val = PLA;
    //   }
    //   if (ELA !== 0 && ELA < val) {
    //     val = ELA;
    //   }

    

      if(aprFlag===1)
      {

        let aprVal=findMaxAPR(aprCLA,aprCCLA,aprPLA,aprELA)

        if (aprVal === aprCLA) { flagCLA = 1 }
        if (aprVal === aprCCLA) { flagCCLA = 1 }
        if (aprVal === aprPLA) { flagPLA = 1 }
        if (aprVal === aprELA) { flagELA = 1 }

    

         
      }

      else
      {
        let val= findMin(CLA,CCLA,PLA,ELA)
   
        if(val===CLA){flagCLA=1}
        if(val===CCLA){flagCCLA=1}
        if(val===PLA){flagPLA=1}
        if(val===ELA){flagELA=1}
      }
        console.log("months " + props.labelMonths.length)
      if (i < props.labelMonths.length)
      {
        if(flagCLA===1)
        {

        
        if(CLA>0 ){
          CLA=Number(CLA)-Number(props.slack);
          if(CLA<0)
          {

            let val1=0
            if(aprFlag)
            {
              aprCLA=0
              val1=findMaxAPR(0,aprCCLA,aprPLA,aprELA)
              // alert(aprELA)
              if(val1===aprCCLA){CCLA=Number(CCLA)+Number(CLA)
              if(CCLA<0)
              {
                aprCCLA=0
                val1=findMaxAPR(aprCLA,0,aprPLA,aprELA)
                if(val1===aprCLA){CLA=Number(CLA)+Number(CCLA)}
                if(val1===aprPLA){PLA=Number(PLA)+Number(CCLA)}
                if(val1===aprELA){ELA=Number(ELA)+Number(CCLA)}
              }
              else if(CCLA===0){aprCCLA=0}

              }

              if(val1===aprPLA){PLA=Number(PLA)+Number(CLA)
                if(PLA<0)
                {
                  aprPLA=0
                  val1=findMaxAPR(aprCLA,aprCCLA,0,aprELA)
                  if(val1===aprCLA){CLA=Number(CLA)+Number(PLA)}
                  if(val1===aprCCLA){CCLA=Number(CCLA)+Number(PLA)}
                  if(val1===aprELA){ELA=Number(ELA)+Number(PLA)}
                }

                else if(PLA===0){aprPLA=0}
              }
              if(val1===aprELA){
                ELA=Number(ELA)+Number(CLA)
                
                if(ELA<0)
                {
                  aprELA=0
                  val1 = findMaxAPR(aprCLA, aprCCLA, aprPLA, 0)
                  if (val1 === aprCLA) { CLA = Number(CLA) + Number(ELA) }
                  if (val1 === aprCCLA) { CCLA = Number(CCLA) + Number(ELA) }
                  if (val1 === aprPLA) { PLA = Number(PLA) + Number(ELA) }

                }
                else if(ELA===0){aprELA=0}
              
              }
            }
            else
            {
               val1=findMin(0,CCLA,PLA,ELA)
               
                 if(val1===CCLA){CCLA=Number(CCLA)+Number(CLA)}
                 if(val1===PLA){PLA=Number(PLA)+Number(CLA)}
                 if(val1===ELA){ELA=Number(ELA)+Number(CLA)}
            }


            
            CLA=0
            flag1=1
          }
          else if(CLA===0){flag1=1;aprCLA=0}
        }else{flag1=1}
      }

 if(flagCCLA===1)
 {

 
        if(CCLA>0)
        {CCLA=Number(CCLA)-Number(props.slack);
          if(CCLA<0)
          {
            let val1=0
            if(aprFlag)
            {
                aprCCLA=0
                val1=findMaxAPR(aprCLA,0,aprPLA,aprELA)
                if(val1===aprCLA){CLA=Number(CLA)+Number(CCLA)
                if(CLA<0)
                {
                  aprCLA=0
              val1=findMaxAPR(0,aprCCLA,aprPLA,aprELA)
                  if(val1===aprCCLA){CCLA=Number(CCLA)+Number(CLA)}
                 if(val1===aprPLA){PLA=Number(PLA)+Number(CLA)}
                 if(val1===aprELA){ELA=Number(ELA)+Number(CLA)}
                }
                else if(CLA===0){aprCLA=0}
                }

                if(val1===aprPLA){PLA=Number(PLA)+Number(CCLA)
                
                  if(PLA<0)
                  {
                    aprPLA=0
                    val1=findMaxAPR(aprCLA,aprCCLA,0,aprELA)
                    if(val1===aprCLA){CLA=Number(CLA)+Number(PLA)}
                    if(val1===aprCCLA){CCLA=Number(CCLA)+Number(PLA)}
                    if(val1===aprELA){ELA=Number(ELA)+Number(PLA)}
                  }
                  else if(PLA===0){aprPLA=0}
                }
                if(val1===aprELA){ELA=Number(ELA)+Number(CCLA)
                if(ELA<0)
                {
                  aprELA=0
                  val1=findMaxAPR(aprCLA,aprCCLA,aprPLA,0)
    
                  if(val1===aprCLA){CLA=Number(CLA)+Number(ELA)}
                  if(val1===aprCCLA){CCLA=Number(CCLA)+Number(ELA)}
                  if(val1===aprPLA){PLA=Number(PLA)+Number(ELA)}
                }
                else if(ELA===0){aprELA=0}
                }

            }
            else
            {
              val1=findMin(CLA,0,PLA,ELA)
              if(val1===CLA){CLA=Number(CLA)+Number(CCLA)}
              if(val1===PLA){PLA=Number(PLA)+Number(CCLA)}
              if(val1===ELA){ELA=Number(ELA)+Number(CCLA)}
            }

          

            // PLA=Number(PLA)+Number(CCLA)
            CCLA=0
            flag2=1
          }
         else if(CCLA===0) {flag2=1;aprCCLA=0}
        }else{flag2=1}

      }
 if(flagPLA===1)
 {

 
        if(PLA>0)
        {PLA=Number(PLA)-Number(props.slack);
          if(PLA<0)
          {
            let val1=0
            if(aprFlag)
            {
              aprPLA=0
              val1=findMaxAPR(aprCLA,aprCCLA,0,aprELA)
              if(val1===aprCLA){CLA=Number(CLA)+Number(PLA)
              if(CLA<0)
              {
                aprCLA=0
                val1=findMaxAPR(0,aprCCLA,aprPLA,aprELA)
                    if(val1===aprCCLA){CCLA=Number(CCLA)+Number(CLA)}
                   if(val1===aprPLA){PLA=Number(PLA)+Number(CLA)}
                   if(val1===aprELA){ELA=Number(ELA)+Number(CLA)}
              }
              else if(CLA===0){aprCLA=0}
              }
              if(val1===aprCCLA){CCLA=Number(CCLA)+Number(PLA)
              
                if(CCLA<0)
                {
                  aprCCLA=0
                  val1=findMaxAPR(aprCLA,0,aprPLA,aprELA)
                  if(val1===aprCLA){CLA=Number(CLA)+Number(CCLA)}
                  if(val1===aprPLA){PLA=Number(PLA)+Number(CCLA)}
                  if(val1===aprELA){ELA=Number(ELA)+Number(CCLA)}
                }
                else if(CCLA===0){aprCCLA=0}

              }
              if(val1===aprELA){ELA=Number(ELA)+Number(PLA)
              
                if(ELA<0)
                {
                  aprELA=0
                  val1=findMaxAPR(aprCLA,aprCCLA,aprPLA,0)
    
                  if(val1===aprCLA){CLA=Number(CLA)+Number(ELA)}
                  if(val1===aprCCLA){CCLA=Number(CCLA)+Number(ELA)}
                  if(val1===aprPLA){PLA=Number(PLA)+Number(ELA)}
                }
                else if(ELA===0){aprELA=0}

              
              }
            }
            else
            {
              val1=findMin(CLA,CCLA,0,ELA)
              if(val1===CLA){CLA=Number(CLA)+Number(PLA)}
              if(val1===CCLA){CCLA=Number(CCLA)+Number(PLA)}
              if(val1===ELA){ELA=Number(ELA)+Number(PLA)}
            }
            

          

         
            PLA=0
            flag3=1
          }
         else if(PLA===0) {flag3=1;aprPLA=0}
        }else{flag3=1}
      }

        // if(PLA>0){PLA=Number(PLA)-Number(props.slack);PLA=PLA<0?0:PLA}
      if(flagELA===1)
      {

        if(ELA>0)
        {ELA=Number(ELA)-Number(props.slack);
          if(ELA<0)
          {
            let val1=0
            if(aprFlag)
            {
              // console.log('aprELA : ',aprELA)
              aprELA=0
              val1=findMaxAPR(aprCLA,aprCCLA,aprPLA,0)

              if(val1===aprCLA){CLA=Number(CLA)+Number(ELA)
              
                if(CLA<0)
                {
                  aprCLA=0
              val1=findMaxAPR(0,aprCCLA,aprPLA,aprELA)
                  if(val1===aprCCLA){CCLA=Number(CCLA)+Number(CLA)}
                 if(val1===aprPLA){PLA=Number(PLA)+Number(CLA)}
                 if(val1===aprELA){ELA=Number(ELA)+Number(CLA)}
                }
                else if(CLA===0){aprCLA=0}

              }
              if(val1===aprCCLA){CCLA=Number(CCLA)+Number(ELA)
              
                if(CCLA<0)
                {
                  aprCCLA=0
                  val1=findMaxAPR(aprCLA,0,aprPLA,aprELA)
                  if(val1===aprCLA){CLA=Number(CLA)+Number(CCLA)}
                  if(val1===aprPLA){PLA=Number(PLA)+Number(CCLA)}
                  if(val1===aprELA){ELA=Number(ELA)+Number(CCLA)}
                }
                else if(CCLA===0){aprCCLA=0}

              }
              if(val1===aprPLA){PLA=Number(PLA)+Number(ELA)
                if(PLA<0)
                {
                  aprPLA=0
                  val1=findMaxAPR(aprCLA,aprCCLA,0,aprELA)
                  if(val1===aprCLA){CLA=Number(CLA)+Number(PLA)}
                  if(val1===aprCCLA){CCLA=Number(CCLA)+Number(PLA)}
                  if(val1===aprELA){ELA=Number(ELA)+Number(PLA)}
                }
                else if(PLA===0){aprPLA=0}
              }

            }

            else
            {

              val1=findMin(CLA,CCLA,PLA,0)
              
                if(val1===CLA){CLA=Number(CLA)+Number(ELA)}
                if(val1===CCLA){CCLA=Number(CCLA)+Number(ELA)}
                if(val1===PLA){PLA=Number(PLA)+Number(ELA)}
            }


      
            ELA=0
            flag4=1
          }
         else if(ELA===0) {flag4=1;aprELA=0}
        }else{flag4=1}
      }
        
      if(CLA<0){CLA=0}
      if(CCLA<0){CCLA=0}
      if(PLA<0){PLA=0}
      if(ELA<0){ELA=0}
        // LineData.push([props.labelMonths[i],CLA,CCLA,PLA,ELA])
        CLData.push(CLA)
        CCData.push(CCLA)
        PLData.push(PLA)
        ELData.push(ELA)


      }
    }
  
 const options = {
  responsive: true,
  interaction: {
    mode: 'index' ,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
    //   text: 'Chart.js Line Chart - Multi Axis',
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left' ,
    },
  },
};


     //const labels = props.labelMonths.length > 11 ? props.labelMonths.slice(0, props.labelMonths.length):props.labelMonths
     const labels = props.labelMonths;

// const labels = props.labelMonths;

 const data = {
  labels,
  datasets: [
    {
      label: 'Car Loan',
      data: CLData,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgb(255, 99, 132)',
      yAxisID: 'y',
    },
    {
      label: 'Credit Card Loan',
      data: CCData,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgb(53, 162, 235)',
      yAxisID: 'y',
    },
    {
        label: 'Personal Loan',
        data: PLData,
        borderColor: 'rgb(53, 62, 235)',
        backgroundColor: 'rgb(53, 62, 235)',
        yAxisID: 'y',
      },
      {
        label: 'Educational Loan',
        data: ELData,
        borderColor: 'rgb(0, 185, 0)',
        backgroundColor: 'rgb(0, 185, 0)',
        yAxisID: 'y',
      },
  ],
};

  return <Line options={options} data={data} />;
}
