import React, { useEffect, useState,useRef } from "react";
import BottomNav from "../components/BottomNav";
import Container from "../components/Container";
import Description from "../components/Description";
import FinancialHealthCheckup from "../components/FinancialHealthCheckup";
import Heading from "../components/Heading";
import Input from "../components/Input";
import PieGraph from "../components/PieGraph";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Cell, ResponsiveContainer } from 'recharts';
import ResultInput from "../components/ResultInput";
import ValueContainer from "../components/ValueContainer";
import { kFormatter } from "../libs";
import DescriptionItem from "../components/DescriptionItem";
import {useSelector,useDispatch} from 'react-redux'
import  { actions } from '../store/index'
import YourFHC from "../components/YourFHC";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faDownload } from '@fortawesome/free-solid-svg-icons'
 import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import emailjs from '@emailjs/browser'; 

const KFormater = (num) => Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#404EED" textAnchor="middle" dy={value < 0 ? 16 : -6} >{`${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</text>;
};

const SFG = () => {
	const form = useRef();
	  const [status, setStatus] = useState('null'); /*New change */	 
	  const sendEmail = (e) => {
		e.preventDefault();
		setStatus("sending"); /*New change */
		
		emailjs.sendForm('service_ykjjhjm', 'template_k11stse', form.current, 'wwqQKgCmrGkZJXbt6')
		  .then((result) => {
			  console.log(result.text);
			  setStatus("sent");
		  }, (error) => {
			  console.log(error.text);
			  setStatus("error");
		  });
	  };
    const counter=useSelector((state)=>state.formdata)
    const dispatch=useDispatch()
    const hasCodeExecuted = useRef(false);


    const [formData, setFormData] = useState({
        M1: null,
        M2: null,
        M3: null,
        M4: null,
        T1: null,
        T2: null,
        T3: null,
        T4: null,
       

    });

    const [data,setData]=useState({
        SGL1: null,
        SGL2: null,
        SGL3: 'No',
        SGL4: null,
        SGL5: null,
        C1: null,
        C2: null,
        C3:null,
        P: 0,
        NewM3: 0,
        S: 0,
        NewT1:0,
        NewT3: 0, 
    })

    const [resultData, setResultData] = useState({
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        R5: 0,
        R6: 0, //Added to calculate Debt to Asset Ratio. -VN
        R7: 0, //Added to calculate Savings Ratio. -VN
        R2a:0,
        R4a:0,
        R1a:0,
        flag:true
    });

    const[flag,setFlag]=useState(true)

    const[SGL3,setSGL3]=useState('No')
	
	const [form1Data, setForm1Data] = useState({
			EFT: 3,
			DTI: 40,
			DTA: 30,
			DCA: 6,
			HCI: 30,
			HOCR: 20,
			SR: 10,
			DP: 20,
			HOCC: 6,
			HIRT: 5,
			PDPM: 'Lowest Balance first',
			
		  
		});
	useEffect(() => {
			if (sessionStorage.getItem('form1Data')){
					setForm1Data(JSON.parse(sessionStorage.getItem('form1Data')))
				}	
				
		},[])
	
	
    const handleSelectChange = (event) => {
        // console.log(selectedOption)
        console.log(event.target.value)
		//let formValues = {...SGL3, [event.target.name]: event.target.value}
		setSGL3(event.target.value)
		sessionStorage.setItem('SGL3', event.target.value)
		
         setSGL3(event.target.value)
    };
	
	const[GoalName,setGoalName]=useState()
	
	const handleSelectChangeGN = (event) => {
        // console.log(selectedOption)
        console.log(event.target.value)
		//let formValues = {...SGL3, [event.target.name]: event.target.value}
		setGoalName(event.target.value)
		sessionStorage.setItem('GoalName', event.target.value)
		
         setGoalName(event.target.value)
    };
    
    const [Slack, setSlack] = useState(0);
    const [Slack2, setSlack2] = useState(0);
    const [Networth, setNetworth] = useState(0);

    const changeHandler = (e) => {
         let formValues = {...formData, [e.target.id]: e.target.value}
		setFormData(formValues)
		sessionStorage.setItem('formData', JSON.stringify(formValues))
		
		setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const dataChangeHandler = (e) => {
		let formValues = {...data, [e.target.id]: e.target.value}
		setData(formValues)
		sessionStorage.setItem('data', JSON.stringify(formValues))
		
        setData({
            ...data,
            [e.target.id]: e.target.value
        });

     

    };

    const update=()=>
    {
        Object.keys(counter).length>0?
        setFormData(counter):
        setFormData(formData)
    }


    function PMT(ir, np, pv, fv) {
        /*
        ir - interest rate per month
        np - number of periods (months)
        pv - present value
        fv - future value (residual value)
        */
        const pmt = (ir * (pv * Math.pow((ir + 1), np) + fv)) / ((ir + 1) * (Math.pow((ir + 1), np) - 1));
        // console.log(pmt)
        console.log( ((Number(formData.H4) + Number(formData.H5) + Number(formData.H6)) / 12))

        // alert(pmt)
        return pmt;
    }
    function PMT1(rate, nper, pv) {
        // Calculate the payment amount
        var pmtAmount = (pv * rate) / (1 - Math.pow(1 + rate, -nper));
        
        // Return the payment amount with two decimal places
        console.log(pmtAmount)
        return pmtAmount.toFixed(2);
      }
      function PMT2 (ir, np, pv, fv ) {
        /*
        ir - interest rate per month
        np - number of periods (months)
        pv - present value
        fv - future value (residual value)
        */
    //    console.log((H4 + H5 + H6) / 12)
        let pmt = ( ir * ( pv * Math.pow ( (ir+1), np ) + fv ) ) / ( ( ir + 1 ) * ( Math.pow ( (ir+1), np) -1 ) );
        return pmt;
       }
     
    
       function PMT3(rate, nper, pv, type) {
        var pmt = (rate * pv) / (1 - Math.pow(1 + rate, -nper));
        if (type === 1) {
          pmt /= (1 + rate);
        }
        return -pmt;
      }

    
    useEffect(() => {
		document.title = 'SYMR | Save for a Goal';
        if (!hasCodeExecuted.current) {

            //update()
            hasCodeExecuted.current = true;

            window.scrollTo(0, 0);

        }
        const { M1, M2, M3, M4, T1, T2, T3, T4, H1, H2, H3, H4, H5, H6, H7, H8, H9 } = formData;
        const val=((PMT(((Number(H8)) /100)/ 12, Number(H9)*12 , Number(H1),0)) + ((Number(H4) + Number(H5) + Number(H6)) / 12))

        setResultData({
            R1: (Number(M3) / Number(M1)) * 100,
            R2: (Number(T1) / ((Number(M2) + Number(M3) + Number(M4)))) *100,
            R3: ((Number(T1) + Number(T2)) /  (Number(T3)) * 100),   // Fixed to check if T3 is null. If null, the denominator is set to 1. VN
            R4: ((Number(T1)+Number(T2)) / (Number(M3) + Number(M4)) * 100), // Fixed to add assets T2 to the calculation. VN
            R5: (Number(M4) / Number(M1) * 100),
            R6: (Number(T3)/(Number(T1)+Number(T2)))*100, //New Result variable added to check the debt ratio if high. - VN
            R7: (Number(Slack)/Number(M1))*100,  //New Result variable added to calculate the savings ratio. - VN
            // BR3: (Number(val) / Number(H2))*100,
            // R1a: (Number(M3) / Number(M1))*100,
            // R2a: (Number(T1) / (Number(M2) + Number(M3) + Number(val)))*100,
            // R3a: ((Number(T1) + Number(T2)) / (Number(T3) + Number(H1) - Number(H7)))*100,
            // R4a: (Number(T1) / (Number(M3) + Number(val)))*100,
            // R5a: (Number(val) / Number(M1))*100,
            // SME: (6 * (Number(val) + Number(M2) +Number(M3)))
        });

    
        let resultR2=(Number(T1) / ((Number(M2) + Number(M3) + Number(M4)))) *100
        // alert(resultR2)
        if(Number(resultR2).toFixed(0)>300)
        {
            let C1=Number(formData.T1)-3*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4))
            data.C1=C1
            data.C2=Number(C1)-Number(formData.T3)
            data.C3=Number(data.SGL1)-Number(data.C2)
            data.NewM3=Number(formData.M3)
            resultData.R1a=(Number(formData.M3)/Number(formData.M1)*100)
            
        }
        setCartData([
            {
            name: `${temp[0].month} ${temp[0].year}`,
            value: Number(T1),
            color: "#404EED"
        },
        {
            name: `${temp[1].month} ${temp[1].year}`,
            value: Number(T2),
            color: "#404EED"
        },
        {
            name: `${temp[2].month} ${temp[2].year}`,
            value: -Number(T3),
            color: "#404EED"
        },
        {
            name: `${temp[3].month} ${temp[3].year}`,
            value: -Number(T4),
            color: "#404EED"
        },
        {
            name: `${temp[4].month} ${temp[4].year}`,
            value: Number(T1),
            color: "#404EED"
        },
        {
            name: `${temp[5].month} ${temp[5].year}`,
            value: Number(T2),
            color: "#404EED"
        },
        {
            name: `${temp[6].month} ${temp[6].year}`,
            value: -Number(T3),
            color: "#404EED"
        },
        {
            name: `${temp[7].month} ${temp[7].year}`,
            value: -Number(T4),
            color: "#404EED"
        },
        {
            name: `${temp[8].month} ${temp[8].year}`,
            value: Number(T1),
            color: "#404EED"
        },
        {
            name: `${temp[9].month} ${temp[9].year}`,
            value: Number(T2),
            color: "#404EED"
        },
        {
            name: `${temp[10].month} ${temp[10].year}`,
            value: -Number(T3),
            color: "#404EED"
        },
        {
            name: `${temp[11].month} ${temp[11].year}`,
            value: -Number(T4),
            color: "#404EED"
        }
    ]);
    let slack= Number(M1) - (Number(M2) + Number(M3) + Number(M4))






    //  slack = slack.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    //  slack = slack.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
//  const networthNoDecimal = slack.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    
// resultData.R2a = (Number(formData.T1) / (Number(formData.M2) + Number(data.NewM3) +Number(formData.M4)))*100
// resultData.R4a =(Number(formData.T1) / (Number(data.NewM3) + Number(formData.M4)))*100

dispatch(actions.updateFormData(formData))

    setSlack(slack);
    data.S=Number(slack)
    // data.P= -PMT(Number(data.SGL4)/12,Number(data.SGL5)*12,Number(data.SGL1),0) 
    // setSlack2(Number(M1) - ((Number(M2) + Number(M3) + Number(resultData.HOC))));
    setSlack2(data.SGL3)
    // console.log(Slack2)
    setNetworth((Number(T1) + Number(T2)) - Number(T3));
    console.log(resultData.HOC)
}, [formData, Slack, Slack2,data.S,data.SGL3,SGL3,resultData.R1a,data]);

useEffect(() => {
		if (sessionStorage.getItem('data')){
				setData(JSON.parse(sessionStorage.getItem('data')))
			}
		if (sessionStorage.getItem('formData')){
				setFormData(JSON.parse(sessionStorage.getItem('formData')))
			}	
		if (sessionStorage.getItem('SGL3')){
				setSGL3(sessionStorage.getItem('SGL3'))
			}
		if (sessionStorage.getItem('GoalName')){
				setGoalName(sessionStorage.getItem('GoalName'))
			}		
	},[]);







        var temp=[]
        const d = new Date();
// console.log(d.getMonth());
   const m=d.getMonth()
   const y=d.getFullYear()
   const mo = [
    {no:1,month:"January",year:y},
    {no:2,month:"February",year:y},
    {no:3,month:"March",year:y},
    {no:4,month:"April",year:y},
    {no:5,month:"May",year:y},
    {no:6,month:"June",year:y},
    {no:7,month:"July",year:y},
    {no:8,month:"August",year:y},
    {no:9,month:"September",year:y},
    {no:10,month:"October",year:y},
    {no:11,month:"November",year:y},
    {no:12,month:"December",year:y}];
   console.log(y)
      mo.map((index)=>
      {
        if(index.no>m)
        {
            temp=[...temp,index]
            // console.log(index)
        }
      })
      mo.map((index)=>
      {
        if(index.no<=m)
        {
        
           index.year+=1
            temp=[...temp,index]
            // console.log(index)
        }
      })
    
      temp.map((index)=>
      {

        console.log(index.month)
      })
      console.log(temp.length)
    const [chartData, setCartData] = useState([
        {
        name: `${temp[0].month} ${temp[0].year}`,
        value: Number(formData.T1),
        color: "#404EED"
    },
    {
        name: `${temp[1].month} ${temp[1].year}`,
        value: Number(formData.T2),
        color: "#404EED"
    },
    {
        name: `${temp[2].month} ${temp[2].year}`,
        value: -Number(formData.T3),
        color: "#404EED"
    },
    {
        name: `${temp[3].month} ${temp[3].year}`,
        value: -Number(formData.T4),
        color: "#404EED"
    },
    {
        name: `${temp[4].month} ${temp[4].year}`,
        value: Number(formData.T1),
        color: "#404EED"
    },
    {
        name: `${temp[5].month} ${temp[5].year}`,
        value: Number(formData.T2),
        color: "#404EED"
    },
    {
        name: `${temp[6].month} ${temp[6].year}`,
        value: -Number(formData.T3),
        color: "#404EED"
    },
    {
        name: `${temp[7].month} ${temp[7].year}`,
        value: -Number(formData.T4),
        color: "#404EED"
    },
    {
        name: `${temp[8].month} ${temp[8].year}`,
        value: Number(formData.T1),
        color: "#404EED"
    },
    {
        name: `${temp[9].month} ${temp[9].year}`,
        value: Number(formData.T2),
        color: "#404EED"
    },
    {
        name: `${temp[10].month} ${temp[10].year}`,
        value: -Number(formData.T3),
        color: "#404EED"
    },
    {
        name: `${temp[11].month} ${temp[11].year}`,
        value: -Number(formData.T4),
        color: "#404EED"
    },
    
    
]);

// for dynamic addition of statments to show in recommendation section
    let goalStats = ''
    let payment = 0
    let extra_savings = 0
    let debt_to_reduce = 0
    let savings_used = 0

    //function for calculating goal allotments
    function GoalRecommendations() {
        let time1 = 0
        let time2 = 0
        let time3 = 0        
        let shortfall = 0        
        let goal_amount = data.SGL1
        let goal_timeline = data.SGL2
        let financing = SGL3
        let loan_rate = data.SGL4
        let loan_tenure = data.SGL5

        console.log("SGL3 "+SGL3)

        if (Number(resultData.R2) < (Number(form1Data.EFT)*100)){
            shortfall = Number(formData.T1)-Number(form1Data.EFT)*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4))
            time1 = (Math.ceil(Math.abs(Number(shortfall/Slack).toFixed(0))))
            goalStats+=`You need to first save up for ${Number(form1Data.EFT)} months of expenses. It will take you ${time1} month(s) using your slack amount of ${Slack}.\n\n`
        }
        else if (Number(resultData.R2) > (Number(form1Data.EFT)*100)){
            time1=0
            extra_savings = Number(formData.T1)-Number(form1Data.EFT)*(Number(formData.M2)+Number(formData.M3)+Number(formData.M4))
        }

        console.log("Extra savings before "+ extra_savings)
        if (Number(formData.T3) > (Number(form1Data.DTA)/100) * (Number(formData.T1)+Number(formData.T2))) {
            goalStats+=`You have excessive debt, reduce it to ${form1Data.DTA}% of your assets.`
            debt_to_reduce = Number(formData.T3)-(Number(form1Data.DTA)/100)*(Number(formData.T1)+Number(formData.T2))
            console.log("extra_savings "+extra_savings)
            if (extra_savings > 0){
                if (extra_savings > debt_to_reduce){
                    goalStats+=` You can use ${extra_savings} from your savings to reduce your debt.`
                    console.log("Extra savings after "+ extra_savings)
                    console.log("debt_to_reduce "+ debt_to_reduce)                    
                    extra_savings = extra_savings - debt_to_reduce
                    savings_used = debt_to_reduce
                    debt_to_reduce = 0
                }
            else {
                    debt_to_reduce = debt_to_reduce - extra_savings
                    goalStats+=` You can use ${extra_savings} from your savings to partially reduce your debt.`
                    savings_used = extra_savings
                    extra_savings = 0 
                    time2 = Number(debt_to_reduce/Slack).toFixed(0)
                    goalStats+= ` You can further reduce your debt using your slack in ${time2} month(s).`
                }            
            }
            else {
                time2 = (Math.ceil(Math.abs(Number(debt_to_reduce/Slack).toFixed(0)))) 
                goalStats+= ` You can reduce your debt using your slack in ${time2} months.`    
            }
            if (Number(formData.T1>0) || Number(formData.T2>0)){
                goalStats+=`\nYour final debt will be ${(Number(form1Data.DTA)/100)*(Number(formData.T1)+Number(formData.T2))} or less.\n\n`
            }
            
        }

        if (goal_amount > 0){
            if (extra_savings > goal_amount){
                goalStats+=`You can achieve this goal immediately using ${extra_savings} from your savings.\n\n`
                savings_used = extra_savings
                extra_savings = 0
                goal_amount = 0
            }
            else if (extra_savings > 0){
                goalStats+=`You can use ${extra_savings} from your savings to partially fund your goal.\n\n`
                savings_used = extra_savings
                extra_savings=0
                goal_amount = goal_amount - extra_savings
            }
            

            time3 = (Math.ceil(Math.abs(Number(goal_amount/Slack).toFixed(0)))) 
            let total_months = Number(time1)+Number(time2)+Number(time3) 
            let years = Math.ceil((total_months/12))
            console.log("financing "+financing)
            console.log("time1 "+Number(time1))
            console.log("time2 "+Number(time2))
            console.log("time3 "+Number(time3))
            console.log("years "+Number(years))
            if (goal_amount > 0) {
                if ( years <= goal_timeline){
                    if (time3 > 0){
                        goalStats+=` You can use slack ${Slack} to achieve your goal in ${time3} month(s).`
                    }
                    
                }
                else if (financing === "No"){
                    console.log("years "+years)
                    goalStats+=`Your given timeline for achieving this goal is too short to achieve without debt. \nHowever, you can stretch your timeline to ${total_months} months (${years} years) to achieve your goal using your slack amount of ${Slack}. \n OR turn on the financing option to see how you can use debt.`
                }
                else if (financing === "Yes"){
                    payment = PMT((Number(loan_rate)/100)/12,Number(loan_tenure)*12,Number(goal_amount),0)
                    goalStats+=`You need to finance ${goal_amount} for achieving your goal and have a monthly payment of ${Math.ceil(payment)}.`

                    if (payment > Slack){
                        goalStats+=`\nYour monthly payment will exceed your slack ${Slack}. You will need to reduce your expenses or other debt payments.`
                    }
                }

            }

        }
        
        
        return goalStats

    }






    return (
        <div className="w-full">
			<form ref={form} onSubmit={sendEmail}>
            <div className="bg-gradient-to-b h-72 from-primary to-patric-blue">
                <Container classes={"flex flex-col p-4 px-5 space-y-6 md:space-y-0  md:py-0 md:flex-row items-center w-full justify-between"}>
                    <div className=" -ml-6 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-6 h-auto " >
							<div>
								<Link to={'/'}>
								<img src="./assets/images/LOGO_White.png" alt="logo" className = "object-scale-down w-[160px] h-[160px]" />
								</Link>								
							</div>
						</div>
					</div>	
					
					
					<div className=" -ml-72 " >
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-6 mt-10 h-auto " >
							<a href="https://www.loom.com/share/430fb49fd48c413998538ca616cdcceb?sid=0ce5ee16-b471-4ded-9e7b-7ac156037d4a" download="SYMR-Glossary" target='_blank'>
							 <center><FontAwesomeIcon icon={faYoutube} style={{color: "white", fontSize: '18px',  marginTop: '5px' }} /> How-To  </center> 
							</a>
						</div>
						<div className="w-auto py-1 mt-[5%] text-white text-l bg-[#646cff] h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<a href="https://drive.google.com/file/d/16K6MSx9zpJxhAGi5ohJvTqZO_qiLoZww/view" download="SYMR-Glossary" target='_blank'>
							 <center> <FontAwesomeIcon icon={faDownload} style={{color: "white", fontSize: '20px'}} />  Glossary  </center> 
							</a>
						</div>
					</div>	
					
					<div className=" -ml-48 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-12 h-auto " >
							<h1 className="text-white font-bold text-center text-2xl md:text-4xl">Saving for a Goal </h1>
						</div>
						<div className="w-auto py-1 mt-[6%] text-white text-l h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<div className="text-white  text-center mb:mx-0 mx-5 md:ml-30 -mt-3 md:-mt-7 text-lg md:text-2xl">You have the goal, now give it a plan</div> 
						</div>
					</div>	
					<div className="-ml-48 md:mr-24 w-full md:w-auto cursor-pointer">
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-3 mt-10 h-auto cursor-pointer " >
							<input type="submit" className = "cursor-pointer" value="Send my Inputs" />
						</div>	
						{status === "sending" && (
								<div style={{ color: "white" }}>Sending...</div>
								)
							}
							{status === "sent" && (
								<div style={{ color: "green" }}>Data sent!</div>
							)
							}
							{status === "error" && (
								<div style={{ color: "red" }}>Failed to send data.</div>
							)
							
							}	
                    </div>
                    
                </Container>

            </div>
            <Container classes={"-mt-24 md:-mt-32 py-10 space-y-6"}>
                <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                        <div className="shadow-md p-6 bg-white space-y-6 md:w-1/2">
                            <Heading text={"Basic Financial Inputs"} icon={"financial-input"} />
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
                                <Input type={"number"} id={"M1"} value={formData.M1} label={"Monthly Income"} tooltip={"Enter your total monthly income from all sources: salary, business income, rental income etc."} name={"M1"} min={0} onChange={changeHandler} required />
                                <Input type={"number"} id={"T1"} value={formData.T1} label={"Total Savings (Liquid Funds)"} tooltip={"Refers to readily accessible funds held in cash or highly liquid assets, such as savings accounts or money market accounts, that can be quickly and easily utilized for immediate financial needs or emergencies."} name={"T1"} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M2"} value={formData.M2} label={"Monthly Expenses"} tooltip={"Your total monthly expenses for everything except loans and debt payments and rent. It should include everything else such as food, utilities, shopping, healthcare, transportation, education etc."} name={"M2"} min={0} onChange={changeHandler} required/>
                                <Input type={"number"} id={"T2"} value={formData.T2} label={"Total Investments (Non-Cash)"} name={"T2"} tooltip={"Total amount invested in stocks, bonds, 401K, IRA, real estate, or other financial instruments."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/}
                                <Input type={"number"} id={"M3"} value={formData.M3} label={"Monthly Debt Payments"} name={"M3"} tooltip={"Monthly payments you make for all loans such as credit card, car payments, etc."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/ }
                                <Input type={"number"} id={"T3"} value={formData.T3} label={"Total Debt (Except Mortgage)"} name={"T3"} tooltip={"Total amount of debt you need to repay. This includes credit card debt, car loan etc."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M4"} value={formData.M4} label={"Housing/Rent, Tax, Insurance"} name={"M4"} tooltip={"This includes your monthly rent or mortgage payment + property taxes + home insurance + home warranty etc. Include this for all your properties."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"T4"} value={formData.T4} label={"Max Debt Interest Rate (%)"}  name={"T4"} tooltip={"This is the highest interest rate on any of your loans or debts other than mortgage."} min={0} onChange={changeHandler} />
                            </div>

                         
                        </div>


                     
              <YourFHC R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} Slack={Slack}/>



                      
                    </div>
					<Input type={"hidden"} id = {"page_name"} value = {"Saving for a Goal"} name={"page_name"} onChange={changeHandler}/>
                </div>



                <div className="flex-1 w-full md:flex-none space-y-6">
                    <div className="shadow-md p-6 bg-white space-y-12">
                        <div className="hidden md:block">

                            <Heading text={"Desired Goal"} icon={"financial-input"} />
                        </div>
                        <div className="block md:hidden md:px-0 md:text-center">

                            <Heading text={"Desired Goal"} classes={' '} />
                        </div>


                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-28 md:ml-7  md:px-0">
                        <div>
                        <label htmlFor="dropdown" className="text-sm ml-2">What is your Goal?</label>
                        <select value = {GoalName} id="dropdown" className="bg-bright-gray/50  text-gray-900 text-sm  py-3 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700  focus:outline-primary outline-primary" name = {'goal_name'} onChange={handleSelectChangeGN}>
                            <option selected className='peer-focus:text-primary text-chinese-black/70 hidden xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm text-black '>Select</option>
                            <option value="Education" className='text-black' >Education</option>
                             <option value="Travel" className='text-black'>Travel</option>
                            <option value="Investment" className='text-black'>Business</option>
                            <option value="Investment" className='text-black'>Buying Home</option>
                             <option value="Other" className='text-black'>Other</option>
                            </select>    
                            </div>
                           <Input type={"number"} className="text-sm ml-2" id={"SGL1"} name = {'SGL1'} value={data.SGL1} label={"Amount of money needed to achieve your goal"} onChange={dataChangeHandler} />
                            <Input type={"number"} id={"SGL2"} value={data.SGL2} name = {"SGL2"} label={"Time-Line for achieving your goal (years)"} onChange={dataChangeHandler} />
                            <div>
                        <label htmlFor="dropdown" className="text-sm ml-2">Do you prefer to finance this goal through a Loan?</label>
                        <select value = {SGL3} id="finance"  name = {"finance"} className="bg-bright-gray/50  text-gray-900 text-sm  py-3 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700  focus:outline-primary outline-primary" onChange={handleSelectChange}>
                            <option  className='peer-focus:text-primary text-chinese-black/70 hidden xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm text-black '>Select</option>
                            <option  value="Yes" className='text-black font-bold' >Yes</option>
                             <option value="No" className='text-black font-bold'>No</option>
                           
                            </select>    
                            </div>                           
                            <Input type={"number"} id={"SGL4"} name={"SGL4"} value={data.SGL4} label={"If yes, approx Interest Rate for Loan"} onChange={dataChangeHandler} />
                            <Input type={"number"} id={"SGL5"} name={"SGL5"} value={data.SGL5} label={"If yes, tenure of the loan(years)"} onChange={dataChangeHandler} />
     
                        </div>


                    </div>
                </div>

                <div className="block md:flex pt-10 px-5">

                    <div className="hidden md:block w-7/12">
                        <PieGraph heading="Expenses & Surplus" subheading="" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
                    </div>
                    <div className="md:hidden">
                        <PieGraph heading="Expenses & Surplus" subheading="" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
                    </div>
                    <div className="md:pl-14 mt-10 md:mt-24">
                        <Description R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} R6={resultData.R6} R7={resultData.R7} Networth={Networth} Slack={Slack} /> {/* Added R6, R7 to print the recommendation based on Debt/Asset ratio & Savings ratio.*/}
                    </div>
                    





                </div>
				
				
				 { (isNaN(resultData.R2) || resultData.R2 === Infinity || resultData.R2<0 ) ?  '':
				<div> 
					<div className="shadow-md p-6 bg-white space-y-12">
						<div className="md:block text-left">
                            <Heading text={"Recommendations"}  />
                        </div>
					
					<div className="w-full md:flex-1  mt-0">
						<div className="flex justify-left pt-3 pb-5 ">
							<div className="md:w-full md:px-0 space-y-2">
								{                           
									Number(Slack)<=0?
									<div >
										<p className="hidden">
										{resultData.flag=(false)}
										</p>
										<p>
										You have zero or negative slack in your budget. Reduce your expenses to have a positive slack to plan for any goal.              
										</p>
									</div>
								   : <div>
										<p className="hidden">
											{GoalRecommendations()}  
											<p className="hidden">
											{resultData.flag=(true)}
											</p>
										</p>    
										<p className="text-left px-5"><div style={{ whiteSpace: 'pre-line' }}>{goalStats}</div></p>
									</div>
								}					

							</div>
						</div>
					</div>	
					</div>
				</div>
				 }

             
  
                <div className="space-y-20">

               
                {console.log("resultData.flag "+resultData.flag)}

                    {
                    resultData.flag === true?
                    <div className=" md:flex justify-between md:space-x-24">
     


                        <div className="w-full md:flex-1 p-5  shadow-md   ">
                            <div className="px-0">

                                <Heading text="Your Financial Metrics at a Glance " classes={"items-center px-10 md:px-4"} />
                            </div>
                            <div className="flex justify-center">


                            </div>
                            <div className="flex justify-center p-5">

                            <div className=" md:w-1/2 md:px-0 px-10 space-y-7">
                                {/* {alert(Number(data.NewM3)/Number(formData.M1)*100)} */}
                                {/* {alert(resultData.R1a)} */}
                                <p className="hidden">
                                    {/* {alert(data.NewM3)}
                                     */}
                                {console.log("payment "+payment)}

                                {payment > 0? data.NewM3 = (Number(formData.M3)+Number(payment)) : data.NewM3=formData.M3}
                                {savings_used > 0? data.T1New = Number(formData.T1)-Number(savings_used): data.T1New = formData.T1}
                                {console.log("New T1New "+data.T1New)}
                                {Slack >0?data.S=Slack-payment:data.S=Slack}
                                {console.log("New Slack "+data.S)}

                                {resultData.R1a=(Number(data.NewM3)/Number(formData.M1)*100)}
                                     {resultData.R2a = (Number(data.T1New) / (Number(formData.M2) + Number(data.NewM3) +Number(formData.M4)))*100}
                                     {resultData.R4a =(Number(data.T1New) / (Number(data.NewM3) + Number(formData.M4)))*100}
                                </p>
                                <ResultInput label={'Debt / Income Ratio'} value={isNaN(resultData.R1a) || resultData.R1a<0 ? 0 :resultData.R1a === Infinity ? 'High' : resultData.R1a?.toFixed(0) + '%'} tooltip={"How much of your income is spent on Debt Payments? Recommended < 20%"} />
                                  <ResultInput label={'Expense Coverage Ratio'} value={isNaN(resultData.R2a) || resultData.R2a<0 ? 0 : resultData.R2a === Infinity ? 'High' : resultData.R2a?.toFixed(0) + '%'}  tooltip={"How many times does your savings cover monthly expenses(expenses, debt and rent)? Recommended>300% (3 months)"}/>
                                    <ResultInput label={'Assets / Liabilities'} value={isNaN(resultData.R3) || resultData.R3 <0 ? 0 : resultData.R3 === Infinity ? 'High'  : resultData.R3?.toFixed(0) + '%'} tooltip={"Ratio of what you have vs what you owe. Recommended>100% (positive net worth)"} />
                                    <ResultInput label={'Assets / (Rent + Debt)'} value={isNaN(resultData.R4a) || resultData.R4a<0 ? 0 : resultData.R4a === Infinity ? 'High' : resultData.R4a?.toFixed(0) + '%'} tooltip={"How many times can your obligations be covered by assets? Recommended > 1200% (one year) "}/>
                                    <ResultInput label={'Housing Cost Ratio'} value={isNaN(resultData.R5) || resultData.R5<0 ? 0 : resultData.R5 === Infinity ? 'High' : resultData.R5?.toFixed(0) + '%'} tooltip={"How much of your income is spent on housing costs? Recommended < 30% "}/>
                                    <ResultInput label={'Slack in Budget'} value={isNaN(data.S) || Number(data.S)< 0 || data.S === Infinity ? 0 : data.S?.toFixed(0)} isLast tooltip={"This is the surplus each month which can be used for goal planning"}/>


                                </div>

                            </div>
                        </div>

                        <div className="  w-full  md:w-1/2 pt-5 md:block  justify-center px-0 md:px-0 ">
                            <PieGraph heading="Expenses & Surplus" subheading="" M1={formData.M1} M2={formData.M2} M3={data.NewM3} M4={formData.M4} slack={data.S?.toFixed(0)} />
                        </div>
                    

                    </div>
:''}


                   
                   
                    <BottomNav />
                </div>
            </Container>
		</form>	
        </div>
    );
};

export default SFG;
