import { Link } from "react-router-dom";
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import SocialIcons from "./SocialIcons";


const Navbar = () => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    return (
        <ul className="flex items-center flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
            <Link to={'/'}>
            <li className="text-white font-bold md:font-medium text-xl md:text-base cursor-pointer">Home</li>
            </Link>

          {/*   <div>
			<button  onClick={handleClickOpen}>
      <li className="text-white font-bold md:font-medium text-xl md:text-base cursor-pointer">Contact</li>

			</button> 
      <Dialog
      sx={{
        backdropFilter: "blur(5px) sepia(5%)",
      }}
      // 👇 Props passed to Paper (modal content)
      PaperProps={{ sx: { borderRadius: "10px" } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="pt-5"></div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
        Please contact us at : &nbsp;
        
          <b className="font-bold text-lg">
          info@startyourmoneyright.com
          </b>
          <br></br>
          <br></br>
          <div className="flex justify-center">

          <SocialIcons/>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            CLose
          </Button>
        
        </DialogActions>
      </Dialog>
    </div>*/}
         
            
            <Link to={'/ld'}>
            <li className="text-white font-bold md:font-medium text-xl md:text-base cursor-pointer">Legal Disclaimer</li>
            </Link>
			<li className="text-white font-bold md:font-medium text-xl md:text-base m-15">* No financial data is stored at the backend by this version of the tool</li>
        </ul>
    );
};

export default Navbar;