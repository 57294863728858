import { Link } from "react-router-dom";
import FeatureCircle from "../components/FeatureCircle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
 import React, { useEffect, useState } from 'react'

const Home = () => {
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		document.title = 'Start Your Money Right';
	  }, []);
    return (
        <>
        <div className='flex flex-col md:flex hidden overflow-y-auto justify-stretch w-full md:justify-center items-center '>
            <div className='absolute top-4 left-4 flex flex-col items-start md:items-center'>
                <img className='w-[52%]' src='./assets/icons/logo.svg' alt='logo' />
                <h1 className='text-primary md:text-center text-left font-bold text-xl lg:text-4xl -mt-4'>
                    Don't Think Money
                    <br />
                </h1>
                <h3 className='text-primary md:text-center text-left' style = {{color: "black"}}> {/* Changed colour to black. VN*/}
                    Think Goals. Make them Real.
                    <br />
                </h3>
                <h3 className='text-primary md:text-center text-left' style = {{color: "black"}}> {/* Changed colour to black. VN*/}
                    Smart Goals, Smart Execution
                    <br />
                </h3>
				<br/>
				
				<div className="w-[210px] py-1 border-[1px] text-sm shadow h-[25%] border-[#404EED] rounded-lg px-0 mt-[12%] h-auto]" >
					
						
						<h1 className="text-center text-sm font-semibold Poppins mt-1">
						More exciting features coming soon! <br/>
					   </h1>
					  
					</div>	
				 <h1 className="text-center text-xs Poppins mt-3">
						This site is best viewed on desktop.<br/> Mobile app coming soon.
					   </h1>
            </div>
            <div className='flex relative flex-col mt-32 md:mt-0 md:py-0 w-full md:w-auto md:flex-row items-center space-y-2 md:space-y-0 md:space-x-20'>
                <Link to={"/fhc"} className="z-30">
                    <div className='w-48 h-48 md:w-60 group shadow-xl md:h-60 flex items-center justify-center rounded-full bg-gradient-to-r from-primary hover:from-philipine-silver to-patric-blue hover:to-cultured hover:scale-105 cursor-pointer duration-150'>
                        <h1 className='text-center font-bold text-2xl md:text-4xl text-white group-hover:text-primary'>
                            Know
                            <br />Your
                            <br />
                            Metrics
                        </h1>
                    </div>
                </Link>
                <div className='halfcircle z-0 absolute w-60 h-32 top-[30%] md:w-72 md:h-36 md:top-1/2 md:-rotate-90 md:-left-7 -translate-y-1/2'></div>
                <div className='flex flex-col relative w-full md:w-auto md:h-auto'>
                    <div className='h-32 md:hidden'></div>
                    <div className='h-32 md:hidden'></div>	
					<Link to={'/defaults'}>
                    <FeatureCircle heading={(<>Defaults</>)} classes={"absolute text-l left-4 top-0 md:left-auto md:right-[320px] md:-top-[250px]"} />
                    </Link>						
						<h1 className='absolute left-4 top-120 md:left-auto md:right-[440px] md:top-[42px] w-[100%] text-slate-400'> <b style = {{color: "grey"}}>Start Here <FontAwesomeIcon icon={faArrowRight} style={{color: "grey"}} /></b> </h1>       
					

                    <Link to={'/bh'}>
                    <FeatureCircle heading={(<>Buy a<br /> Home</>)} classes={"absolute left-4 top-0 md:left-auto md:right-[160px] md:-top-[230px]"} />
                    </Link>
                    <Link to={'/as'}>
                    <FeatureCircle heading={(<>Allocate <br /> Savings</>)} classes={"absolute right-4 top-0 md:right-[60px] md:-top-[125px]"} />
                    </Link>
                    <Link to={'/dm'} >
                    <FeatureCircle heading={(<>Pay off <br /> Debt</>)} classes={"md:static absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:translate-x-0 md:translate-y-0"} />
                    </Link>
                    <Link to={'/vp'}>
                    <FeatureCircle heading={(<>Create a <br /> Budget</>)} classes={"absolute left-4 bottom-0 md:left-auto md:right-[60px] md:-bottom-[125px]"} />
                    </Link>
					<Link to={'/sfg'}>
                    <FeatureCircle heading={(<>Save for <br />a Goal</>)} classes={"absolute right-4 bottom-0 md:right-[160px] md:-bottom-[230px]"} />
					</Link>	
					<Link to={'/inv'}>
					<FeatureCircle heading={(<>Investing</>)} classes={"absolute right-4 bottom-0 md:left-auto md:right-[320px] md:-bottom-[250px]"} />
					</Link>
					
					
					
                </div>
            </div>





            <div className='hidden md:block absolute bottom-4 z-0 left-0'>
                <img src='./assets/images/illustration1.png' alt='illustration' />
            </div>
<div className="absolute   right-7   hidden md:block ">
<div className="bg-gradient-to-b h-12 bg-[#646cff]  cursor-pointer from-primary to-patric-blue w-[50%] ml-24 " onClick={() => setShowModal(true)}>
	<h1 className = "w-[50%] py-3 mt-[3%] font-semibold text-white text-l rounded-lg ml-4 p-12 px-6 mt-10 h-auto "> <center  >
	TESTIMONIALS</center> </h1>
</div>
	
	
	{showModal ? (
				<>
				  <div className="flex justify-center w-full items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
					<div className="relative w-[75%] my-0 mx-auto max-w-3xl">
					  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<div className="bg-gradient-to-b h-auto from-primary to-patric-blue flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
						  <h3 className="text-center text-white text-2xl font-semibold Poppins">Testimonials</h3>
						  <button
							className="bg-transparent border-0 text-black float-right"
							onClick={() => setShowModal(false)}
						  >
							<span className="opacity-7 h-4 w-6  text-white text-xl text-bold block bg-gray-400 py-0 rounded-full">
							  x
							</span>
						  </button>						  
						</div>
						<iframe src='https://drive.google.com/file/d/1w7k5U_B023dogb_ChQAn4SFv9ZYmSUIj/preview'  className="w-full h-[600px]" type="application/pdf"/>
						
						
						<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
						
						  <button
							className="bg-gradient-to-b h-auto from-primary to-patric-blue text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
							type="button"
							onClick={() => setShowModal(false)}
						  >
							Close
						  </button>
						 
						</div>
					  </div>
					</div>
				  </div>
				</>
			  ) : null}

	<a href="https://drive.google.com/file/d/1pYDON6aFoSSpNa-oBdQ3-XcaZ2RV1Ydz/view" download="SYMR-EBook" target='_blank'><h1 className="text-center text-[#404EED] font-semibold Poppins mb-2 mt-12">How Peter and Ann used SYMR (eBook)</h1></a>
	 <div className="border-[1px] my-1 mb-4 w-[180px] m-auto border-[#CACACA]"></div>
		
            <div className="">			
						
			
				<iframe src="https://drive.google.com/file/d/1pXBpvYZyTM9TusjOJvf7pB-uV4uY3q28/preview" width={361} height={190} controls="controls" poster='./assets/images/placeholder1.png'></iframe>
				
				 
				 
            </div>
            <div className="w-[361px] py-4 border-[1px] shadow h-auto border-[#404EED] rounded-lg px-2 mt-2 h-auto" >

               <h1 className="text-center text-[#404EED] font-semibold Poppins">Financial Planning Tools for Young People </h1>

               <div className="border-[1px] my-3 mb-6 w-[261px] m-auto border-[#CACACA]"></div>

               <div className="m-auto ml-4 space-y-4 Poppins">

        
                            <li> DIY - Simple and SMART </li>

                            <li> Data Driven Recommendations </li>

                            <li> No Personal or Bank Details </li>

                            <li> Focus on Your Goals, Not the Bills  </li>

             
               </div>
            </div>
			{console.log("showModal "+showModal)}			
	</div>

</div>


<div className="w-full md:hidden block md:justify-center items-center">

        <div className='md:hidden flex justify-stretch w-full md:justify-center items-center '>
            <div className='absolute top-4 left-4 flex flex-col items-start md:items-center'>
                <img className='w-[50%]' src='./assets/icons/logo.svg' alt='logo' />
                <h1 className='text-primary md:text-center text-left font-bold text-xl lg:text-4xl'>
                    Don't Think Money
                    <br />
                </h1>
                <h3 className='text-primary md:text-center text-left' style = {{color: "black"}}> {/* Changed colour to black. VN*/}
                    Think Goals. Make them Real.
                    <br />
                </h3>
                <h3 className='text-primary md:text-center text-left' style = {{color: "black"}}> {/* Changed colour to black. VN*/}
                    Smart Goals, Smart Execution
                    <br />
                </h3>
				<a href="https://docs.google.com/forms/d/e/1FAIpQLSew8n-prZwrFWOb2jMvCcsykXbKhau0uY1PbU0MR3tn7PZHEQ/viewform" download="SYMR-Glossary" target='_blank'>
				<div className="w-[210px] py-1 border-[1px] text-sm shadow h-[25%] border-[#404EED] rounded-lg px-0 mt-[12%] h-auto]" >
					
						
						<h1 className="text-center text-sm font-semibold Poppins mt-1">
						More exciting features coming soon! <br/>
					   </h1>
					  
					</div>	
				 </a> 
				 <h1 className="text-center text-xs Poppins mt-3">
						This site is best viewed on desktop.<br/> Mobile app coming soon.
					   </h1>
            </div>
			
			<br/>
			
			
            <div className='flex relative flex-col mt-96 md:mt-0 md:py-0 w-full md:w-auto md:flex-row items-center space-y-2 md:space-y-0 md:space-x-20'>
			
                <Link to={"/fhc"} className="z-30">
                    <div className='w-48 h-48 md:w-60 group shadow-xl md:h-60 flex items-center justify-center rounded-full bg-gradient-to-r from-primary hover:from-philipine-silver to-patric-blue hover:to-cultured hover:scale-105 cursor-pointer duration-150'>
					{/* <h1 className='absolute left-4 top-150 md:left-auto md:right-[450px] md:top-[42px] w-[100%] text-slate-400'> <b style = {{color: "grey"}}>Start Here <FontAwesomeIcon icon={faArrowRight} style={{color: "grey"}} /></b> </h1>  */}     
						<h1 className='text-center font-bold text-2xl md:text-4xl text-white group-hover:text-primary'>
                            Know
                            <br />Your
                            <br />
                            Metrics
                        </h1>
                    </div>
                </Link>
                <div className='halfcircle z-0 absolute w-60 h-32 top-[30%] md:w-72 md:h-36 md:top-1/2 md:-rotate-90 md:-left-7 -translate-y-1/2'></div>
                <div className='flex flex-col relative w-full md:w-auto md:h-auto'>
                    <div className='h-32 md:hidden'></div>
                    <div className='h-32 md:hidden'></div>
					
					 <Link to={'/defaults'}>
                    <FeatureCircle heading={(<>Defaults</>)} classes={"absolute left-4 top-0 md:left-auto md:right-[320px] md:-top-[250px]"} />
                    </Link>						
						
                    <Link to={'/bh'}>
                    <FeatureCircle heading={(<>Buy a <br /> Home</>)} classes={"absolute left-[28%] top-10 md:left-auto md:right-[350px] md:-top-[230px]"} />
                    </Link>
                    <Link to={'/as'}>
                    <FeatureCircle heading={(<>Allocate <br /> Savings</>)} classes={"absolute right-4 top-0 md:right-[60px] md:-top-[125px]"} />
                    </Link>
                    
					 <Link to={'/dm'} >
                    <FeatureCircle heading={(<>Pay off <br /> Debt</>)}  classes={"absolute left-[55%] top-10 md:left-auto md:right-[300px] md:-top-[230px]"} />
                    </Link>
                    <Link to={'/vp'}>
                    <FeatureCircle heading={(<>Create a <br /> Budget</>)} classes={"absolute left-4 bottom-0 md:left-auto md:right-[60px] md:-bottom-[125px]"} />
                    </Link>
                  <Link to={'/sfg'}>
                    <FeatureCircle heading={(<>Save for <br />a Goal</>)} classes={"absolute right-[40%] bottom-0 md:right-[160px] md:-bottom-[230px]"} />
                  </Link>
				  <Link to={'/inv'}>
                    <FeatureCircle heading={(<>Investing</>)} classes={"absolute right-4 bottom-0 md:right-[320px] md:-bottom-[250px]"} />
					</Link>
				 
			
                </div>
            </div>





            <div className='hidden md:block absolute bottom-4 z-0 left-0'>
                <img src='./assets/images/illustration1.png' alt='illustration' />
            </div>


        </div>
		

            <div className="md:hidden block my-16 flex justify-center">
			
			
               <div>
			
			<div className="bg-gradient-to-b h-12 from-primary to-patric-blue w-[50%] ml-24">
				<h1 className = "w-[50%] py-3 mt-[3%] font-semibold text-white text-l rounded-lg ml-4 p-12 px-6 mt-10 h-auto  cursor-pointer"> <center onClick={() => setShowModal(true)} >TESTIMONIALS </center> </h1>
			</div>
              
            <div className="">
				<h1 className="text-center text-[#404EED] font-semibold Poppins">How Peter and Ann used SYMR</h1>
				<video 
				 src="https://drive.google.com/file/d/1pXBpvYZyTM9TusjOJvf7pB-uV4uY3q28/view?usp=sharing" className="rounded-lg" width={361} height={190}  controls autoplay muted>
				</video>  
               
            </div>
            <div className="w-[361px] py-4 border-[1px] shadow h-auto border-[#404EED] rounded-lg px-2 mt-2 h-auto" >

               <h1 className="text-center text-[#404EED] font-semibold Poppins">Financial Planning Tools for Young People </h1>

               <div className="border-[1px] my-3 mb-6 w-[261px] m-auto border-[#CACACA]"></div>

               <div className="m-auto ml-4 space-y-4 Poppins">

        
                             <li> DIY - Simple and SMART </li>

                            <li> Data Driven Recommendations </li>

                            <li> No Personal or Bank Details </li>

                            <li> Focus on Your Goals, Not the Bills  </li>

             
               </div>
         </div>
            </div>
            </div>
</div>
        </>
    );
};

export default Home;
