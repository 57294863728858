import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



export function INVLineChart({INVValues}) {
	const STData = []
    const BDData = []
    const REData = []
    const ALData = []
	
	//function for calculating goal allotments
   
		let FV = 0	
		let RT = 0	
		let i = 12
        let stock_ret = INVValues.STAR
        let stock_allo = INVValues.STA
		let bond_ret = INVValues.BDAR
        let bond_allo = INVValues.BDA
		let real_ret = INVValues.REAR
        let real_allo = INVValues.REA
		let alt_ret = INVValues.ALAR
        let alt_allo = INVValues.ALA
		let init_inv = INVValues.init_inv
		let Slack = INVValues.Slack
		let ret_amt = INVValues.ret_amt
		let FVS = 0
		let FVB = 0
		let FVR = 0        
		let FVA = 0
		const labelYears=[]	
		let cur_year = new Date().getFullYear()
		let year = 0
		//Below are the variables for calculating p, r and s for Stocks, Bonds, Real Estate, Alternatives respectively.
		let ps = 0
		let rs = 0
		let ss = 0
		let pb = 0
		let rb = 0
		let sb = 0
		let pr = 0
		let rr = 0
		let sr = 0
		let pa = 0
		let ra = 0
		let sa = 0
				
		
		if (init_inv > 0) {
			// Setting the values
			if (stock_ret > 0 && stock_allo > 0) {
				ps = init_inv * (stock_allo/100)
				rs = (stock_ret/100)/12
				ss = Slack *(stock_allo/100)
			}
			if (bond_ret > 0 && bond_allo > 0) {
				pb = init_inv * (bond_allo/100)
				rb = (bond_ret/100)/12
				sb = Slack *(bond_allo/100)
			}	
			if (real_ret > 0 && real_allo > 0) {
				pr = init_inv * (real_allo/100)
				rr = (real_ret/100)/12
				sr = Slack *(real_allo/100)
			}	
			if (alt_ret > 0 && alt_allo > 0) {
				pa = init_inv * (alt_allo/100)
				ra = (alt_ret/100)/12
				sa = Slack *(alt_allo/100)
			}			
		
			//Calculation of the Retirement Time (RT)
			while(RT === 0){
				let t = i				
				if (stock_ret > 0 && stock_allo > 0) {
					var FVS1 = Number(ps*(Math.pow((1+rs),t)))
					var FVS2 = Number(ss*((Math.pow((1+rs),t) - 1)/rs))
					FVS = (parseInt(FVS1))+(parseInt(FVS2))
					STData.push(FVS)
				}
				if (bond_ret > 0 && bond_allo > 0) {			
					let FVB1 = pb*(Math.pow((1+rb),t))
					let FVB2 = sb*((Math.pow((1+rb),t) - 1)/rb)
					FVB = parseInt(FVB1)+parseInt(FVB2)	
					BDData.push(FVB)
				}
				if (real_ret > 0 && real_allo > 0) {
					let FVR1 = pr*(Math.pow((1+rr),t))
					let FVR2 = sr*((Math.pow((1+rr),t) - 1)/rr)
					FVR = parseInt(FVR1)+parseInt(FVR2)	
					REData.push(FVR)
						
				}
				if (alt_ret > 0 && alt_allo > 0) {				
					let FVA1 = pa*(Math.pow((1+ra),t))
					let FVA2 = sa*((Math.pow((1+ra),t) - 1)/ra)
					FVA = parseInt(FVA1)+parseInt(FVA2)		
					ALData.push(FVA)
				}
				
				FV = parseInt(FVS)+parseInt(FVB)+parseInt(FVR)+parseInt(FVA)				
				if (i === 12){
					labelYears.push(cur_year) 
					year = cur_year
				}
				
				if (FV >= ret_amt){
					RT = i	
				}	
				else {
					if ((i/12)<40){
						year+= 1
						labelYears.push(year) 	
					}
					RT = 0
					i = i+12
				}		
				
				
			}
			
			
		}
	
    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                //   text: 'Chart.js Line Chart - Multi Axis',
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
        },
    };


    //const labels = props.labelMonths.length > 11 ? props.labelMonths.slice(0, props.labelMonths.length):props.labelMonths
    const labels = labelYears;
//
    // const labels = props.labelMonths;

    const data = {
        labels,
        datasets: [
            {
                label: 'Stocks',
                data: STData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgb(255, 99, 132)',
                yAxisID: 'y',
            },
            {
                label: 'Bonds',
                data: BDData,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgb(53, 162, 235)',
                yAxisID: 'y',
            },
            {
                label: 'Real Estate',
                data: REData,
                borderColor: 'rgb(53, 62, 235)',
                backgroundColor: 'rgb(53, 62, 235)',
                yAxisID: 'y',
            },
            {
                label: 'Alternatives',
                data: ALData,
                borderColor: 'rgb(0, 185, 0)',
                backgroundColor: 'rgb(0, 185, 0)',
                yAxisID: 'y',
            },
        ],
    };

    return <Line options={options} data={data} />;
}