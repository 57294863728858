import React from 'react';

const Hamburger = ({ isOpen, setIsOpen }) => {
    return (
        <div onClick={() => setIsOpen(val => !val)} className={`absolute duration-300 top-4 right-4 md:hidden w-10 h-10 rounded-xl flex flex-col justify-between px-2 py-3 ${isOpen ? "bg-white" : " bg-primary"}`} >
            <div className={`${isOpen ? "bg-primary" : "bg-white"} duration-300 h-1 w-5 rounded-xl`}></div>
            <div className={`${isOpen ? "bg-primary" : "bg-white"} duration-300 h-1 w-3 rounded-xl`}></div>
            <div className={`${isOpen ? "bg-primary" : "bg-white"} duration-300 h-1 w-5 rounded-xl`}></div>
        </div >
    );
};

export default Hamburger;