
import { Pie } from '@ant-design/plots';
import Heading from './Heading';


const PieGraph4 = ({ heading, subheading, VS1, VS2, VS3, VS4, VS5, VS6, Surplus,AVS }) => {
 // let slackValue = Number(M1) - Number(M2) - Number(M3) - Number(M4);
 // slackValue = slackValue < 0 ? slackValue = null : slackValue;
  //Surplus=Number(Surplus)?Surplus:0
  Surplus = Number(Surplus) ? Number(Surplus) < 0 ? 0 : Surplus : 0
  AVS=Number(AVS)?AVS:0
  const data = [
    {
      type: "Surplus",
      value: Number(Surplus),
    },
    {
        type: "Books",
      value: Number(VS1),
    },
    {
        type: "Travel",
      value: Number(VS2),
    },
    {
        type: "Eating out",
      value: Number(VS3),
    },
    {
        type: "Shopping",
       value: Number(VS4),
    },
    {
        type: "Send money",
       value: Number(VS5),
    },
    {
        type: "Others",
       value: Number(VS6),
    },

  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
      color: ['#09509c', '#7b57a3', '#b7639c', '#e07891', '#f89888', '#ffbd8a', '#ffbd8a'],
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 40,
      content: '{name} \n {percentage} ({value})',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <div className='space-y-4 justify-center'>
          {
              heading ?
                  <Heading text={heading} classes={'md:w-4/4 '} /> : ''
          }
        {/*<Heading text={heading} classes={'md:w-4/4 '}/>*/}
      {
        subheading &&
        <div className='flex justify-center'>
           <h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>{subheading}</h2>
        </div>
      }
      <Pie {...config}/>

    </div>
  );
};

export default PieGraph4;