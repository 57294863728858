import { Outlet } from 'react-router-dom';
import { useEffect, useState } from "react";
import Footer from "./Footer";
import Hamburger from "./Hamburger";
import SocialIcons from "./SocialIcons";
import Navbar from "./Navbar";


const Layout = () => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {

            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isOpen]);
    return (
        <div className="min-h-screen flex flex-col relative font-Poppins">
            <div className="z-50">
                <Hamburger isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
            {
                isOpen &&
                <div className={`h-full fixed top-0 z-40 w-full right-0 bg-gradient-to-b from-primary to-patric-blue duration-300  flex flex-col items-center py-48 justify-between`}>
                    <Navbar />
                    <SocialIcons />
                </div>
            }

            <div className="flex-1 flex items-stretch w-full md:justify-center">
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default Layout;