import React, { useEffect, useState, useRef } from "react";
import BottomNav_Def from "../components/BottomNav_Def";
import Container from "../components/Container";
import Description from "../components/Description";
import FinancialHealthCheckup from "../components/FinancialHealthCheckup";
import Heading from "../components/Heading";
import Input from "../components/Input";
import PieGraph from "../components/PieGraph";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Cell,Tooltip , CartesianGrid,ResponsiveContainer } from 'recharts';
import { kFormatter } from "../libs";
import ResultInput from "../components/ResultInput";
import { BarChart1 } from "../components/BarChart1";
import {useSelector,useDispatch} from 'react-redux'
import  { actions } from '../store/index'
import YourFHC from "../components/YourFHC";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
 import { faDownload } from '@fortawesome/free-solid-svg-icons'
 import { faYoutube } from '@fortawesome/free-brands-svg-icons'
function Example() {
  
}

	
const Preferences = () => {
	
	const [form1Data, setForm1Data] = useState({
        EFT: 3,
        DTI: 40,
        DTA: 30,
        DCA: 6,
        HCI: 30,
        HOCR: 20,
        SR: 10,
        DP: 20,
		HOCC: 6,
		HIRT: 5,
		PDPM: 'Snowball',
		EWRR: 4,
		
      
    });
	
	const changeHandler = (e) => {
		if (e.target.id === "DTI" ||e.target.id === "DTA" ||e.target.id === "HCI" ||e.target.id === "HOCR" ||e.target.id === "SR" ||e.target.id === "DP" ||e.target.id === "HIRT" ||e.target.id === "EWRR") {
				if (e.target.value < 0 || e.target.value > 100){
					alert("Value should be between 0 and 100");
					e.target.value = ""
				}
		}	
		
		let formValues = {...form1Data, [e.target.id]: e.target.value}
		setForm1Data(formValues)
		sessionStorage.setItem('form1Data', JSON.stringify(formValues))
		
		setForm1Data({
            ...form1Data,
            [e.target.id]: e.target.value
        });
		
	}		
	const clickHandler = () => {
		console.log("Inside Click")
		setForm1Data({
			EFT: 3,
			DTI: 40,
			DTA: 30,
			DCA: 6,
			HCI: 30,
			HOCR: 20,
			SR: 10,
			DP: 20,
			HOCC: 6,
			HIRT: 5,
			PDPM: 'Snowball',
			EWRR: 4,
		});
		let formValues = {...form1Data, EFT: 3,
			DTI: 40,
			DTA: 30,
			DCA: 6,
			HCI: 30,
			HOCR: 20,
			SR: 10,
			DP: 20,
			HOCC: 6,
			HIRT: 5,
			PDPM: 'Snowball',
			EWRR: 4}
		setMethodName('Snowball');	
		sessionStorage.setItem('form1Data', JSON.stringify(formValues))	
		sessionStorage.setItem('MethodName', 'Snowball')
		
	}	
	const[MethodName,setMethodName]=useState('')
	const handleSelectChange = (event) => {
        console.log(MethodName)
        setMethodName(event.target.value);
		sessionStorage.setItem('MethodName', event.target.value)
    };
	const [selectedOption, setSelectedOption] = useState("Snowball");
	
	useEffect(() => {
		document.title = 'SYMR | Defaults';
		if (sessionStorage.getItem('form1Data')){
				setForm1Data(JSON.parse(sessionStorage.getItem('form1Data')))
			}	
		if (sessionStorage.getItem('MethodName')){
				setMethodName(sessionStorage.getItem('MethodName'))
			}		
	  }, []);
    return (
       
	<div className="w-full">
			
			<div className="bg-gradient-to-b h-72 from-primary to-patric-blue">
			<Container classes={"flex flex-col p-4 px-5 space-y-3 md:space-y-0  md:py-4 md:flex-row items-center w-full justify-between"}>
				<div className="w-full md:w-auto">
                        <Link to='/'>
                        <img src="./assets/images/LOGO_White.png" alt="logo" className = "object-scale-down w-[160px] h-[160px]" />
                        </Link>
                    </div>
				<h1 className="text-white font-bold text-center text-2xl md:text-4xl -ml-24">Defaults</h1>
				
				
				<div className="-ml-12 w-full md:w-auto cursor-pointer">
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-3 mt-10 h-auto cursor-pointer " >
							<input type="button" className = "cursor-pointer" value="Reset" onClick = {clickHandler}/>
						</div>	
				</div>		
			</Container>
			<div className="text-white  text-center mb:mx-0 mx-5 md:ml-40 -mt-12 md:-mt-12 text-lg md:text-l">We kind of penciled in some financial safety nets, but these are editable.</div>
		</div>
		<Container classes={"-mt-24 md:-mt-32 py-10 space-y-6 w-full"}>
            <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                        <div className="shadow-md p-6 bg-white space-y-6 md:w-full">
                            <Heading text={"Defaults"} icon={"financial-input"} />
										
                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
								
                                <Input type={"number"} id={"EFT"} value={form1Data.EFT} label={"Emergency Fund Threshold (No. of Months)"} tooltip={"The fund covers the monthly expenses for desired amount of months."} name={"EFT"} min = {0} onChange={changeHandler} />
                                <Input type={"number"} id={"DTI"} value={form1Data.DTI} label={"Debt To Income Threshold (% ratio)"} tooltip={"How much of the Income going into Debt is considered safe."} name={"DTI"} min={0} max={100} onChange={changeHandler}/>
                                <Input type={"number"} id={"DTA"} value={form1Data.DTA} label={"Debt To Assets Threshold (% ratio)"} tooltip={"How much Debt as ratio of the Total Assets is considered safe."} name={"DTA"} min={0} max={100} onChange={changeHandler} required/>
                                <Input type={"number"} id={"DCA"} value={form1Data.DCA} label={"Debt Coverage by Assets (No. of Months)"} name={"DCA"} tooltip={"How much of monthly Obligations (debt and rent) are covered by Assets."} min={0} onChange={changeHandler} /> 
                                <Input type={"number"} id={"HCI"} value={form1Data.HCI} label={"Housing Cost to Income Threshold (% ratio)"} name={"HCI"} tooltip={"How much of the Income going into Housing Cost is considered prudent."} min={0} max={100} onChange={changeHandler} /> 
                                <Input type={"number"} id={"HOCR"} value={form1Data.HOCR} label={"Home Ownership Cost Over Current Rent (% ratio)"} name={"HOCR"} tooltip={"How much of home ownership cost increase is allowed compared to current rent."} min={0} max={100} onChange={changeHandler} />
                                <Input type={"number"} id={"SR"} value={form1Data.SR} label={"Savings Ratio (% ratio)"} name={"SR"} tooltip={"A savings ratio (slack/income) is what percentage of the Income can be saved or invested."} min={0} max={100} onChange={changeHandler} />
                                <Input type={"number"} id={"DP"} value={form1Data.DP} label={"Down Payment Ratio of Home Price (% ratio)"}  name={"DP"} tooltip={"How much of the home price will be funded by a down payment."} min={0} max={100} onChange={changeHandler} />
								<Input type={"number"} id={"HOCC"} value={form1Data.HOCC} label={"Home Ownership Cost Coverage Fund (No. of Months)"}  name={"HOCC"} tooltip={"How many months of home ownership cost and other expenses can be covered by existing savings."} min={0} onChange={changeHandler} />
								<Input type={"number"} id={"HIRT"} value={form1Data.HIRT} label={"High Interest Rate Threshold (%)"}  name={"HIRT"} tooltip={"How much interest rate is considered high for debt pay-off decision?"} min={0} max={100} onChange={changeHandler} />
								<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 md:gap-y-2 lg:gap-x-28 md:ml-2  md:px-0">
								<label htmlFor="dropdown" className="peer-focus:text-primary text-chinese-black/70  xl:ml-1 font-medium text-[10px] sm:text-sm md:text-sm">Which method to be default on the Debt PayOff page?</label>
								<select value = {MethodName} id = "dropdown" className="w-full bg-bright-gray/50 rounded-xl py-3 px-2 focus:outline-primary outline-primary" onChange={handleSelectChange} name = {"method"}>
										<option value="Snowball">Snowball</option>
										<option value="Avalanche">Avalanche</option>
								</select>	
								</div>
								<Input type={"number"} id={"EWRR"} value={form1Data.EWRR} label={"Expected withdrawal rate in retirement (% ratio)"} min={0} name={"EWRR"} tooltip={"To calculate the retriement corpus"} min={0} onChange={changeHandler} />
								

						{console.log("form1Data.PDPM Pref page"+MethodName)}
								
								
                            </div>
                        
                        </div>						        
                    </div>
					
            </div>
			 <div className="pt-10">
                    <BottomNav_Def />
                </div>
		</Container>
	</div>	
		 
    );
};

export default Preferences;
