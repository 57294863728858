import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const MultipleAxisGraph = ({Loans,APRs}) => {


  const chartData={
    series: [
      {
        name: 'Amount ',
        type: 'column',
        
        data: [Number(Loans[0]), Number(Loans[1]), Number(Loans[2]), Number(Loans[3])],
      },
      {
        name: 'Interest Rate (%)',
        type: 'line',
        data: [APRs[0], APRs[1], APRs[2], APRs[3]],
      },
    ],
    options: {
      colors: ['rgba(	255, 165, 0, 0.8)', 'rgba(54, 162, 255)'],
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [0, 4],
        
      },
    
      tooltip: {
        shared: true,
        intersect: false,
        
      
      },
      yaxis: [
        {
          title: {
            text: 'Loans',
          },
          min: 0,
          tickAmount:5

        },
        {
          opposite: true,
          title: {
            text: 'APR',
          },
          min: 0.0,
          tickAmount:5
       
          
        },
      ],
      xaxis: {
        categories: [
          'Car Loan', 'Credit Card Loan', 'Personal Loan', 'Educational Loan'
        ],
      },
    },
  }

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={550}
   
    />
  );
};

export default MultipleAxisGraph;
