import React, { useEffect, useState,useRef } from "react";
import BottomNav from "../components/BottomNav";
import Container from "../components/Container";
import Description from "../components/Description";
import FinancialHealthCheckup from "../components/FinancialHealthCheckup";
import Heading from "../components/Heading";
import Input from "../components/Input";
import PieGraph from "../components/PieGraph";
import ResultInput from "../components/ResultInput";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Cell, ResponsiveContainer } from 'recharts';
import SelectMenu from "../components/SelectMenu";
import ASPieGraph from "../components/ASPieGraph";
import LineChart from "../components/LineChart";
import {useSelector,useDispatch} from 'react-redux'
import  { actions } from '../store/index'
import YourFHC from "../components/YourFHC";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faDownload } from '@fortawesome/free-solid-svg-icons'
 import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import emailjs from '@emailjs/browser'; 
 

const KFormater = (num) => Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#0A1827" textAnchor="middle" dy={value < 0 ? 16 : -6} >{`${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</text>;
};

const AS = () => {
	const form = useRef();
	  const [status, setStatus] = useState('null'); /*New change */	 
	  const sendEmail = (e) => {
		e.preventDefault();
		setStatus("sending"); /*New change */
		
		emailjs.sendForm('service_ykjjhjm', 'template_k11stse', form.current, 'wwqQKgCmrGkZJXbt6')
		  .then((result) => {
			  console.log(result.text);
			  setStatus("sent");
		  }, (error) => {
			  console.log(error.text);
			  setStatus("error");
		  });
	  };

    const counter=useSelector((state)=>state.formdata)
    const dispatch=useDispatch()
    const hasCodeExecuted = useRef(false);


    const [selects, setselects] = useState();


    const [formData, setFormData] = useState({
        M1: null,
        M2: null,
        M3: null,
        M4: null,
        T1: null,
        T2: null,
        T3: null,
        T4: null,
        H1: null,
        H2: null,
        H3: null,
        H4: null,
        H5: null,
        H6: null,
        H7: null,
        H8: null,
        H9: null,
      
      

    }); // setting form data variables for input

    
    // states for storing names of Goal GL1, GL2 ...
    const [GoalGL1, setGoal1] = useState('')
    const [GoalGL2, setGoal2] = useState('')
    const [GoalGL3, setGoal3] = useState('')
    const [GoalGL4, setGoal4] = useState('')

    //an object for storing calculated variables from input data
    const [resultData, setResultData] = useState({
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        R5: 0,
		R6: 0, //Added to calculate Debt to Asset Ratio. -VN
		R7: 0, //Added to calculate Savings Ratio. -VN
        R1a: 0,
        R2a: 0,
        R3a: 0,
        R4a: 0,
        R5a: 0,
        S2: 0
    });

    const [data,setData]=useState({
        T1New: 0,
        T3New: 0,
        LNew: 0,
        L: null,
        L1: null,
        GL1: 0,
        GL2: 0,
        GL3: 0,
        GL4: 0,
        GLT1: 0,
        GLT2: 0,
        GLT3: 0,
        GLT4: 0,
		EFC: 0,
		POD: 0,
		GA: 0

    })

    data.LNew= data.L
    

    const [Slack, setSlack] = useState(0);// declaring state for slack
    const [Networth, setNetworth] = useState(0); // declaring state for networth
	
	
    // Handling changes in Goal selections such as travel, education....
    const goalChangeHandler = (event) => {

        if (event.target.name === 'GoalGL1') {
            setGoal1(event.target.value)
			//sessionStorage.setItem('GoalGL1', event.target.value)
        }
        else if (event.target.name === 'GoalGL2') {
            setGoal2(event.target.value)
			//sessionStorage.setItem('GoalGL2', event.target.value)
        }
        else if (event.target.name === 'GoalGL3') {
            setGoal3(event.target.value)
			//sessionStorage.setItem('GoalGL3', event.target.value)
        }
        else if (event.target.name === 'GoalGL4') {
            setGoal4(event.target.value)
			//sessionStorage.setItem('GoalGL4', event.target.value)
        }

    };
	useEffect(() => {
		if (sessionStorage.getItem('data')){
				setData(JSON.parse(sessionStorage.getItem('data')))
			}
		if (sessionStorage.getItem('formData')){
				setFormData(JSON.parse(sessionStorage.getItem('formData')))
			}	
		if (sessionStorage.getItem('inputFields')){
				setInputFields(JSON.parse(sessionStorage.getItem('inputFields')))
			}	
		if (sessionStorage.getItem('inputFields1')){
				setInputFields(JSON.parse(sessionStorage.getItem('inputFields1')))
			}		
		if (sessionStorage.getItem('GoalGL1')){
				console.log("Get GoalGL1 ")
				setGoal1(sessionStorage.getItem('GoalGL1'))
				console.log("Set GoalGL1 "+ GoalGL1)
			}
		if (sessionStorage.getItem('GoalGL2')){
				setGoal2(sessionStorage.getItem('GoalGL2'))
			}
		if (sessionStorage.getItem('GoalGL4')){
				setGoal4(sessionStorage.getItem('GoalGL4'))
			}
		if (sessionStorage.getItem('GoalGL3')){
				setGoal3(sessionStorage.getItem('GoalGL3'))
			}		
	},[])	
	
    //handling changes in inout variables and updating them
    const changeHandler = (e) => {
		let formValues = {...formData, [e.target.id]: e.target.value}
		setFormData(formValues)
		sessionStorage.setItem('formData', JSON.stringify(formValues))
		
		setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });	

        

     

    };

    const dataChangeHandler = (e) => {
		
		let formValues = {...data, [e.target.id]: e.target.value}
		setData(formValues)
		sessionStorage.setItem('data', JSON.stringify(formValues))
		
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
		
		    

    };

   // setting input fields for goals GL! and GL2
    const [inputFields, setInputFields] = useState([
        {
            label: <div>
                <label className="peer-focus:text-primary  text-chinese-black/70  xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm">Financial Goal 1 (Select)</label>
				{console.log("GoalGL1 input 12" + GoalGL1)}  
				<select id="GoalGL1" className="bg-bright-gray/50  text-gray-900 text-sm  py-3 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700  focus:outline-primary outline-primary" name = {'GoalGL1'} onChange={goalChangeHandler}>					
                    <option value="" className='peer-focus:text-primary text-chinese-black/70 hidden xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm text-black '>Select</option>
                    <option value="Education" className='text-black' >Education</option>
                    <option value="Travel" className='text-black'>Travel</option>
                    <option value="Investment" className='text-black'>Investment</option>
                    <option value="Other" className='text-black'>Other</option>
                </select>
					
					
            </div>,
            flag: true
        },
        {
            label: 'Financial Goal 1 Amount',
            flag: false,
            id: 'GL1', 
			name: 'GL1',
            val: data.GL1
        },
        {
            label: 'Financial Goal 1 Time (Years)',
            flag: false,
            id: 'GLT1', 
			name: 'GLT1',
            val: data.GLT1
        },
        {
            label: <div>
                <label className="peer-focus:text-primary  text-chinese-black/70  xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm">Financial Goal 2 (Select)</label>

                <select id="GoalGL2" name="GoalGL2" className="bg-bright-gray/50  text-gray-900 text-sm  py-3 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700  focus:outline-primary outline-primary" onChange={goalChangeHandler}>
                    <option selected className='peer-focus:text-primary text-chinese-black/70 hidden xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm text-black '>Select</option>
                    <option value="Education" className='text-black' >Education</option>
                    <option value="Travel" className='text-black'>Travel</option>
                    <option value="Investment" className='text-black'>Investment</option>
                    <option value="Other" className='text-black'>Other</option>
                </select>
            </div>,
            flag: true
        },
        {
            label: 'Financial Goal 2 Amount',
            flag: false,
            id: 'GL2', 
			name: 'GL2',
            val: data.GL2

        },
        {
            label: 'Financial Goal 2 Time (Years)',
            flag: false,
            id: 'GLT2', 
			name: 'GLT2',
            val: data.GLT2
        },
    ]);

    //handling dynamic additions of Goal GL3 and GL4 on add goal click button
    const handleAddFields = () => {
        const values = [...inputFields];

        if (data.GL4 != '') {

        }
		else if (!inputFields.find(ida => ida.id === 'GL3')) {
                const len = 3
                
                values.push({
                    label: <div>
                        <label className="peer-focus:text-primary  text-chinese-black/70  xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm">Financial Goal 3 (Select)</label>

                        <select id="GoalGL3" name="GoalGL3" className="bg-bright-gray/50  text-gray-900 text-sm  py-3 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700  focus:outline-primary outline-primary" onChange={goalChangeHandler}>
                            <option selected className='peer-focus:text-primary text-chinese-black/70 hidden xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm text-black '>Select</option>
                            <option value="Education" className='text-black' >Education</option>
                            <option value="Travel" className='text-black'>Travel</option>
                            <option value="Investment" className='text-black'>Investment</option>
                            <option value="Other" className='text-black'>Other</option>
                        </select>
                    </div>,
                    flag: true
                },
                    {
                        label: `Financial Goal ${len} Amount`,
                        flag: false,
                        id: `GL${len}`,
                        val: data.GL3, 
						name: 'GL3'
                    },
                    {
                        label: `Financial Goal ${len} Time (Years)`,
                        flag: false,
                        id: `GLT${len}`,
                        val: data.GLT3, 
						name: 'GLT3'
                    },
                )
            }
			
        else if (data.GL2 != '' && data.GL1 != '') {
            if (data.GL3 != '' && !inputFields.find(ida => ida.id === 'GL4')) {

                const len = 4
              
                values.push({
                    label: <div>
                        <label className="peer-focus:text-primary  text-chinese-black/70  xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm">Financial Goal 4 (Select)</label>

                        <select id="GoalGL4" name="GoalGL4" className="bg-bright-gray/50  text-gray-900 text-sm  py-3 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700  focus:outline-primary outline-primary" onChange={goalChangeHandler}>
                            <option selected className='peer-focus:text-primary text-chinese-black/70 hidden xl:ml-4 font-medium text-[10px] sm:text-xs md:text-sm text-black '>Select</option>
                            <option value="Education" className='text-black' >Education</option>
                            <option value="Travel" className='text-black'>Travel</option>
                            <option value="Investment" className='text-black'>Investment</option>
                            <option value="Other" className='text-black'>Other</option>
                        </select>
                    </div>,
                    flag: true
                },
                    {
                        label: `Financial Goal ${len} Amount`,
                        flag: false,
                        id: `GL${len}`,
                        val: data.GL4, 
						name: 'GL4'
                    },
                    {
                        label: `Financial Goal ${len} Time (Years)`,
                        flag: false,
                        id: `GLT${len}`,
                        val: data.GLT4, 
						name: 'GLT4'
                    },
                )
            }
            
        }
    

        setInputFields(values);
		
    };
	//sessionStorage.setItem('inputFields', JSON.stringify(values))

    const update=()=>
    {
        Object.keys(counter).length>0?
        setFormData(counter):
        setFormData(formData)
    }

    useEffect(() => {
		document.title = 'SYMR | Allocate Savings';
        if (!hasCodeExecuted.current) {

            update()
            hasCodeExecuted.current = true;

            window.scrollTo(0, 0);
        }

        const { M1, M2, M3, M4, T1, T2, T3, T4} = formData;  // getting input variables from form data
        const {GL1, GL2 }=data
        setResultData({
            R1: (Number(M3) / Number(M1)) * 100,
            R2: Number(T1) / (Number(M2) + Number(M3) + Number(M4)) * 100,
            R3: ((Number(T1) + Number(T2)) /  (Number(T3)) * 100),	// Fixed to check if T3 is null. If null, the denominator is set to 1. VN
            R4: ((Number(T1)+Number(T2)) / (Number(M3) + Number(M4)) * 100), // Fixed to add assets T2 to the calculation. VN
            R5: (Number(M4) / Number(M1)) * 100,
			R6: (Number(T3)/(Number(T1)+Number(T2)))*100, //New Result variable added to check the debt ratio if high. - VN
			R7: (Number(Slack)/Number(M1))*100,  //New Result variable added to calculate the savings ratio. - VN
        });//performing calculations and setting result variables

        setCartData([{
            name: 'Total Savings',
            value: Number(T1),
            color: "green"
        },
        {
            name: 'Investments',
            value: Number(T2),
            color: "green"
        },
        {
            name: 'Total Debt',
            value: -Number(T3),
            color: "red"
        },
        {
            name: 'Mortgage',
            value: -Number(T4),
            color: "pink"
        }]); // updating graph data

        
        data.T1New = T1
        data.T3New = T3
        dispatch(actions.updateFormData(formData))

        setSlack(Number(M1) - (Number(M2) + Number(M3) + Number(M4))); // setting Slack 1 after computing
        setNetworth((Number(T1) + Number(T2)) - Number(T3)); // setting Networth 
    }, [formData, Slack, GoalGL1, GoalGL2,data]);
	
	
	
    const [chartData, setCartData] = useState([{
        name: 'Total Savings',
        value: Number(formData.T1),
        color: "green"
    },
    {
        name: 'Investments',
        value: Number(formData.T2),
        color: "green"
    },
    {
        name: 'Total Debt',
        value: -Number(formData.T3),
        color: "red"
    },
    {
        name: 'Mortgage',
        value: -Number(formData.T4),
        color: "pink"
    }]); // declaring chart data
	
	const [form1Data, setForm1Data] = useState({
			EFT: 3,
			DTI: 40,
			DTA: 30,
			DCA: 6,
			HCI: 30,
			HOCR: 20,
			SR: 10,
			DP: 20,
			HOCC: 6,
			HIRT: 5,
			PDPM: 'Lowest Balance first',
			
		  
		});
	useEffect(() => {
			if (sessionStorage.getItem('form1Data')){
					setForm1Data(JSON.parse(sessionStorage.getItem('form1Data')))
				}	
				
		},[])
    // for dynamic addition of statments to show in recommendation section
    let goalStats = ''
	let goals_name = ''
    //function for calculating goal allotments
    function TenureAllotment(Ln) {
        let values = [
            { 'name': "Education", amount: data.GL1, tenure: data.GLT1 },
            { 'name': "Travel", amount: data.GL2, tenure: data.GLT2 },
            { 'name': "Investment", amount: data.GL3, tenure: data.GLT3 },
            { 'name': "Other", amount: data.GL4, tenure: data.GLT4 },
        ]
        console.log("Hello")
        // values = values.filter(value => value.amount > 0).sort((a, b) => a.amount - b.amount); //sorting values in ascending order who are greater than 0
        values = values.filter(value => value.tenure > 0).sort((a, b) => a.tenure - b.tenure); //sorting values in ascending order who are greater than 0
        var ex_savings = Number(formData.T1) - (Number(form1Data.EFT)*(Number(formData.M4) + Number(formData.M2) + Number(formData.M3)))
		console.log("Number(formData.T1) "+Number(formData.T1))
		console.log("Number(form1Data.EFT) "+Number(form1Data.EFT))
		console.log("Number(formData.M4) "+Number(formData.M4))
		console.log("Number(formData.M2) "+Number(formData.M2))
		console.log("Number(formData.M3) "+Number(formData.M3))
		console.log("(Number(formData.M4) + Number(formData.M2) + Number(formData.M3)) "+(Number(formData.M4) + Number(formData.M2) + Number(formData.M3)))
		console.log("ex_savings "+ex_savings)
        var ES = ex_savings > 0 ? ex_savings : 0 
		data.GA = 0
        values.map((val) => {
            if (val.amount > 0) {
                console.log("Slack is "+Slack)
				
				var i = 0
                if (Number(Ln) - Number(val.amount) >= 0) {
                    Ln = Number(Ln) - Number(val.amount)
                    console.log('T3', Ln)
					if (i === 0) {
						goalStats += `\n Allocate a portion ${val.amount} of lumpsum amount to achieve your Goal ${val.name}. `
						console.log("data.GA is "+data.GA)
						console.log("val.amount "+val.amount)
						data.GA = Number(data.GA) + Number(val.amount)
						console.log("data.GA is "+data.GA)
					}
					else if (i > 0) {
						console.log("i 1 "+val.amount)
						goalStats += `\n\n Allocate a portion ${val.amount} of lumpsum amount to achieve your Goal ${val.name}. `
						console.log("val.amount 2"+val.amount)
						data.GA = data.GA+val.amount
					}
					i+=1
					console.log("i is "+i)
                }
                else {
                    if ((Number(Ln) + Number(ES)) - Number(val.amount) >= 0) {
                        goalStats += `Allocate remaining lumpsum amount of ${Number(Ln)} and extra savings of ${Number(ES)} to achieve your Goal ${val.name}. `
						data.GA = Number(Ln)
                        Ln = 0
                    }
                    else {
                        console.log("Ln is "+Ln)
                        if (Ln > 0) {
                            if (Number(Slack).toFixed(0) > 0) {
                                var months = (Number(val.amount) - Number(Ln)) / Slack
                            }
                            goalStats += `\nAllocate lumpsum amount of ${Number(Ln)} towards a portion of your Goal ${val.name}. `
							data.GA = Number(Ln)
                            if (months?.toFixed(0)> 0) {
                                goalStats += `Remaining amount can be saved using slack in ${months?.toFixed(0)} months.`
                            }
                            Ln = 0
                        }
                        else {
                            if (Number(Slack).toFixed(0) > 0) {
                                var months1 = Number(val.amount) / Slack
								goalStats += `\nSlack of ${Slack} can be used to achieve your Goal ${val.name}. It will take ${months1?.toFixed(0)} months to achieve it.`
                            }
                            
                        }
                    }
                    
                }
            }
        })

      
        return Ln
    }


    return (
        <div className="w-full ">
			<form ref={form} onSubmit={sendEmail}>
            <div className="bg-gradient-to-b h-72 from-primary to-patric-blue">
                <Container classes={"flex flex-col p-4 px-5 space-y-6 md:space-y-0  md:py-0 md:flex-row items-center w-full justify-between"}>
                   <div >
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-6 h-auto " >
							<div>
								<Link to={'/'}>
								<img src="./assets/images/LOGO_White.png" alt="logo" className = "object-scale-down w-[160px] h-[160px]" />
								</Link>								
							</div>
						</div>
					</div>	
					<div className=" -ml-48 " >
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-6 mt-10 h-auto " >
							<a href="https://www.loom.com/share/792373273a834407a304e62eadddb398?sid=e6243f50-75c7-4c38-8269-e840ca25d40a" download="SYMR-Glossary" target='_blank'>
							 <center><FontAwesomeIcon icon={faYoutube} style={{color: "white", fontSize: '18px',  marginTop: '5px' }} /> How-To  </center> 
							</a>
						</div>
						<div className="w-auto py-1 mt-[5%] text-white text-l bg-[#646cff] h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<a href="https://drive.google.com/file/d/16K6MSx9zpJxhAGi5ohJvTqZO_qiLoZww/view" download="SYMR-Glossary" target='_blank'>
							 <center> <FontAwesomeIcon icon={faDownload} style={{color: "white", fontSize: '20px'}} />  Glossary  </center> 
							</a>
						</div>
					</div>	
					<div className=" -ml-48 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-12 h-auto " >
							<h1 className="text-white font-bold text-center text-2xl md:text-4xl">Allocate Savings</h1>
						</div>
						<div className="w-auto py-1 mt-[6%] text-white text-l h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<div className="text-white  text-center mb:mx-0 mx-5 md:ml-30 -mt-3 md:-mt-7 text-lg md:text-2xl">You've got money! Find the best way to deploy it</div> 
						</div>
					</div>			
                   <div className="-ml-48 w-full md:w-auto cursor-pointer">
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-3 mt-10 h-auto cursor-pointer " >
							<input type="submit" className = "cursor-pointer" value="Send my Inputs" />
						</div>	
						{status === "sending" && (
							<div style={{ color: "white" }}>Sending...</div>
							)
						}
						{status === "sent" && (
							<div style={{ color: "green" }}>Data sent!</div>
						)
						}
						{status === "error" && (
							<div style={{ color: "red" }}>Failed to send data.</div>
						)
						
						}	
                    </div>
					 <div className="-ml-24 w-full md:w-auto">
					 </div>
                </Container>

            </div>
            <Container classes={"-mt-24 md:-mt-32 py-10 space-y-6"}>
             
                <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                        <div className="shadow-md p-6 bg-white space-y-6 md:w-1/2">
                            <Heading text={"Basic Financial Inputs "} icon={"financial-input"} />
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
                                <Input type={"number"} id={"M1"} value={formData.M1} label={"Monthly Income"} tooltip={"Enter your total monthly income from all sources: salary, business income, rental income etc."} name={"M1"} min={0} onChange={changeHandler} required />
                                <Input type={"number"} id={"T1"} value={formData.T1} label={"Total Savings (Liquid Funds)"} tooltip={"Refers to readily accessible funds held in cash or highly liquid assets, such as savings accounts or money market accounts, that can be quickly and easily utilized for immediate financial needs or emergencies."} name={"T1"} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M2"} value={formData.M2} label={"Monthly Expenses"} tooltip={"Your total monthly expenses for everything except loans and debt payments and rent. It should include everything else such as food, utilities, shopping, healthcare, transportation, education etc."} name={"M2"} min={0} onChange={changeHandler} required/>
                                <Input type={"number"} id={"T2"} value={formData.T2} label={"Total Investments (Non-Cash)"} name={"T2"} tooltip={"Total amount invested in stocks, bonds, 401K, IRA, real estate, or other financial instruments."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/}
                                <Input type={"number"} id={"M3"} value={formData.M3} label={"Monthly Debt Payments"} name={"M3"} tooltip={"Monthly payments you make for all loans such as credit card, car payments, etc."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/ }
                                <Input type={"number"} id={"T3"} value={formData.T3} label={"Total Debt (Except Mortgage)"} name={"T3"} tooltip={"Total amount of debt you need to repay. This includes credit card debt, car loan etc."}  min={0}onChange={changeHandler} />
                                <Input type={"number"} id={"M4"} value={formData.M4} label={"Housing/Rent, Tax, Insurance"} name={"M4"} tooltip={"This includes your monthly rent or mortgage payment + property taxes + home insurance + home warranty etc. Include this for all your properties."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"T4"} value={formData.T4} label={"Max Debt Interest Rate (%)"}  name={"T4"} tooltip={"This is the highest interest rate on any of your loans or debts other than mortgage."} min={0} onChange={changeHandler} />
                            </div>


                        </div>


                     
              <YourFHC R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} Slack={Slack}/>


                    </div>
					<Input type={"hidden"} id = {"page_name"} value = {"Allocate Savings"} name={"page_name"} onChange={changeHandler}/>
                </div>



              

                <div className="flex-1 w-full md:flex-none space-y-6">
                    <div className="shadow-md p-6 bg-white space-y-12">
                        <div className="hidden md:block">

                            <Heading text={"Savings Details"} icon={"financial-input"} />
                        </div>
                        <div className="block md:hidden md:px-0 md:text-center">

                            <Heading text={"Savings Details"} classes={' '} />
                        </div>


                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-28 md:ml-7  md:px-0">

                            <Input type={"number"} id={"L"} name={"L"} value={data.L} label={"Lump Sum Amount"} tooltip={"This is the amount you want to allocate towards your financial goals."} onChange={dataChangeHandler} />
                      

					  {/* <Input type={"number"} id={"L1"} name={"L1"} value={data.L1} label={"Avg. Interest Rate (%)"} onChange={dataChangeHandler} /> */}
                          
                        </div>
					
					<div >
                    <div className="md:flex hidden ml-28 ">
                        <h1 className="text-primary text-center text-l font-bold  md:ml-24 md:mr-24">Financial Goal</h1> 
						<h1 className="text-primary text-center text-l font-bold  md:ml-48 md:mr-12">Amount</h1>
                        <h1 className="text-primary text-center text-l font-bold  md:ml-96 md:mr-12 ">Time (Years)</h1>
                        
						

                    </div>
<br/>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-0 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden ">
                        <h1 className=" text-center text-black text-l   mt-3 -md:mt-7 md:ml-36 ">Education</h1>

                        <Input type={"number"} id={"GL1"} name={"GL1"} value={data.GL1} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"GLT1"} name={"GLT1"} value={data.GLT1} onChange={dataChangeHandler} />
                       


                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:hidden grid">
                        <h1 className="text-center text-l   md:mt-3 md:ml-28 ">Education</h1>
                        <Input label={"Amount"} type={"number"} id={"GL1"} name={"GL1"} value={data.GL1}  onChange={dataChangeHandler} />
                        <Input label={"Time"} type={"number"} id={"GLT1"} name={"GLT1"} value={data.GLT1} onChange={dataChangeHandler} />
                        

                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-0 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden">
                        <h1 className="text-center  text-l  mt-3 -md:mt-7 md:ml-36">Travel</h1>
                        <Input type={"number"} id={"GL2"} name={"GL2"} value={data.GL2} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"GLT2"} name={"GLT2"} value={data.GLT2} onChange={dataChangeHandler} />                        

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1  md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className="text-center text-l  md:mt-3 md:ml-28 ">Travel</h1>
                        <Input label={"Amount"} type={"number"} id={"GL2"} name={"GL2"} value={data.GL2} onChange={dataChangeHandler} />
                        <Input label={"Time"} type={"number"} id={"GLT2"} name={"GLT2"} value={data.GLT2} onChange={dataChangeHandler} />
                        
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 md:grid hidden">
                        <h1 className="text-center text-l mt-3 -md:mt-7 md:ml-36">Investment</h1>
                        <Input type={"number"} id={"GL3"} name={"GL3"} value={data.GL3} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"GLT3"} name={"GLT3"} value={data.GLT3} onChange={dataChangeHandler} />
                        

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className=" text-center text-l   md:mt-3 md:ml-28 ">Investment</h1>
                        <Input label={"Amount"} type={"number"} id={"GL3"} name={"GL3"} value={data.GL3} onChange={dataChangeHandler} />
                        <Input label={"Time"} type={"number"} id={"GLT3"} name={"GLT3"} value={data.GLT3} onChange={dataChangeHandler} />                     

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0  md:grid hidden">
                        <h1 className=" text-center text-l  mt-3 -md:mt-7 md:ml-36">Other</h1>
                        <Input type={"number"} id={"GL4"} name={"GL4"} value={data.GL4} onChange={dataChangeHandler} />
                        <Input type={"number"} id={"GLT4"} name={"GLT4"} value={data.GLT4} onChange={dataChangeHandler} />
                       

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-20 md:mt-0 mt-5 md:hidden grid">
                        <h1 className="text-center text-l  md:mt-3 md:ml-28 ">Other</h1>
                        <Input label={"Amount"} type={"number"} id={"GL4"} name={"GL4"} value={data.GL4} onChange={dataChangeHandler} />

                        <Input label={"Time"} type={"number"} id={"GLT4"} name={"GLT4"} value={data.GLT4} onChange={dataChangeHandler} />

                        
                    </div>              




                    <div>

                    </div>
                </div>
						
						
                       


                    </div>
                </div>






                <div className="block md:flex   px-5">

                    <div className="hidden md:block w-7/12">
                        <PieGraph heading="Current Cash Flow Distribution" subheading="" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
                    </div>
                    <div className="md:hidden">
                        <PieGraph heading="Current Cash Flow Distribution" subheading="" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
                    </div>
                    <div className="md:pl-14 mt-10 md:mt-24">
                        <Description R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} R6={resultData.R6} R7={resultData.R7} Networth={Networth} /> {/* Added R6, R7 to print the recommendation based on Debt/Asset ratio & Savings ratio. -VN */}
                    </div>




                </div>








                {console.log("data.L " + data.L)}
                {console.log("form1Data.HIRT " + form1Data.HIRT)}
				
					<Heading text="Recommendations" classes={"text-left"} />
				<div className="w-full md:flex-1  shadow-md  p-8 ">
				{(Number(data.L)) > 0 && ((Number(resultData.R2)).toFixed(0) < (Number(form1Data.EFT)*100)) && (Number(resultData.R2)).toFixed(0) > 0 ?
					<div className="w-full md:flex-1  mt-0">
							<h1><b>Emergency Fund Coverage</b></h1>
						<div className="flex justify-left pt-3 pb-5 ">
							<div className="md:w-full md:px-0 space-y-2">
								<p className="text-left">You should allocate Lumpsum amount to boost your Emergency Fund. </p>
								{(Number(formData.T1) + Number(data.L)) > (Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))) ?
									<div className="text-left">
								<p>Add a portion of Lumpsum amount ({(Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))) - (Number(formData.T1))}) to your Savings {formData.T1} to bring Expense Coverage Ratio to {Number(form1Data.EFT)*100}%. </p>
										<p className="hidden">{data.LNew = Number(data.L) - (Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))-Number(formData.T1))} {data.T1New = (Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4)))} {data.EFC = (Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))) - (Number(formData.T1))} </p>
									</div>
									:
									<div className="text-left">
										<p> Boost Savings {formData.T1} by using the Lumpsum Amount {data.L}.  </p>
										{Slack > 0 ? <p className="text-left">You have {Slack} surplus in your budget every month, you can use this to boost your Emergency Fund to at least {Number(form1Data.EFT)*100}% of expenses. This will take {(((Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))) - Number(data.L)) /Slack).toFixed(0) } months. Then start using your monthly Slack towards your financial goals.</p> : ''}

										<p className="hidden">{data.LNew = 0} {data.EFC = data.L}</p>
									</div>
									
								}
								

							</div>
						</div>

					</div> : ''}  


				{(data.LNew > 0 ? 	
					isNaN(formData.T4) ? '' :
						((Number(formData.T4) > Number(form1Data.HIRT)) && formData.T3 > 0) ?
							<div className="w-full md:flex-1">
									<h1><b>High Debt or Interest rate (APR)</b></h1>
								<div className="flex justify-left pt-5 pb-2 ">
									<div className="md:w-full md:px-0 space-y-2">
										<p className="text-left">You have high interest rate debt. Consider paying off your debt.</p>
										{Number(data.LNew) >= Number(formData.T3) && formData.T3 > 0 && data.LNew > 0 ?
											<div className="text-left">
												<p >You can pay off your entire Debt of { Number(formData.T3) }.</p>
												<p className="hidden"> {data.T3New = 0} {data.LNew= Number(data.LNew) - Number(formData.T3)} {data.POD = Number(formData.T3)}</p>

											</div>
											:
											formData.T3 > 0 && data.LNew > 0 ?
												<div className="text-left">
													<p> You can pay off a portion {data.LNew} of your Debt. </p>
													{Slack > 0 ? <p className="text-left">You have {Slack} surplus in your budget every month, you can use this to first pay off your remaining debt and then start saving for your financial goals.</p> : ''}
													<p className="hidden">{data.T3New = Number(formData.T3) - Number(data.LNew)} {data.LNew= 0} {data.POD = Number(data.LNew) } </p>


												</div> : 
												<div className="text-left">
													{Slack > 0 ? <p className="text-left">You have {Slack} surplus in your budget every month, you can use this to first pay off your remaining debt and then start saving for your financial goals.</p> : ''}
													<p className="hidden">{data.T3New = Number(formData.T3) - Number(data.LNew)} {data.LNew = 0} {data.POD = Number(data.LNew) }</p>


												</div> 

										}
									   
									</div>
								</div>

							</div>
						: (formData.T4 > 0 && (Number(formData.T4) < Number(form1Data.HIRT)) && formData.T3 > 0) ?
							<div className="w-full md:flex-1">
								<h1><b>Low Interest Rate Debt (APR)</b></h1>
								<div className="flex justify-left pt-2 pb-5 ">
									<div className="md:w-full md:px-0 space-y-2">									

										{Number(data.LNew) >= ((Number(form1Data.DTA))/100)*(Number(formData.T1)+Number(formData.T2)) && formData.T3 > 0 && data.LNew > 0 ?                                   
											
												Number(formData.T3) > ((Number(form1Data.DTA))/100)*(Number(formData.T1)+Number(formData.T2)) ?
													<div className="text-left">
														<p>You have less interest rate debt. Consider paying off part of your debt.</p>
														<p>You can pay off {((Number(form1Data.DTA))/100) * (Number(formData.T1) + Number(formData.T2))} of your debt.</p>
														<p className="hidden"> {data.T3New = 0} {data.LNew = Number(data.LNew) - ((Number(form1Data.DTA))/100) * (Number(formData.T1) + Number(formData.T2))} {data.POD = ((Number(form1Data.DTA))/100) * (Number(formData.T1) + Number(formData.T2)) } </p>
													</div>
												: 	<div className="text-left">
														<p >You can pay off your entire Debt of { Number(formData.T3) }.</p>
														<p className="hidden"> {data.T3New = 0} {data.LNew = Number(data.LNew) - Number(formData.T3)} {data.POD = Number(formData.T3) }</p>
													</div>	
											
												

											
											:
											formData.T3 > 0 && data.LNew > 0 ?
												Number(formData.T3) < data.LNew ?
													<div className="text-left">
														<p >You can pay off your entire Debt of { Number(formData.T3) }.</p>
														<p className="hidden"> {data.T3New = 0} {data.LNew = Number(data.LNew) - Number(formData.T3)} {data.POD = Number(formData.T3) }</p>
													</div>	
													:	
													<div className="text-left">
														<p className="text-left">You have less interest rate debt. Consider paying off part of your debt.</p>
														<p> You can pay off a portion {data.LNew} of your Debt. </p>
														{Slack > 0 ? <p className="text-left">You have {Slack} surplus in your budget every month, you can use this to first pay off your remaining debt and then start saving for your financial goals.</p> : ''}
														<p className="hidden">{data.T3New = Number(formData.T3) - Number(data.LNew)} {data.LNew = 0} {data.POD = Number(data.LNew) }</p>
													</div> 
												:
												<div className="text-left">
													{Slack > 0 ? <p className="text-left">You have {Slack} surplus in your budget every month, you can use this to first pay off your remaining debt and then start saving for your financial goals.</p> : ''}
													<p className="hidden">{data.T3New = Number(formData.T3) - Number(data.LNew)} {data.LNew = 0} {data.POD = Number(data.LNew) }</p>


												</div>

										}

									</div>
								</div>

							</div> : ''
					:'')
				}		
				
				 {isNaN(data.LNew) ? '' :
                        data.LNew> 0 && (data.GL1 > 0 || data.GL2 > 0) ?
                            <div className="w-full md:flex-1 mt-2">
								<h1><b>Financial Goal Allotment</b></h1>
                                <div className="flex justify-left mt-2 ">
                                    <div className="md:w-full md:px-0 px-10 ">
                                        <p className="hidden">
                                            {data.LNew= TenureAllotment(data.LNew)}
                                        </p>
                                        <div style={{ whiteSpace: 'pre-line' }}><p className="text-left -mt-2 space-y-24">{goalStats}</p></div>
                                     

                                    </div>
                                </div>

                            </div>
                            :
                            null}
				
				{isNaN(data.LNew) ? '' :
                        data.LNew> 0 ?
                            <div className="w-full md:flex-1 mt-5">
								<h1><b>Remaining Lumpsum Utilization</b></h1>
                                <div className="flex justify-left pt-2 pb-2 ">
                                    <div className="md:w-full md:px-0 px-10 space-y-2">
                                        <p className="text-left">You have savings left after paying off debt, emergency fund and allocating to your goals.</p>
                                        <p className="text-left">
                                            Consider investing the rest of your lumpsum {data.LNew} for your future or buy something nice for yourself or your loved ones.</p>
                                    </div>
                                </div>

                            </div>
                            :
                            null
                    }	
				
				
				</div>


                {data.L > 300 ?

                    <div className="md:flex justify-between md:space-x-24 pt-10">
                        <div className="w-full md:flex-1 p-5  shadow-md   ">
                            <div className="px-0 md:px-10">

                                <Heading text="Financial Measures (Post - Allocation)" classes={"items-center px-10 md:px-10"} />
                            </div>
                            <div className="flex justify-center">


                            </div>
                            <div className="flex justify-center p-5">
                                <div className="hidden">
                                    {resultData.R1a = (Number(formData.M3) / Number(formData.M1)) * 100}
                                    {resultData.R2a = (Number(data.T1New) / (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))) * 100}
                                    {resultData.R3a = ((Number(data.T1New) + Number(formData.T2)) / (Number(data.T3New))) * 100}
                                    {resultData.R4a = ((Number(data.T1New) / (Number(formData.M3) + Number(formData.M4)))) * 100}
                                    {resultData.R5a = (Number(formData.M4) / Number(formData.M1))}
                                    {resultData.S2 = Number(formData.M1) - (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))}
                                </div>
                                <div className=" md:w-1/2 md:px-0 px-10 space-y-7">
                                    <ResultInput label={'Debt / Income Ratio'} value={isNaN(resultData.R1a) ? 0 : resultData.R1a === Infinity ? 'High' : resultData.R1a.toFixed(0) + '%'} tooltip={"How much of your income is spent on Debt Payments? Recommended < 20%"}/>
                                    <ResultInput label={'Expense Coverage Ratio'} value={isNaN(resultData.R2a) ? 0 : resultData.R2a === Infinity ? 'High' : resultData.R2a.toFixed(0) + '%'}  tooltip={"How many times does your savings cover monthly expenses(expenses, debt and rent)? Recommended>300% (3 months)"}/>
                                    <ResultInput label={'Assets / Liabilities'} value={isNaN(resultData.R3a) ? 0 : resultData.R3a === Infinity ? 'High' : resultData.R3a.toFixed(0) + '%'} tooltip={"Ratio of what you have vs what you owe. Recommended>100% (positive net worth)"} />
                                    <ResultInput label={'Assets / (Rent + Debt)'} value={isNaN(resultData.R4a) ? 0 : resultData.R4a === Infinity ? 'High' : resultData.R4a.toFixed(0) + '%'} tooltip={"How many times can your obligations be covered by assets? Recommended > 1200% (one year) "} />
                                    <ResultInput label={'Housing Cost Ratio'} value={isNaN(resultData.R5) ? 0 : resultData.R5 === Infinity ? 'High' : resultData.R5.toFixed(0) + '%'} tooltip={"How much of your income is spent on housing costs? Recommended < 30% "}/>
                                    <ResultInput label={'Slack in Budget'} value={isNaN(resultData.S2) ? 0 : resultData.S2 === Infinity ? 'High' :  resultData.S2.toFixed(0)} isLast  tooltip={"This is the surplus each month which can be used for goal planning"}/>


                                </div>

                            </div>
                        </div>

                        <div className=" w-full w-9/12 md:w-1/2 pt-5 md:block  justify-center px-0 md:px-0 ">
							<ASPieGraph heading="Allocation of Lump Sum Amount" L = {data.L} EFC = {data.EFC} POD = {data.POD} GA = {data.GA}
                            />
                            
                        </div>

                    </div>

                    : ''}



                <div className="pt-10">
                    <BottomNav />
                </div>

            </Container>
			</form>	
        </div>
    );
};

export default AS;