import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  );
  
  export function BarChart1({T1,T2,T3,T4,Net}) {
   const options = {
  responsive: true,
  plugins: {
    legend: {
      display:false
      // position: 'bottom' ,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['Total Savings', 'Investments', 'Total Debt', 'Net Worth']; {/* Removed Mortgage (T4) VN*/ }

 const data = {
  labels,
  datasets: [
    {
      
      // label:`${labels[0]}, ${labels[1]}${Net>0?`, ${labels[4]}`:''}  `,
      // label: ['Total Savings', 'Investments', 'Total Debt', 'Mortgage', 'Net Worth'],
      data: [T1,T2,-T3,Net],
      backgroundColor: ['#219F94','#219F94','#FF0F00', Net>0?'#219F94':'#8B0000'],
    },
    
 
  ],
};

  return <Bar options={options} data={data} />;
}
