import { configureStore,createSlice } from "@reduxjs/toolkit";

const counterSlice=createSlice({
    name:'formdata',
    initialState:{formdata:[],list:['abc','dbe']},
    reducers:{
      updateFormData(state,action)
      {
        state.formdata=action.payload

      }
    }
})
export const actions=counterSlice.actions
const store=configureStore(
    {
        reducer: counterSlice.reducer
    }
)
export default store