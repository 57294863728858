import DescriptionItem from "./DescriptionItem";
import Preferences from "../pages/Preferences";
import React, { useEffect, useState, useRef } from "react";


const Description = ({ R1, R2, R3, R4, R5, R6, R7, Networth, Slack }) => {
    const networthStr = Networth.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    R2 === 0 ? R2 = 0.1 : R2 = R2; // Setting R2 to something other than zero so that the negative condition is satisfied in case no savings are entered. VN
    console.log("R7 "+R7)
const networthNoDecimal = Networth.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

const [form1Data, setForm1Data] = useState({
        EFT: 3,
        DTI: 40,
        DTA: 30,
        DCA: 6,
        HCI: 30,
        HOCR: 20,
        SR: 10,
        DP: 20,
		HOCC: 6,
		HIRT: 5,
		PDPM: 'Lowest Balance first',
		
      
    });
useEffect(() => {
		if (sessionStorage.getItem('form1Data')){
				setForm1Data(JSON.parse(sessionStorage.getItem('form1Data')))
			}	
			
	},[])
	
console.log("R4 "+ (Number(R4)/100).toFixed(0))
console.log("form1Data.DCA "+ form1Data.DCA)
    return (
        <div className="space-y-6">
            <div className="space-y-2">
                {
                    R1 ? <DescriptionItem falseText={"You have too much debt, which is considered risky to lenders."} rightText={"You have manageable amount of debt compared to your income."} condition={R1 <= form1Data.DTI} /> : null
                }	
                {
                    R2 ? <DescriptionItem falseText={`You have less than ${form1Data.EFT} months of savings to cover your expenses and debt obligations.`} rightText={`You have ${form1Data.EFT}+ months of savings to cover expenses and debt payments. Maintain this as your emergency fund.`} condition={((Number(R2)/100).toFixed(0)) > Number(form1Data.EFT)} /> : null
                }
                {
                    R3 ? <DescriptionItem falseText={"You have a negative networth."} rightText={"You have positive networth."} condition={R3 >= form1Data.DTA} /> : null
                }
                {
                    R4 ? <DescriptionItem falseText={"Your savings are not enough to cover essential payments. You need to boost your savings' value."} rightText={`Your assets can be liquidated to cover ${(Number(R4)/100).toFixed(0)} months of payments. 6 months or more is a good sign.  `} 
					condition={((Number(R4)/100).toFixed(0)) > (Number(form1Data.DCA)) } /> : null
                }
                {
                    R5 ? <DescriptionItem falseText={"Your housing cost is high compared to your income."} rightText={`Your housing cost is manageable with your current income.`} condition={R5 <= form1Data.HCI} /> : null
                }
				{
                    R6 ? <DescriptionItem falseText={"You have high debt compared to your assets."} rightText={"You have a manageable debt compared to your assets."} condition={R6 <= 50} /> : null
                }
													
				{
                    isNaN(R7) ? null
                        : (R7 <= Number(form1Data.SR) ? 
                            Slack === 0 ?
                                <div className="flex items-center space-x-2">
                                    <img src={"./assets/icons/cross.svg"} alt="cross" />
                                    <h3 className="text-red-devil font-medium text-sm flex-1">{"You do not have any monthly savings left after expenses, debt and housing payments. This will impair your ability to plan for goals or pay off debt."}</h3>   
                                </div >
                                 
                                : Slack < 0 ?
                                    <div className="flex items-center space-x-2">
                                        <img src={"./assets/icons/cross.svg"} alt= "cross" />
                                        <h3 className="text-red-devil font-medium text-sm flex-1">{`You have a deficit of ${Math.abs(Slack)} in your budget every month. That means you are spending more than you earn each month. This is not sustainable.`}</h3>
                                    </div >
                                    
                                  :null
                            :
                            <DescriptionItem
                            falseText={`You have less than ${Number(form1Data.SR).toFixed(2)}% savings from your income.`}
                            rightText={`You have  good savings ratio of ${R7.toFixed(2)}%.`}                            
                            condition={R7 >= form1Data.SR} />                
						 
                    ) 
                }
            </div>
            <div>
                <h1 className="font-bold text-xl"><span className="text-chinese-black">Your Net Worth is:</span> <span className="text-jungle-green"> {(Networth)}</span></h1> {/* Removed $ sign VN */}
            </div>
        </div >
    );
};

export default Description;;;;
