import React from 'react'
import ResultInput from './ResultInput'
import Heading from './Heading'
import DescriptionItem from "./DescriptionItem";

export default function YourFHC({R1,R2,R3,R4,R5,Slack}) { {/* Adde new parameter Slack to compare. -VN*/}
  return (
    <div className="shadow-md p-6 bg-white space-y-7 md:w-1/2">
    <Heading text={"Your Financial Metrics at a Glance"} icon={"checkup"} />
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">

        <ResultInput name ={"R1"} label={"Debt / Income ratio"} value={isNaN(R1) ? 0 : R1 === Infinity ? 'High' : R1.toFixed(0) + '%'} tooltip={"How much of your income is spent on Debt Payments? Recommended < 20%"} />
        <ResultInput name ={"R2"} label={"Expense Coverage Ratio"} value={isNaN(R2) ? 0 : R2 === Infinity ? 'High' : R2.toFixed(0) + '%'} tooltip={"How many times does your savings cover monthly expenses(expenses, debt and rent)? Recommended>300% (3 months)"} />
        <ResultInput name ={"R3"} label={"Assets / Liabilities"} value={isNaN(R3) ? 0 : R3 === Infinity ? 'High' : R3.toFixed(0) + '%'} tooltip={"Ratio of what you have vs what you owe. Recommended>100% (positive net worth)"} />
        <ResultInput name ={"R4"} label={"Assets / (Rent + Debt)"} value={isNaN(R4) ? 0 : R4 === Infinity ? 'High' : R4.toFixed(0) + '%'} tooltip={"How many times can your obligations be covered by assets? Recommended > 1200% (one year) "} />
        <ResultInput name ={"R5"} label={"Housing Cost Ratio"} value={isNaN(R5) || R5 === Infinity ? 0 : R5.toFixed(0) + '%'} tooltip={"How much of your income is spent on housing costs? Recommended < 30% "} />
        <ResultInput name ={"Slack"} label={"Slack in Budget"} value={isNaN(Slack) || Slack === Infinity || Number(Slack) < 0 ? 0 : '' + Slack.toFixed(0)} isLast  tooltip={"This is the surplus each month which can be used for goal planning"}/>
        {/* .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') */}
    </div>
	{/* Added below section to display message based on Slack value. -VN*/}
	<div className="grid">
			{
                Slack > 0 ? <DescriptionItem 
				falseText=<div style={{whiteSpace: 'pre-line'}}>{`You have a deficit of ${Slack} in your budget every month.\n That means you are spending more than you earn each month. This is not sustainable.`}</div> 
				rightText=<div style={{whiteSpace: 'pre-line'}}>{`You have a surplus of ${Slack} in your budget every month.\n This can be used to pay off debt, boost your savings or achieve more goals.`}</div> 
				condition={Slack > 0} /> : null
            }
	</div>
</div>
  )
}
