import React, { useEffect } from 'react'
import { Link } from "react-router-dom";

export default function LD() {
	useEffect(() => {
		document.title = 'SYMR | Legal Disclaimer';
	  }, []);
    return (
        <div className='m-auto'>
            <div className=' flex  justify-center  '>
                <div className=' flex justify-center   '>
					<Link to={'/'}>
                    <img className='w-[200]' src='./assets/icons/logo.svg' alt='logo' />
					</Link>	
                </div>
            </div>

            <div className='space-y-10'>
                <h1 className='text-primary text-center  font-bold text-xl lg:text-4xl'>Legal Disclaimer</h1>
                <div className='mx-10 md:mx-52 md:my-0 mb-20 py-4 text-justify	'>


                    <p className='mx-10 md:mx-52 md:my-0 mb-20 py-4'>
                        The “Start Your Money Right” website provides software tools for Financial Planning for Young Professionals. These tools are to be used only for a preliminary assessment of financial situation and goals. These are in no way a substitute for real financial planning from a competent authority or financial professional.			
                    </p>
					<p className='mx-10 md:mx-52 md:my-0 mb-20 py-4'>
						Start Your Money Right, as a company does not hold any responsibility for the data entered and its veracity for the user. Also, the planning and recommendations provided are based on generic rules of thumb and may not apply to all personal finance situations. The objective of these tools is to provide basic financial education and minimal planning on the hands of the user, who may not be financially knowledgeable at all. The user will not receive any advice/recommendations related to investment and insurance products, methodology, advisory, asset allocation, asset classes, retirement planning, tax advantaged accounts, markets and any other form of investment and insurance related topics. Similarly no tax or estate related discussion or suggestions were made. 

					</p>
					<p className='mx-10 md:mx-52 md:my-0 mb-20 py-4'>
						The money management principles discussed were limited to Income, Expense and Saving in FDIC insured savings/checking accounts. Hence, the user hereby release, waive, acquit, and forever discharge Start Your Money Right LLC, their founders,agents, successors,assigns, personal representatives, executors, heirs, and employees from every claim, suit, action, demand, or right to compensation for damages that user may claim to have or that may have arising out of actions, omissions, or commissions taken by user or as a result of analysis done by the website. 

					</p>
					<p className='mx-10 md:mx-52 md:my-0 mb-20 py-4'>
						The user further declares and represents that no promise, inducement, or agreement not herein expressed has been made to the user to enter into this release. The release made pursuant to this paragraph shall bind user’s heirs, executors, personal representatives, successors, assigns, and agents.

					</p>
					<p className='mx-10 md:mx-52 md:my-0 mb-20 py-4'>
						No financial data is stored at the backend by this version of the tool.
					</p>
                </div>
            </div>
        </div>
    )
}
