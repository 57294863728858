import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
 
const ResultInput = ({ label, value, name, isLast,tooltip }) => {
    return (
        <Tooltip title={tooltip}>

        <div className="flex flex-col pt- items-center w-full">
            <label className="text-chinese-black/70 font-medium font-medium text-[10px] sm:text-xs md:text-sm">{label} 
				{tooltip? <FontAwesomeIcon className = {"ml-2"} icon={faCircleInfo} size="s" style={{color: "#9c9fa5",}} />:''}
			</label>
            <input className={`py-3 px-4 w-full bg-bright-gray/50 rounded-xl pointer-events-none text-center ${isLast && "bg-primary/10 text-primary"}`} name = {name}readonly value={value} />
        </div>
        </Tooltip>
    );
};

export default ResultInput;