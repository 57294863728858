import React, { useEffect, useState, useRef } from "react";
import BottomNav from "../components/BottomNav";
import Container from "../components/Container";
import Description from "../components/Description";
import FinancialHealthCheckup from "../components/FinancialHealthCheckup";
import Heading from "../components/Heading";
import Input from "../components/Input";
import PieGraph from "../components/PieGraph";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Cell, ResponsiveContainer } from 'recharts';
import ResultInput from "../components/ResultInput";
import Toast from "../components/Toast";
import {useSelector,useDispatch} from 'react-redux'
import  { actions } from '../store/index'
import Tooltip from '@mui/material/Tooltip';
import { Button } from "@mui/material";
import YourFHC from "../components/YourFHC";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faDownload } from '@fortawesome/free-solid-svg-icons'
 import { faYoutube } from '@fortawesome/free-brands-svg-icons'


const KFormater = (num) => Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#0A1827" textAnchor="middle" dy={value < 0 ? 16 : -6} >{`${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</text>;
};

const BH = () => {
	const form = useRef();
	  const [status, setStatus] = useState('null'); /*New change */	 
	  const sendEmail = (e) => {
		e.preventDefault();
		setStatus("sending"); /*New change */
		
		emailjs.sendForm('service_ykjjhjm', 'template_k11stse', form.current, 'wwqQKgCmrGkZJXbt6')
		  .then((result) => {
			  console.log(result.text);
			  setStatus("sent");
		  }, (error) => {
			  console.log(error.text);
			  setStatus("error");
		  });
	  };
    const counter=useSelector((state)=>state.formdata)
    const dispatch=useDispatch()
    const hasCodeExecuted = useRef(false);
    
     const [formData, setFormData] = useState({
        M1: null,
        M2: null,
        M3: null,
        M4: null,
        T1: null,
        T2: null,
        T3: null,
        T4: null,
    
    

    }) // setting form 1 data variables

    const [dataBH,setDataBH]=useState({
        H1: null,
        H2: null,
        H3: null,
        H4: null,
        H5: null,
        H6: null,
        H7: null,
        H8: null,
        H9: 30,
    })

    const [resultData, setResultData] = useState({
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        R5: 0,
		R6: 0, //Added to calculate Debt to Asset Ratio. -VN
		R7: 0, //Added to calculate Savings Ratio. -VN
        HOC: 0,
        BR3: 0,
        BR4: 0,
        R1a: 0,
        R2a: 0,
        R3a: 0,
        R4a: 0,
        R5a: 0,
        R1b: 0,
        R2b: 0,
        R3b: 0,
        R4b: 0,
        R5b: 0,
        SME: 0,
        ES: 0 //Added to store the extra savings. -VN
    }); // setting calculated data variables
	
	const [form1Data, setForm1Data] = useState({
			EFT: 3,
			DTI: 40,
			DTA: 30,
			DCA: 6,
			HCI: 30,
			HOCR: 20,
			SR: 10,
			DP: 20,
			HOCC: 6,
			HIRT: 5,
			PDPM: 'Lowest Balance first',
			
		  
		});
	useEffect(() => {
			if (sessionStorage.getItem('form1Data')){
					setForm1Data(JSON.parse(sessionStorage.getItem('form1Data')))
				}	
				
		},[])

    const [Slack, setSlack] = useState(0);
    const [Slack2, setSlack2] = useState(0);
    const [Networth, setNetworth] = useState(0);

    const changeHandler = (e) => {
        
		let formValues = {...formData, [e.target.id]: e.target.value}
		setFormData(formValues)
		sessionStorage.setItem('formData', JSON.stringify(formValues))
		
		setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
		
    }; // handling input variables and updating their variables
	
    const dataChangeHandler = (e) => {
		let formValues = {...dataBH, [e.target.id]: e.target.value}
		setDataBH(formValues)
		sessionStorage.setItem('dataBH', JSON.stringify(formValues))
        // setData({
            // ...data,
            // [e.target.id]: e.target.value
        // });

     

    };
    
    function PMT(ir, np, pv, fv) {
        /*
        ir - interest rate per month
        np - number of periods (months)
        pv - present value
        fv - future value (residual value)
        */
        const pmt = (ir * (pv * Math.pow((ir + 1), np) + fv)) / ((ir + 1) * (Math.pow((ir + 1), np) - 1));
		console.log("pmt "+pmt)
        return pmt;
    }


    const update=()=>
    {
        Object.keys(counter).length>0?
        setFormData(counter):
        setFormData(formData)
    }

    useEffect(() => {
		document.title = 'SYMR | Buy a Home';				
        if (!hasCodeExecuted.current) {
          
    //   alert('adg')
            update()  
            // Set the flag to indicate that the code has been executed
            hasCodeExecuted.current = true;

            window.scrollTo(0, 0);

          }
       
        const { M1, M2, M3, M4, T1, T2, T3, T4, } = formData; // getting form data variables for calculations
        const {H1, H2, H3, H4, H5, H6, H7, H8, H9}=dataBH
        const val = ((PMT(((Number(H8)) / 100) / 12, Number(H9) * 12, Number(H1), 0)) + ((Number(H4) + Number(H5) + Number(H6)) / 12))
        const ES = Number(T1) - 3 * (Number(M2) + Number(M3) + Number(M4))

        setResultData({
            R1: (Number(M3) / Number(M1)) * 100,
            R2: Number(T1) / (Number(M2) + Number(M3) + Number(M4)) * 100,
            R3: ((Number(T1) + Number(T2)) / (Number(T3)) * 100),	// Fixed to check if T3 is null. If null, the denominator is set to 1. VN
            R4: ((Number(T1)+Number(T2)) / (Number(M3) + Number(M4)) * 100), // Fixed to add assets T2 to the calculation. VN
            R5: (Number(M4) / Number(M1)) * 100,
			R6: (Number(T3)/(Number(T1)+Number(T2)))*100, //New Result variable added to check the debt ratio if high. - VN
			R7: (Number(Slack)/Number(M1))*100,  //New Result variable added to calculate the savings ratio. - VN
            HOC: ((PMT(((Number(H8)) / 100) / 12, Number(H9) * 12, Number(H1), 0)) + ((Number(H4) + Number(H5) + Number(H6)) / 12)),
            BR3: (Number(val) / Number(formData.M4)) * 100, //Chnaged H2 to M4-VN 14jul
            BR4: (Number(val) / (Number(formData.M4)+Number(Slack))) * 100, //New Result variable to display in Affordability Section -VN 12Jul changed H2 to M4.
            R1a: (Number(M3) / Number(M1)) * 100,
            R2a: (Number(T1) / (Number(M2) + Number(M3) + Number(val))) * 100,
            R3a: ((Number(T1) + Number(T2)) / (Number(T3) + Number(H1) - Number(H7))) * 100,
            R4a: ((Number(T1) + Number(T2) )/ (Number(M3) + Number(val))) * 100,
            R5a: (Number(val) / Number(M1)) * 100,
            SME: (Number(form1Data.HOCC) * (Number(val) + Number(M2) + Number(M3))),
            ES: (Number(T1) - 3 * (Number(M2) + Number(M3) + Number(M4))) < 0 ? 0 : Number(T1) - 3 * (Number(M2) + Number(M3) + Number(M4)), //New Result Variable to check the Extra Savings - VN 14 Jul
            R1b: (Number(M3) / Number(M1)) * 100, //Added to include savings in the post buying calculations
            R2b: ((Number(T1)-Number(ES)) / (Number(M2) + Number(M3) + Number(val))) * 100, //Added to include savings in the post buying calculations
            R3b: (((Number(T1) - Number(ES)) + Number(T2)) / (Number(T3) + Number(H1) - Number(H7))) * 100, //Added to include savings in the post buying calculations
            R4b: (((Number(T1) - Number(ES)) + Number(T2)) / (Number(M3) + Number(val))) * 100, //Added to include savings in the post buying calculations
            R5b: (Number(val) / Number(M1)) * 100, //Added to include savings in the post buying calculations
        });//Computing and setting results
		
		console.log("HOC "+((PMT(((Number(H8)) / 100) / 12, Number(H9) * 12, Number(H1), 0)) + ((Number(H4) + Number(H5) + Number(H6)) / 12)))
		
        setCartData([{
            name: 'Total Savings',
            value: Number(T1),
            color: "green"
        },
        {
            name: 'Investments',
            value: Number(T2),
            color: "green"
        },
        {
            name: 'Total Debt',
            value: -Number(T3),
            color: "red"
        },
        {
            name: 'Mortgage',
            value: -Number(T4),
            color: "pink"
        }]);//updating chart values

        let slack = Number(M1) - (Number(M2) + Number(M3) + Number(M4))  //setting Slack 1 value in a variable

        dispatch(actions.updateFormData(formData))

        setSlack(slack); // setting slack 
        const s = (Number(M1).toFixed(0) - Number(M2).toFixed(0) - Number(M3).toFixed(0) - Number(resultData.HOC).toFixed(0))
        setSlack2(Number(M1) - Number(M2) - Number(M3) - Number(resultData.HOC));   // setting Slack 2


        setNetworth((Number(T1) + Number(T2)) - Number(T3));  // setting Networth
		
		// if (1>2){
			// if (localStorage.getItem('data')){
				// setData(JSON.parse(localStorage.getItem('data')))
			// }
		// }

    }, [formData, Slack, Slack2, dataBH]);
	useEffect(() => {
		if (sessionStorage.getItem('dataBH')){
				setDataBH(JSON.parse(sessionStorage.getItem('dataBH')))
			}
		if (sessionStorage.getItem('formData')){
				setFormData(JSON.parse(sessionStorage.getItem('formData')))
			}	
	},[])	

    const [chartData, setCartData] = useState([{
        name: 'Total Savings',
        value: Number(formData.T1),
        color: "green"
    },
    {
        name: 'Investments',
        value: Number(formData.T2),
        color: "green"
    },
    {
        name: 'Total Debt',
        value: -Number(formData.T3),
        color: "red"
    },
    {
        name: 'Mortgage',
        value: -Number(formData.T4),
        color: "pink"
    }]); // declaring chart data
    var NV = Number(resultData.ES) + Number(dataBH.H7);
    var HP2 = (isNaN((form1Data.DP/100) * Number(dataBH.H1)) || (form1Data.DP/100) * Number(dataBH.H1) === Infinity ? 0 : (((form1Data.DP/100) * Number(dataBH.H1)).toFixed(0)));
    function PostBuy(H7) {
        var HP2 = (isNaN((form1Data.DP/100) * Number(dataBH.H1)) || (form1Data.DP/100) * Number(dataBH.H1) === Infinity ? 0 : (((form1Data.DP/100) * Number(dataBH.H1)).toFixed(0)));

        if (Number(H7) >= Number(HP2)) {
            return (
                <div className=" md:w-1/2 md:px-0 px-10 space-y-7">
                    <ResultInput label={'Debt / Income ratio'} value={isNaN(resultData.R1a) ? 0 : resultData.R1a === Infinity ? 'High' : resultData.R1a.toFixed(0) + '%'} tooltip={"How much of your income is spent on Debt Payments? Recommended < 20%"} />
                    <ResultInput label={'Expense Coverage Ratio'} value={isNaN(resultData.R2a) ? 0 : resultData.R2a === Infinity ? 'High' : resultData.R2a.toFixed(0) + '%'} tooltip={"How many times does your savings cover monthly expenses(expenses, debt and rent)? Recommended>300% (3 months)"} />
                    <ResultInput label={'Assets / Liabilities'} value={isNaN(resultData.R3a) ? 0 : resultData.R3a === Infinity ? 'High' : resultData.R3a.toFixed(0) + '%'} tooltip={"Ratio of what you have vs what you owe. Recommended>100% (positive net worth)"} />
                    <ResultInput label={'Assets / (Rent + Debt)'} value={isNaN(resultData.R4a) ? 0 : resultData.R4a === Infinity ? 'High' : resultData.R4a.toFixed(0) + '%'} tooltip={"How many times can your obligations be covered by assets? Recommended > 1200% (one year) "} />
                    <ResultInput label={'Housing Cost Ratio'} value={isNaN(resultData.R5a) ? 0 : resultData.R5a === Infinity ? 'High' : resultData.R5a.toFixed(0) + '%'} tooltip={"How much of your income is spent on housing costs? Recommended < 30% "} />
                    <ResultInput label={'Slack in Budget'} value={isNaN(Slack2) || Number(Slack2) < 0 || Slack2 === Infinity ? 0 : '' + Slack2.toFixed(0)} isLast tooltip={"This is the surplus each month which can be used for goal planning"} />
                </div>
            )
        }
        else if (Number(H7) < Number(HP2)) {
            return (
                <div className=" md:w-1/2 md:px-0 px-10 space-y-7">
                    <ResultInput label={'Debt / Income ratio'} value={isNaN(resultData.R1b) ? 0 : resultData.R1b === Infinity ? 'High' : resultData.R1b.toFixed(0) + '%'} tooltip={"How much of your income is spent on Debt Payments? Recommended < 20%"} />
                    <ResultInput label={'Expense Coverage Ratio'} value={isNaN(resultData.R2b) ? 0 : resultData.R2b === Infinity ? 'High' : resultData.R2b.toFixed(0) + '%'} tooltip={"How many times does your savings cover monthly expenses(expenses, debt and rent)? Recommended>300% (3 months)"} />
                    <ResultInput label={'Assets / Liabilities'} value={isNaN(resultData.R3b) ? 0 : resultData.R3b === Infinity ? 'High' : resultData.R3b.toFixed(0) + '%'} tooltip={"Ratio of what you have vs what you owe. Recommended>100% (positive net worth)"} />
                    <ResultInput label={'Assets / (Rent + Debt)'} value={isNaN(resultData.R4b) ? 0 : resultData.R4b === Infinity ? 'High' : resultData.R4b.toFixed(0) + '%'} tooltip={"How many times can your obligations be covered by assets? Recommended > 1200% (one year) "} />
                    <ResultInput label={'Housing Cost Ratio'} value={isNaN(resultData.R5b) ? 0 : resultData.R5b === Infinity ? 'High' : resultData.R5b.toFixed(0) + '%'} tooltip={"How much of your income is spent on housing costs? Recommended < 30% "} />
                    <ResultInput label={'Slack in Budget'} value={isNaN(Slack2) || Number(Slack2) < 0 || Slack2 === Infinity ? 0 : '' + Slack2.toFixed(0)} isLast tooltip={"This is the surplus each month which can be used for goal planning"} />
                </div>
            )
        }

    }
  

  
    return (
        <div className="w-full">
			<form ref={form} onSubmit={sendEmail}>
            <div className="bg-gradient-to-b h-72 from-primary to-patric-blue">
                <Container classes={"flex flex-col p-4 px-5 space-y-6 md:space-y-0  md:py-0 md:flex-row items-center w-full justify-between"}>
                    <div className=" -ml-6 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-6 h-auto " >
							<div>
								<Link to={'/'}>
								<img src="./assets/images/LOGO_White.png" alt="logo" className = "object-scale-down w-[160px] h-[160px]" />
								</Link>								
							</div>
						</div>
					</div>	
					<div className=" -ml-48 ">
						<div className="w-auto py-1 mt-[5%]  text-white text-l bg-[#646cff] rounded-lg px-6 mt-12 h-auto " >
							<a href="https://www.loom.com/share/f78b5644f8944d70883a028e3baa87a8?sid=c9ab3366-b5af-49e6-9135-06d65b340d2b" download="SYMR-Glossary" target='_blank'>
							 <center><FontAwesomeIcon icon={faYoutube} style={{color: "white", fontSize: '18px',  marginTop: '5px' }} /> How-To  </center> 
							</a>
						</div>
						<div className="w-auto py-1 mt-[5%] text-white text-l bg-[#646cff] h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<a href="https://drive.google.com/file/d/16K6MSx9zpJxhAGi5ohJvTqZO_qiLoZww/view" download="SYMR-Glossary" target='_blank'>
							 <center> <FontAwesomeIcon icon={faDownload} style={{color: "white", fontSize: '20px'}} />  Glossary  </center> 
							</a>
						</div>
					</div>	
					<div className=" -ml-48 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-12 h-auto " >
							<h1 className="text-white font-bold text-center text-2xl md:text-4xl">Buy a Home</h1>
						</div>
						<div className="w-auto py-1 mt-[6%] text-white text-l h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<div className="text-white  text-center mb:mx-0 mx-5 md:ml-30 -mt-3 md:-mt-7 text-lg md:text-2xl">A home is an investment only if you buy it financially right</div> 
						</div>
					</div>	
                    <div className="-ml-48 md:mr-24 w-full md:w-auto cursor-pointer">
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-3 mt-10 h-auto cursor-pointer " >
							<input type="submit" className = "cursor-pointer" value="Send my Inputs" />
						</div>	
						{status === "sending" && (
							<div style={{ color: "white" }}>Sending...</div>
							)
						}
						{status === "sent" && (
							<div style={{ color: "green" }}>Data sent!</div>
						)
						}
						{status === "error" && (
							<div style={{ color: "red" }}>Failed to send data.</div>
						)
						
						}	
                    </div>
                </Container>
            </div>
            <Container classes={"-mt-24 md:-mt-32 py-10 space-y-6"}>
                <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                        <div className="shadow-md p-6 bg-white space-y-7 md:w-1/2">
                            <Heading text={"Basic Financial Inputs "} icon={"financial-input"} />
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
                                <Input type={"number"} id={"M1"} value={formData.M1} label={"Monthly Income"} tooltip={"Enter your total monthly income from all sources: salary, business income, rental income etc."} name={"M1"} min={0} onChange={changeHandler} required />
                                <Input type={"number"} id={"T1"} value={formData.T1} label={"Total Savings (Liquid Funds)"} tooltip={"Refers to readily accessible funds held in cash or highly liquid assets, such as savings accounts or money market accounts, that can be quickly and easily utilized for immediate financial needs or emergencies."} min={0} name={"T1"} onChange={changeHandler} />
                                <Input type={"number"} id={"M2"} value={formData.M2} label={"Monthly Expenses"} tooltip={"Your total monthly expenses for everything except loans and debt payments and rent. It should include everything else such as food, utilities, shopping, healthcare, transportation, education etc."} name={"M2"} min={0} onChange={changeHandler} required/>
                                <Input type={"number"} id={"T2"} value={formData.T2} label={"Total Investments (Non-Cash)"} name={"T2"} tooltip={"Total amount invested in stocks, bonds, 401K, IRA, real estate, or other financial instruments."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/}
                                <Input type={"number"} id={"M3"} value={formData.M3} label={"Monthly Debt Payments"} name={"M3"} tooltip={"Monthly payments you make for all loans such as credit card, car payments, etc."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/ }
                                <Input type={"number"} id={"T3"} value={formData.T3} label={"Total Debt (Except Mortgage)"} name={"T3"} tooltip={"Total amount of debt you need to repay. This includes credit card debt, car loan etc."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M4"} value={formData.M4} label={"Housing/Rent, Tax, Insurance"} name={"M4"} tooltip={"This includes your monthly rent or mortgage payment + property taxes + home insurance + home warranty etc. Include this for all your properties."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"T4"} value={formData.T4} label={"Max Debt Interest Rate (%)"}  name={"T4"} tooltip={"This is the highest interest rate on any of your loans or debts other than mortgage."} min={0} onChange={changeHandler} />
                            </div>


                        </div>

              <YourFHC R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} Slack={Slack}/>
                    </div>
					<Input type={"hidden"} id = {"page_name"} value = {"Buy a Home"} name={"page_name"} onChange={changeHandler}/>
                </div>



                <div className="flex-1 w-full md:flex-none space-y-6">
                    <div className="shadow-md p-6 bg-white space-y-12">
                        <div className="hidden md:block">

                            <Heading text={"Housing Inputs"} icon={"financial-input"} />
                        </div>
                        <div className="block md:hidden md:px-0 md:text-center">

                            <Heading text={"Housing Inputs"} classes={' '} />
                        </div>


                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 gap-4 md:gap-y-8 lg:gap-x-28 md:ml-7  md:px-0">

                            <Input type={"number"} id={"H1"} value={dataBH.H1} name={"H1"} label={"Home Price"} tooltip={"The total price of the home you are looking to buy."} onChange={dataChangeHandler} />
                            <Input type={"number"} id={"H4"} value={dataBH.H4} name={"H4"} label={"Property Taxes (Yearly)"} tooltip={"Estimated value of property taxes for this house you are planning to buy."} onChange={dataChangeHandler} />
                            <Input type={"number"} id={"H7"} value={dataBH.H7} name={"H7"} label={"Down Payment (Separate from Savings)"} tooltip={"The amount you need to pay upfront when you buy this house. This is usually a cash payment."} onChange={dataChangeHandler} /> {/* VN 12Jul*/}
                            <Input type={"number"} id={"H2"} value={formData.M4} name={"H2"} label={"Current Rent"} tooltip={"Monthly rent you are paying right now (if any)."} disable onChange={dataChangeHandler} />
                            <Input type={"number"} id={"H5"} value={dataBH.H5} name={"H5"} label={"Property Insurance (Yearly)"} tooltip={"Insurance for the home you are planning to buy."} onChange={dataChangeHandler} />
                            <Input type={"number"} id={"H8"} value={dataBH.H8} name={"H8"} label={"Interest Rate (%)"} tooltip={"Mortgage APR."} onChange={dataChangeHandler} /> {/* VN 12Jul*/}
                            <Input type={"number"} id={"H3"} value={dataBH.H3} name={"H3"} label={"# of years planning to stay"} disable onChange={dataChangeHandler} />
                            <Input type={"number"} id={"H6"} value={dataBH.H6} name={"H6"} label={"Property Maintenance (yearly)"} tooltip={"Approximate yearly maintenance expenses for this house."} onChange={dataChangeHandler} />
                            <Input type={"number"} id={"H9"} value={dataBH.H9} name={"H9"} label={"Tenure (years)"} tooltip={"How many year loan are you planning to take? It is usually 30-year or 15-year or could be 1 or 5-year also in some cases."} onChange={dataChangeHandler} />
                        </div>


                    </div>
                </div>

                <div className="block md:flex pt-10 px-5">

                    <div className="hidden md:block w-7/12">
                        <PieGraph heading="Monthly Expenses vs Savings" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
                    </div>
                    <div className="md:hidden">
                        <PieGraph heading="Monthly Expenses vs Savings" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
                    </div>
                    <div className="md:pl-14 mt-10 md:mt-24">
                        <Description R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} R6={resultData.R6} R7={resultData.R7} Networth={Networth} Slack={Slack} /> 
                    </div>




                </div>



                <div className="space-y-20">

                    <div className="flex justify-between mt-5 flex-wrap md:items-stretch mx-0 md:mx-0 md:space-y-0 space-y-10 md:space-x-10">


                        {/* <ValueContainer fields={[{ label: 'Home Ownership Cost (HOC)', value: '$' + 0 }, { label: 'Current Rent', value: '$' + data.H2 }, { label: 'HOC vs Rent', value: '$' + 0 }]} /> */}

                        <div className="w-full md:flex-1  shadow-md  p-8 ">
                            <div className="px-0 md:px-20">

                                <Heading text="Buy vs Rent" classes={"text-center"} />
                            </div>
                            <div className="flex justify-center">


                            </div>
                            <div className="flex justify-center pt-5 pb-5 ">

                                <div className="md:w-1/2 md:px-0 px-10 space-y-7">
                                    <ResultInput label={'Home Ownership Cost (HOC)'} tooltip={"HOC includes the total expenses associated with owning a property, including mortgage payments, property taxes, insurance, and maintenance, which contribute to the overall financial responsibility of owning a home."} value={isNaN(resultData.HOC) ? 0 : resultData.HOC === Infinity ? 'High' :  resultData.HOC.toFixed(0)} />
                                    <ResultInput label={'Current Rent'} value={isNaN(formData.M4) ? 0 : formData.M4 === Infinity ? 'High' : (Number(formData.M4)).toFixed(0)} />
                                    <ResultInput label={'HOC vs Rent'} value={isNaN(resultData.BR3) ? 0 : resultData.BR3 === Infinity ? 'High' : resultData.BR3.toFixed(2) + '%'} />

                                </div>


                            </div>
                            <div className="mt-4">
                                {isNaN(resultData.BR3) ? '' :
                                    ((Number(resultData.BR3)) > ((Number(form1Data.HOCR))+100)) ? <Toast color={"bg-red-devil"} description={"Your Homeownership cost will increase in your budget."} textcolor={"text-red-devil"} /> 
                                        : <Toast color={"bg-jungle-green"} description={"Your HOC is the same or less than your current housing costs. You should see no to minimal impact on your budget due to this home purchase."} textcolor={"text-jungle-green"} />}
                            </div>
                        </div>
                        <div className="w-full md:flex-1  shadow-md  p-8 ">
                            <div className="md:px-20 mt-20 md:mt-0">

                                <Heading text="Affordability Check" classes={"text-center"} />
                            </div>
                            <div className="flex justify-center">


                            </div>
                            <div className="flex justify-center pt-5">

                                <div className="md:w-1/2 md:px-0 px-10 space-y-7">
                                    <ResultInput label={'Home Ownership Cost (HOC)'} value={isNaN(resultData.HOC) ? 0 : resultData.HOC === Infinity ? 'High' :  resultData.HOC.toFixed(0)} />
                                    <ResultInput label={'Rent + Slack'} value={isNaN((formData.M4 + Slack)) ? 0 : (formData.M4 + Slack) === Infinity ? 'High' : (Number(formData.M4) + Number(Slack)).toFixed(0)} />
									<ResultInput label={'HOC vs (Rent+Slack)'} value={isNaN(resultData.BR4) ? 0 : resultData.BR4 === Infinity ? 'High' : resultData.BR4.toFixed(2) + '%'} />

                                </div>

                            </div>
                            <div className="mt-8">
                                {isNaN(resultData.HOC) ? '' :
                                    resultData.HOC > Number(formData.M4) + Number(Slack) ? <Toast color={"bg-red-devil"} description={"You may not be able to afford this home without reducing your expenses or other debts"} textcolor={"text-red-devil"} />
                                        : <Toast color={"bg-jungle-green"} description={"You can comfortably afford this home with the current rent and slack in your budget."} textcolor={"text-jungle-green"} />}
                            </div>
                        </div>
                    </div>
                    <div className="h-1">

                    </div>

                    <div className="flex justify-between mt-5 flex-wrap md:items-stretch mx-0 md:mx-0 md:space-y-0 space-y-10 md:space-x-10">


                        <div className="w-full md:flex-1  shadow-md  p-8 ">
                            <div className="px-0">

                                <Heading text={`Down Payment Recommendation`} classes={"px-5 md:px-5"} /> {/* Changed Heading VN 12Jul */}
                            </div>
                            <div className="flex justify-center">

                            </div>
                            <div className="flex justify-center pt-5 ">

                                <div className="md:w-1/2 md:px-0 px-10 space-y-7">
                                    <ResultInput label={'Extra Savings'} value={isNaN(resultData.ES) || resultData.ES === Infinity ? 0 : (Number(resultData.ES)).toFixed(0)} />{/* Changed Name VN 12Jul */}
                                    <ResultInput label={'Down Payment'} value={isNaN(dataBH.H7) || dataBH.H7 === Infinity ? 0 :  Number(dataBH.H7).toFixed(0)} />
                                    <ResultInput label={`${form1Data.DP}% Of Home Price`} value={isNaN((form1Data.DP/100) * Number(dataBH.H1)) || (form1Data.DP/100) * Number(dataBH.H1) === Infinity ? 0 :  (((form1Data.DP/100) * Number(dataBH.H1)).toFixed(0))} />

                                </div>

                            </div>
                            
                            
                            {/* Modified below section to diaply recommendations based on Downpayment VN*/ }
                            <div className="mt-8">                                
                                {   
                                    (Number(resultData.ES).toFixed(0) <= 0) ?
                                        (Number(dataBH.H7) >= Number(HP2)) ?
                                            <Toast color={"bg-jungle-green"} description={`You can make a downpayment of ${Number(dataBH.H7).toFixed(0)}. But you do not have any extra savings. Utilize your slack to build your emergency fund.`} textcolor={"text-jungle-green"} />
                                            :<Toast color={"bg-jungle-green"} description={`You can make a downpayment of ${Number(dataBH.H7).toFixed(0)} but it may increase your HOC since it is less than ${form1Data.DP}% of Home Price.`} textcolor={"text-jungle-green"} />
                                        :(isNaN(dataBH.H7) || dataBH.H7 === Infinity ? 0 : Number(dataBH.H7).toFixed(0)) ?                                    
                                            (Number(dataBH.H7) < Number(HP2)) ?
                                                (Number(resultData.ES) + Number(dataBH.H7)) > Number(HP2) ?
                                                    <Toast color={"bg-jungle-green"} description={`You can use your extra savings ${Number(resultData.ES).toFixed(0)} to increase your down payment and decrease HOC.`} textcolor={"text-jungle-green"} />
                                                    : <Toast color={"bg-jungle-green"} description={`You can add the extra savings of ${Number(resultData.ES).toFixed(0)} to your downpayment (${Number(dataBH.H7).toFixed(0)}) but it may still increase your HOC since it is less than ${form1Data.DP}% of Home Price.`} textcolor={"text-jungle-green"} />
                                            : (Number(dataBH.H7) >= Number(HP2)) ?
                                                <Toast color={"bg-jungle-green"} description={`You have enough savings for making a ${form1Data.DP}% downpayment. Keep the extra for emergencies.`} textcolor={"text-jungle-green"} />
                                                :null
                                            : null                            
                                }
                            </div>
                            
                            {/* <div className="mt-8">
                                {isNaN(resultData.R2a) ? '' :
                                    resultData.R2a < 3 ? <Toast color={"bg-red-devil"} description={"You have insufficient savings to cover 3 months of expenses and debt payments. Buying this home can put a strain in case of an emergency."} textcolor={"text-red-devil"} />
                                        : data.H7 < 0.2 * data.H1 ?
                                            <Toast color={"bg-jungle-green"} description={`You can buy the home with ${data.H7} down payment but it may increase HOC due to PMI. Please check with your lender about various low-down payment options`} textcolor={"text-jungle-green"} />
                                            : <Toast color={"bg-jungle-green"} description={`You have emergency fund savings to cover ${((Number(resultData.R2a))/100).toFixed(0)} months of expenses and debt payments. Do not use this for your down payment.`} textcolor={"text-jungle-green"} />
                                }
                            </div> */}
                        </div>
                        <div className="w-full md:flex-1  shadow-md  p-8  ">
                            <div className="px-0  mt-20 md:mt-0  " >

                                <Heading text="Do you have enough savings to make payments?" classes={"px-5 md:px-5 "} />
                            </div>
                            <div className="flex justify-center">


                            </div>
                            <div className="flex justify-center pt-5">

                                <div className="md:w-1/2 md:px-0 px-10 space-y-7">
                                    <ResultInput label={'Total Savings'} value={isNaN(formData.T1) || formData.T1 === Infinity ? 0 :  (Number(formData.T1)).toFixed(0)} />
                                    <ResultInput label={'Six months of Expenses + HOC'} value={isNaN(resultData.SME) || resultData.SME === Infinity ? 0 :  Number(resultData.SME).toFixed(0)} />
										{/* <ResultInput label={'Shortfall/Surplus'} value={isNaN(resultData.SME) || resultData.SME === Infinity ? 0 :  Number(resultData.SME).toFixed(0)} /> */}
                                    <ResultInput label={'Shortfall/Surplus'} value={isNaN(resultData.SME) || resultData.SME === Infinity ? 0 : isNaN(formData.T1) || formData.T1 === Infinity ? 0 : (Number(formData.T1) - Number(resultData.SME)).toFixed(0)} /> { /* Added this field to display the surplus */}

                                </div>

                            </div>
                            <div className="mt-8 ">
                                {isNaN(resultData.SME) || resultData.SME === 0 ? '' :
                                    formData.T1 < resultData.SME ? <Toast color={"bg-red-devil"} description={`You have less than ${form1Data.HOCC} months of savings to cover all your expenses after buying the home. Increasing these savings to at least ${form1Data.HOCC} months of reserves to cover home costs, expenses, and other debt payments is prudent financial planning. `} textcolor={"text-red-devil"} />
                                        : <Toast color={"bg-jungle-green"} description={`Maintain the ${formData.T1} savings to at least ${form1Data.HOCC} months of expenses including the monthly cost to afford this home.`} textcolor={"text-jungle-green"} />}
                            </div>
                        </div>
                    </div>
                    <div className="h-1">

                    </div>
                    <div className=" md:flex justify-between md:space-x-24">
                        <div className="w-full md:flex-1 p-5  shadow-md   ">
                            <div className="px-0">

                                <Heading text="Key Financial Measures (Post-Buying)" classes={"items-center px-10 md:px-4"} />
                            </div>
                            <div className="flex justify-center">


                            </div>
                            
                            <div className="flex justify-center p-5">
                                {PostBuy(dataBH.H7)}
                                

                            </div>
                        </div>

                        <div className="  w-full  md:w-1/2 pt-5 md:block  justify-center px-0 md:px-0 ">
                            <PieGraph heading="Monthly Expenses vs Savings (Post-Buying)"  M2={formData.M2} M3={formData.M3} HOC={resultData.HOC.toFixed(0)} slack={Slack2.toFixed(0)} />
                        </div>

                    </div>
                    <BottomNav />
                </div>
            </Container>
        </form>
		</div>
    );
};

export default BH;
