import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

const Input = ({ label, id, type, name, value, onChange, onKeyDown, onPaste, isLast,disable, required, tooltip, pattern, min}) => {
	
	
    return (
	
		<div className="relative h-[72px]">	
        
		<Tooltip title={tooltip}>
            <label className="peer-focus:text-primary text-chinese-black/70  xl:ml-1 font-medium text-[10px] sm:text-sm md:text-sm">{label} 
				{tooltip? <FontAwesomeIcon className = {"ml-2"} icon={faCircleInfo} size="s" style={{color: "#9c9fa5",}} />:''}
			</label>
        </Tooltip>    
		    {disable?
            <input disabled className={`w-full bg-bright-gray/50 rounded-xl py-3 px-4 focus:outline-primary outline-primary ${isLast && "bg-primary/10 text-primary"} `} id={id} type={type} value={value} 	/>
         :  <input  className={`w-full bg-bright-gray/50 rounded-xl py-3 px-4 focus:outline-primary outline-primary ${isLast && "bg-primary/10 text-primary"} `}  id={id} type={type} value={value} onChange={onChange} onKeyDown ={onKeyDown} onPaste ={onPaste} name = {name} required= {required} tooltip = {tooltip} pattern = {pattern} min = {min}/>
        }
        </div>
	
		
		
		
        
    );
};

export default Input;