import React, { useEffect, useState ,useRef} from "react";
import BottomNav from "../components/BottomNav";
import Container from "../components/Container";
import Description from "../components/Description";
import FinancialHealthCheckup from "../components/FinancialHealthCheckup";
import Heading from "../components/Heading";
import Input from "../components/Input";
import PieGraph2 from "../components/PieGraph2";
import PieGraph from "../components/PieGraph";
import ResultInput from "../components/ResultInput";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Cell, ResponsiveContainer } from 'recharts';
import PieGraph4 from "../components/PieGraph4";
import DescriptionItem from "../components/DescriptionItem";
import {useSelector,useDispatch} from 'react-redux'
import  { actions } from '../store/index'
import YourFHC from "../components/YourFHC";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faDownload } from '@fortawesome/free-solid-svg-icons'
 import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import emailjs from '@emailjs/browser'; 

const KFormater = (num) => Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#0A1827" textAnchor="middle" dy={value < 0 ? 16 : -6} >{`${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</text>;
};

const VP = () => {
	const form = useRef();
	  const [status, setStatus] = useState('null'); /*New change */	 

	  const sendEmail = (e) => {
		e.preventDefault();
		setStatus("sending"); /*New change */
		
		emailjs.sendForm('service_ykjjhjm', 'template_k11stse', form.current, 'wwqQKgCmrGkZJXbt6')
		  .then((result) => {
			  console.log(result.text);
			  setStatus("sent");
		  }, (error) => {
			  console.log(error.text);
			  setStatus("error");
		  });
	  };
	  
    const counter=useSelector((state)=>state.formdata)
    const dispatch=useDispatch()
    const hasCodeExecuted = useRef(false);
    const [selects,setselects]=useState();
    

    const [formData, setFormData] = useState({
        M1: null,
        M2: null,
        M3: null,
        M4: null,
        T1: null,
        T2: null,
        T3: null,
        T4: null,
  

     
    
    });

    const [data,setData]=useState({
        F1: null,
        F2: null,
        F3: null,
        F4: null,
        VS1: null,
        VS2: null,
        VS3: null,
        VS4: null,
        VS5: null,
        VS6: null
    })
    
    const [resultData, setResultData] = useState({
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        R5: 0,
		R6: 0, //Added to calculate Debt to Asset Ratio. -VN
		R7: 0, //Added to calculate Savings Ratio. -VN
        TE: 0,
        TFC: 0,
        AVS: 0,
        TVC: 0,
        Surplus: 0,
        S:0
    });

	const [form1Data, setForm1Data] = useState({
			EFT: 3,
			DTI: 40,
			DTA: 30,
			DCA: 6,
			HCI: 30,
			HOCR: 20,
			SR: 10,
			DP: 20,
			HOCC: 6,
			HIRT: 5,
			PDPM: 'Lowest Balance first',
			
		  
		});
	useEffect(() => {
			if (sessionStorage.getItem('form1Data')){
					setForm1Data(JSON.parse(sessionStorage.getItem('form1Data')))
				}	
				
		},[])
		
    const [Slack, setSlack] = useState(0);
    const [Slack2, setSlack2] = useState(0);
    const [Networth, setNetworth] = useState(0);
	const [VPcheck, setVPCheck] = useState(false)

    const changeHandler = (e) => {
        let formValues = {...formData, [e.target.id]: e.target.value}
		setFormData(formValues)
		sessionStorage.setItem('formData', JSON.stringify(formValues))
		
		setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const dataChangeHandler = (e) => {
		let formValues = {...data, [e.target.id]: e.target.value}
		setData(formValues)
		sessionStorage.setItem('data', JSON.stringify(formValues))
		
        setData({
            ...data,
            [e.target.id]: e.target.value
        });

     

    };

    const update=()=>
    {
        Object.keys(counter).length>0?
        setFormData(counter):
        setFormData(formData)
    }

    useEffect(() => {
		document.title = 'SYMR | Create a Budget';
        if (!hasCodeExecuted.current) {

            //update()
            hasCodeExecuted.current = true;

            window.scrollTo(0, 0);

        }

        const { M1, M2, M3, M4, T1, T2, T3, T4} = formData;
        const {F1,F2,F3,F4, VS1,VS2,VS3,VS4,VS5,VS6 }=data;
        setResultData({
            R1: (Number(M3) / Number(M1))*100 ,
            R2: Number(T1) / (Number(M2) + Number(M3) + Number(M4))*100,
            R3: ((Number(T1) + Number(T2)) /  (Number(T3)) * 100),	// Fixed to check if T3 is null. If null, the denominator is set to 1. VN
            R4: ((Number(T1)+Number(T2)) / (Number(M3) + Number(M4)) * 100), // Fixed to add assets T2 to the calculation. VN
            R5: (Number(M4) / Number(M1) )*100,
			R6: (Number(T3)/(Number(T1)+Number(T2)))*100, //New Result variable added to check the debt ratio if high. - VN
			R7: (Number(Slack)/Number(M1))*100,  //New Result variable added to calculate the savings ratio. - VN
            TFC: Number(F1)+Number(F2)+Number(F3)+Number(F4),
            TVC: (isNaN(Number(VS1))?0:Number(data.VS1)) + (isNaN(Number(data.VS2))?0:Number(data.VS2)) + (isNaN(Number(data.VS3))?0:Number(data.VS3)) + (isNaN(Number(data.VS4))?0:Number(data.VS4)) + (isNaN(Number(data.VS5))?0:Number(data.VS5)) + (isNaN(Number(data.VS6))?0:Number(data.VS6)),
            AVS: Number(M1)-Number(M3)-Number(M4)-(isNaN(Number(F1))?0:Number(F1)) - (isNaN(Number(F2))?0:Number(F2)) - (isNaN(Number(F3))?0:Number(F3)) - (isNaN(Number(F4))?0:Number(F4)),
            Surplus: Number(resultData.AVS)-Number(VS1)-Number(VS2)-Number(VS3)-Number(VS4)-Number(VS5)-Number(VS6)
            
        });
        setCartData([{
            name: 'Total Savings',
            value: Number(T1),
            color: "green"
        },
        {
            name: 'Investments',
            value: Number(T2),
            color: "green"
        },
        {
            name: 'Total Debt',
            value: -Number(T3),
            color: "red"
        },
        {
            name: 'Mortgage',
            value: -Number(T4),
            color: "pink"
        }]);
    dispatch(actions.updateFormData(formData))
	
		

        checkAmount()
        
		//alert("TVC " + ((isNaN(Number(VS1))?0:Number(data.VS1)) + (isNaN(Number(VS2))?0:Number(data.VS2) )))

        setSlack(Number(M1) - (Number(M2) + Number(M3) + Number(M4)));
        //alert(resultData.TVC)
        setSlack2(Number(M1)-Number(M3)-Number(M4)-Number(resultData.TFC)-Number(resultData.TVC))
        setNetworth((Number(T1) + Number(T2)) - Number(T3));
		
		
		
    }, [formData, Slack,Slack2,data]);
	
	useEffect(() => {
		if (sessionStorage.getItem('data')){
				setData(JSON.parse(sessionStorage.getItem('data')))
			}
		if (sessionStorage.getItem('formData')){
				setFormData(JSON.parse(sessionStorage.getItem('formData')))
			}	
	},[]);	
	
	function checkAmount() {
            if (resultData.AVS && resultData.TVC) {
                let TVC = (isNaN(Number(data.VS1))?0:Number(data.VS1)) + (isNaN(Number(data.VS2))?0:Number(data.VS2)) + (isNaN(Number(data.VS3))?0:Number(data.VS3)) + (isNaN(Number(data.VS4))?0:Number(data.VS4)) + (isNaN(Number(data.VS5))?0:Number(data.VS5)) + (isNaN(Number(data.VS6))?0:Number(data.VS6))
                let AVS = (Number(formData.M1) - (Number(formData.M3) + Number(formData.M4))) - (isNaN(Number(data.F2))?0:Number(data.F2)) - (isNaN(Number(data.F3))?0:Number(data.F3)) - (isNaN(Number(data.F4))?0:Number(data.F4))
				console.log("(Number(TVC)).toFixed(0) "+(Number(TVC)).toFixed(0))
				console.log("(Number(AVS)).toFixed(0) "+resultData.AVS)
                if (((Number(TVC)).toFixed(0)) > resultData.AVS) {
					console.log("Here?")
                    setVPCheck(true)
                }
                else if (((Number(TVC)).toFixed(0)) <= resultData.AVS) {
					console.log("Here 22?")
                    setVPCheck(false)
                }
            }
            else {
                setVPCheck(false)
            }
			console.log("VPCheck "+ VPcheck)
        }
	 
	
    const [chartData, setCartData] = useState([{
        name: 'Total Savings',
        value: Number(formData.T1),
        color: "green"
    },
    {
        name: 'Investments',
        value: Number(formData.T2),
        color: "green"
    },
    {
        name: 'Total Debt',
        value: -Number(formData.T3),
        color: "red"
    },
    {
        name: 'Mortgage',
        value: -Number(formData.T4),
        color: "pink"
    }]);

    
    return (
        <div className="w-full ">
			<form ref={form} onSubmit={sendEmail}>
            <div className="bg-gradient-to-b h-72 from-primary to-patric-blue">
                <Container classes={"flex flex-col p-4 px-5 space-y-6 md:space-y-0  md:py-0 md:flex-row items-center w-full justify-between"}>
                    <div >
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-6 h-auto " >
							<div>
								<Link to={'/'}>
								<img src="./assets/images/LOGO_White.png" alt="logo" className = "object-scale-down w-[160px] h-[160px]" />
								</Link>								
							</div>
						</div>
					</div>	
					
					<div className=" -ml-6 " >
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-6 mt-10 h-auto " >
							<a href="https://www.loom.com/share/c4f7e6928750423f88083595342adef1?sid=28dfd8e3-d74a-48bf-8f3d-149bacbf5185" download="SYMR-Glossary" target='_blank'>
							 <center><FontAwesomeIcon icon={faYoutube} style={{color: "white", fontSize: '18px',  marginTop: '5px' }} /> How-To  </center> 
							</a>
						</div>
						<div className="w-auto py-1 mt-[5%] text-white text-l bg-[#646cff] h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<a href="https://drive.google.com/file/d/16K6MSx9zpJxhAGi5ohJvTqZO_qiLoZww/view" download="SYMR-Glossary" target='_blank'>
							 <center> <FontAwesomeIcon icon={faDownload} style={{color: "white", fontSize: '20px'}} />  Glossary  </center> 
							</a>
						</div>
					</div>		
					<div className=" -ml-12 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-12 h-auto " >
							<h1 className="text-white font-bold text-center text-2xl md:text-4xl">Create a Budget</h1>
						</div>
						<div className="w-auto py-1 mt-[6%] text-white text-l h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<div className="text-white  text-center mb:mx-0 mx-5 md:ml-30 -mt-3 md:-mt-7 text-lg md:text-2xl">Love life, not a budget. Prioritize your values in your spending plan</div> 
						</div>
					</div>		
                   <div className="-ml-12 w-full md:w-auto cursor-pointer">
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-3 mt-10 h-auto cursor-pointer " >
							<input type="submit" className = "cursor-pointer" value="Send my Inputs" />
						</div>	
						{status === "sending" && (
							<div style={{ color: "white" }}>Sending...</div>
							)
						}
						{status === "sent" && (
							<div style={{ color: "green" }}>Data sent!</div>
						)
						}
						{status === "error" && (
							<div style={{ color: "red" }}>Failed to send data.</div>
						)
						
						}	
                    </div>
					
                </Container>
                
            </div>
            <Container classes={"-mt-24 md:-mt-32 py-10 space-y-6"}>
            <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                        <div className="shadow-md p-6 bg-white space-y-7 md:w-6/12">
                            <Heading text={"Basic Financial Inputs "} icon={"financial-input"} />
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
                                <Input type={"number"} id={"M1"} value={formData.M1} label={"Monthly Income"} tooltip={"Enter your total monthly income from all sources: salary, business income, rental income etc."} name={"M1"} min={0} onChange={changeHandler} required />
                                <Input type={"number"} id={"T1"} value={formData.T1} label={"Total Savings (Liquid Funds)"} tooltip={"Refers to readily accessible funds held in cash or highly liquid assets, such as savings accounts or money market accounts, that can be quickly and easily utilized for immediate financial needs or emergencies."} name={"T1"} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M2"} value={formData.M2} label={"Monthly Expenses"} tooltip={"Your total monthly expenses for everything except loans and debt payments and rent. It should include everything else such as food, utilities, shopping, healthcare, transportation, education etc."} name={"M2"} min={0} onChange={changeHandler} required/>
                                <Input type={"number"} id={"T2"} value={formData.T2} label={"Total Investments (Non-Cash)"} name={"T2"} tooltip={"Total amount invested in stocks, bonds, 401K, IRA, real estate, or other financial instruments."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/}
                                <Input type={"number"} id={"M3"} value={formData.M3} label={"Monthly Debt Payments"} name={"M3"} tooltip={"Monthly payments you make for all loans such as credit card, car payments, etc."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/ }
                                <Input type={"number"} id={"T3"} value={formData.T3} label={"Total Debt (Except Mortgage)"} name={"T3"} tooltip={"Total amount of debt you need to repay. This includes credit card debt, car loan etc."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M4"} value={formData.M4} label={"Housing/Rent, Tax, Insurance"} name={"M4"} tooltip={"This includes your monthly rent or mortgage payment + property taxes + home insurance + home warranty etc. Include this for all your properties."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"T4"} value={formData.T4} label={"Max Debt Interest Rate (%)"}  name={"T4"} tooltip={"This is the highest interest rate on any of your loans or debts other than mortgage."} min={0} onChange={changeHandler} />
                            </div>

                           
                        </div>

                      
              <YourFHC R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} Slack={Slack}/>

                        
                        
                        
                    </div>
					<Input type={"hidden"} id = {"page_name"} value = {"Create a Budget"} name={"page_name"} onChange={changeHandler}/>
                </div>



                      <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                    <div className="shadow-md p-6 bg-white space-y-7 md:w-6/12">
                            <Heading text={"Monthly Fixed Costs"} icon={"financial-input"} />
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:px-0 px-10 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
                                <Input type={"number"} id={"F1"} name={"F1"} value={data.F1} min={0} label={"Transportation (car loan + gas)"} onChange={dataChangeHandler} />
                                <Input type={"number"} id={"F4"} name={"F4"} value={data.F4} min={0} label={"Any other (saving goals etc.)"} onChange={dataChangeHandler} />
                                <Input type={"number"} id={"F2"} name={"F2"} value={data.F2} min={0} label={"Food (groceries & related food)"} onChange={dataChangeHandler} />
                                <ResultInput type={"number"} name={"TFC"} label={"Total fixed Costs"} value={isNaN(resultData.TFC) || resultData.TFC === Infinity ? 0 : resultData.TFC.toFixed(0)} isLast />
                                <Input type={"number"} id={"F3"} name={"F3"} value={data.F3} min={0} label={"Utilities(electricity, gas, internet, etc.)"} onChange={dataChangeHandler} />
                                <ResultInput type={"number"} id={"AVS"}  name={"AVS"}  label={"Amt. available for value spending"} tooltip={"Monthly Income minus Debt Payments minus Mortgage or Rent minus Fixed Costs. This represents the money you have available every month to spend on things or goals that really matter to you."} value={isNaN(resultData.AVS) || resultData.AVS === Infinity ? 0 :  resultData.AVS.toFixed(0)} isLast/>
                            </div>
                            </div>
                        
                        
                        <div className="shadow-md p-6 bg-white space-y-6 md:w-6/12">
                            <Heading text={"Value Spending (monthly amounts)"} icon={"financial-input"} />
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20  ">
                                <Input type={"number"} id={"VS1"} name={"VS1"} value={data.VS1} label={"Books"} onChange={dataChangeHandler} />
                                <Input type={"number"} id={"VS4"} name={"VS4"} value={data.VS4} label={"Shopping"} onChange={dataChangeHandler} />
                                <Input type={"number"} id={"VS2"} name={"VS2"} value={data.VS2} label={"Travel"} onChange={dataChangeHandler} />
                                <Input type={"number"} id={"VS5"} name={"VS5"} value={data.VS5} label={"Send money to parents"} onChange={dataChangeHandler} />
                                <Input type={"number"} id={"VS3"} name={"VS3"} value={data.VS3} label={"Eating out"} onChange={dataChangeHandler} />
                                <Input type={"number"} id={"VS6"} name={"VS6"} value={data.VS6} label={"Others"} onChange={dataChangeHandler} />
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-1 md:ml-48">
                            <ResultInput name = {'TVC'} label={"Total Variable Costs"} value={isNaN(resultData.TVC) || resultData.TVC === Infinity ? 0 :  resultData.TVC.toFixed(0)} isLast />


                            </div>
                            {VPcheck ?
                                <div className="flex justify-center md:ml-50 mt-5 text-red-devil">
                                    <p>The Total Variable Costs should not exceed the amount available for value spending</p>
                                </div> : ''

                            }
                        </div>
                            

                        
                        
                      </div>
                      </div>




                      <div className="block md:flex   px-5">

               <div className="hidden md:block w-7/12">
               <PieGraph heading="Expenses & Surplus"  M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
                   </div>
                  <div className="md:hidden">
               <PieGraph heading="Expenses & Surplus"  M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
                 </div>
                 <div className="md:pl-14 mt-10 md:mt-24">
                    <div className="space-y-3">
                    { resultData.R1>Number(form1Data.DTI) ? <DescriptionItem falseText={"Your monthly Debt payments are high compared to your Income."} rightText={""} condition={resultData.R1 <= Number(form1Data.DTI)} /> : null}
                    {/*<br></br>*/}
                        {formData.T3 > ((Number(form1Data.DTA)/100) * (Number(formData.T1)) + Number(formData.T2)) ? <DescriptionItem falseText={`Use ${resultData.AVS} per month to bring down your debt to max ${Number(form1Data.DTA)}% of assets. This will take ${Math.abs(((Number(formData.T3) - (Number(form1Data.DTA)/100) * (Number(formData.T1) + Number(formData.T2))) / Number(resultData.AVS)).toFixed(0)) } months to achieve.`} rightText={""} condition={formData.T3< ((Number(form1Data.DTA)/100)*(Number(formData.T1))+Number(formData.T2))} /> : null}
                    {/*<br></br>*/}
                    { (resultData.R1<Number(form1Data.DTI) && formData.T3 < ((Number(form1Data.DTA)/100)*(Number(formData.T1))+Number(formData.T2))) ? <DescriptionItem falseText={""} rightText={"Your monthly Debt payments are manageable given your Income and your Total Debt is reasonable."} condition={resultData.R1<Number(form1Data.DTI) & formData.T3 < ((Number(form1Data.DTA)/100)*(Number(formData.T1))+Number(formData.T2)) } /> : null}
                    {/*<br></br>*/}
                        {!(isNaN(resultData.R2) || resultData.R2 === Infinity || resultData.R2 < 0) ? <DescriptionItem falseText={`You have less than ${Number(form1Data.EFT)} months of Total Savings to cover your expenses and debt obligations.`} rightText={`You have ${Number(form1Data.EFT)}+ months of Total savings to cover expenses and debt payments. Maintain this as your emergency fund.`} condition={(Number(resultData.R2) > (Number(form1Data.EFT) * 100 ))} /> : null}
                        {/*<br></br>*/}
                        {(Number(resultData.R2) < (Number(form1Data.EFT) * 100)) ? <DescriptionItem falseText={`Use ${resultData.AVS} per month to bring your savings up to ${Number(form1Data.EFT)} months of expenses. This will take ${Math.abs(((Number(formData.T1) - Number(form1Data.EFT) * (Number(formData.M2) + Number(formData.M3) + Number(formData.M4))) / Number(resultData.AVS)).toFixed(0))} months to achieve.`} rightText={""} condition={(Number(resultData.R2) > (Number(form1Data.EFT))* 100)} /> : null}
                        {resultData.AVS > 0 ? <DescriptionItem falseText={""} rightText={"Keep your AVS in a separate account."} condition={resultData.AVS > 0} /> : null} 
                    </div> 
                  </div> 

 </div> 

 {/*<div className="hidden md:block    ">*/}
 {/*              <PieGraph2 heading="Fixed Costs vs Surplus"  Surplus={resultData.Surplus} AVS={resultData.AVS} />*/}
 {/*                  </div>*/}
 {/*                 <div className="md:hidden">*/}
 {/*              <PieGraph2  heading="Fixed Costs vs Surplus"  Surplus={resultData.Surplus>0?resultData.Surplus:0} AVS={resultData.AVS>0?resultData.AVS:0} />*/}
 {/*                </div>*/}

                <div className="md:flex justify-between md:space-x-24 pt-10">
                    <div className=" w-full w-9/12 md:w-1/2 pt-5 md:block  justify-center px-0 md:px-0 ">
                        <PieGraph2 heading="Planned Expenses & Surplus" subheading= "Monthly Fixed Costs & Surplus" F1={data.F1} F2={data.F2} F3={data.F3} F4={data.F4} Surplus={resultData.Surplus} AVS={resultData.AVS} />
                    </div>
                    

                    <div className=" w-full w-9/12 md:w-1/2 pt-12 md:block  justify-center px-0 md:px-0 ">
                        <div className="pt-8">
                            {/*<div className="pt-6">*/}
                            <PieGraph4 subheading="Value Spending & Surplus" VS1={data.VS1} VS2={data.VS2} VS3={data.VS3} VS4={data.VS4} VS5={data.VS5} VS6={data.VS6} Surplus={resultData.Surplus} AVS={resultData.AVS} />
                            {/*</div>*/}
                        </div>
                            

                    </div>

                </div>


                  

                 <div className="md:flex justify-between md:space-x-24 pt-10">
<div className="w-full md:flex-1 p-5  shadow-md   ">
    <div className="px-0 md:px-20">

        <Heading text="Key Financial Metrics" classes={"items-center px-10 md:px-10 "} />
    </div>
    <div className="flex justify-center">

     
    </div>
    <div className="flex justify-center p-5 ">
<p className="hidden">{resultData.S=(Number(formData.M1)-Number(formData.M3)-Number(formData.M4)-Number(resultData.TFC)-Number(resultData.TVC))}</p>
        <div className=" md:w-1/2 md:px-0 px-10 space-y-7">
        <ResultInput label={'Debt / Income Ratio'} value={isNaN(resultData.R1) ? 0 : resultData.R1 === Infinity ? 'High' :resultData.R1.toFixed(0) +'%'} tooltip={"How much of your income is spent on Debt Payments? Recommended < 20%"} />
            <ResultInput label={'Expense Coverage Ratio'} value={isNaN(resultData.R2) ? 0 : resultData.R2 === Infinity ? 'High' : resultData.R2.toFixed(0) +'%'}  tooltip={"How many times does your savings cover monthly expenses(expenses, debt and rent)? Recommended>300% (3 months)"} />
            <ResultInput label={'Assets / Liabilities'} value={isNaN(resultData.R3) ? 0 : resultData.R3 === Infinity ? 'High' :  resultData.R3.toFixed(0)+'%'}  tooltip={"Ratio of what you have vs what you owe. Recommended>100% (positive net worth)"}/>
            <ResultInput label={'Assets / (Rent + Debt)'} value={isNaN(resultData.R4) ? 0 : resultData.R4 === Infinity ? 'High' :  resultData.R4.toFixed(0)+'%'} tooltip={"How many times can your obligations be covered by assets? Recommended > 1200% (one year) "} />
            <ResultInput label={'Housing Cost Ratio'} value={isNaN(resultData.R5) ? 0 : resultData.R5 === Infinity ? 'High' : resultData.R5.toFixed(0)+'%'} tooltip={"How much of your income is spent on housing costs? Recommended < 30% "}/>
            <ResultInput label={'Slack in Budget'} value={isNaN(resultData.S) || resultData.S=== Infinity  || Number(resultData.S)<0 ?0 :  resultData.S.toFixed(0)} isLast  tooltip={"This is the surplus each month which can be used for goal planning"}/>
    

        </div>

    </div>
</div>

<div className=" w-full w-9/12 md:w-1/2 pt-5 md:block  justify-center px-0 md:px-0 ">
                            <PieGraph heading="Expenses & Surplus"  M1={formData.M1}  M3={formData.M3} M4={formData.M4} slack={resultData.S} TFC={resultData.TFC} VS={resultData.TVC} />
                        </div>

</div>

                 <div className="pt-10">
                    <BottomNav />
                </div>

            </Container>
			</form>
        </div>
    );
};

export default VP;
