
import { Pie } from '@ant-design/plots';
import Heading from './Heading';


const PieGraphINV = ({ heading, subheading, ST, BD, RE, AL}) => {

   const data = [
    {
      type: "Stocks",
      value: Number(ST),
    },
    {
      type: "Bonds",
      value: Number(BD),
    },
    {
       type:"Real Estate",
       value:Number(RE)
    },
    {
      type: "Alternatives",
      value: Number(AL),
    },   

  ];


  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#5b67ea', 'rgba(64, 78, 237, 0.35)', '#9ea1c2', '#219F94'],
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name} \n {percentage} ({value}) ',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <div className='space-y-4'>
      {
        heading?
        <Heading text={heading} />:''
      }
      {
        subheading &&
        <div className='flex justify-center'>
          <h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>{subheading}</h2>
        </div>
      }
      <Pie {...config} />
    </div>
  );
};

export default PieGraphINV;