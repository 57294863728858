import React, { useEffect, useState, useRef, useCallback  } from "react";
import BottomNav from "../components/BottomNav";
import Container from "../components/Container";
import Description from "../components/Description";
import FinancialHealthCheckup from "../components/FinancialHealthCheckup";
import Heading from "../components/Heading";
import Input from "../components/Input";
import PieGraph from "../components/PieGraph";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Cell,Tooltip , CartesianGrid,ResponsiveContainer } from 'recharts';
import { kFormatter } from "../libs";
import ResultInput from "../components/ResultInput";
import { BarChart1 } from "../components/BarChart1";
import {useSelector,useDispatch} from 'react-redux'
import  { actions } from '../store/index'
import YourFHC from "../components/YourFHC";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faDownload } from '@fortawesome/free-solid-svg-icons'
 import { faYoutube } from '@fortawesome/free-brands-svg-icons'







const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#0A1827" textAnchor="middle" dy={value < 0 ? 16 : -6} >{`${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</text>;
};

const FHC = () => {
	
	
	
	
	  const form = useRef();
	  const [status, setStatus] = useState('null'); /*New change */	 
	  const sendEmail = (e) => {
		e.preventDefault();
		setStatus("sending"); /*New change */
		
		emailjs.sendForm('service_ykjjhjm', 'template_k11stse', form.current, 'wwqQKgCmrGkZJXbt6')
		  .then((result) => {
			  console.log(result.text);
			  setStatus("sent");
		  }, (error) => {
			  console.log(error.text);
			  setStatus("error");
		  });
	  };
		
    const counter=useSelector((state)=>state.formdata)
    const dispatch=useDispatch()
    const hasCodeExecuted = useRef(false);

    const [formData, setFormData] = useState({
        M1: null,
        M2: null,
        M3: null,
        M4: null,
        T1: null,
        T2: null,
        T3: null,
        T4: null,
      
    });

    const [resultData, setResultData] = useState({
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        R5: 0,
	    R6: 0, //Added to calculate Debt to Asset Ratio. -VN
	    R7: null //Added to calculate Debt to Asset Ratio. -VN
    });


    const [Slack, setSlack] = useState(0);
    const [Networth, setNetworth] = useState(0);
	const [inputValue, setInputValue] = useState('');
	const [testArray, setTestArray] = useState([]);
    const changeHandler = (e) =>  {        
		let formValues = {...formData, [e.target.id]: e.target.value}
		setFormData(formValues)
		sessionStorage.setItem('formData', JSON.stringify(formValues))
		
		setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
		
    };
	
	

    const update=()=>
    {
        Object.keys(counter).length>0?
        setFormData(counter):
        setFormData(formData)
    }
	
	const preventMinus = (e) => {
		console.log("preven minus " +e.charCode)
		console.log("e.key "+e.key)
		if (e.code === 'Minus') {
			console.log("prevent minus")
			e.preventDefault();
		}
	};
	const preventPasteNegative = (e) => {
		const clipboardData = e.clipboardData || window.clipboardData;
			const pastedData = parseFloat(clipboardData.getData('text'));

			if (pastedData < 0) {
				e.preventDefault();
			}
		};
	
	 function handleKeyPress() {
        console.log( "You pressed a key." )
    }
	
    useEffect(() => {
		document.title = 'SYMR | Know Your Metrics';		
			
		
        if (!hasCodeExecuted.current) {

            update()
            hasCodeExecuted.current = true;

            window.scrollTo(0, 0);

        }
		
        const { M1, M2, M3, M4, T1, T2, T3, T4 } = formData;
        setResultData({
            R1: (Number(M3) / Number(M1)) * 100,
            R2: Number(T1) / (Number(M2) + Number(M3) + Number(M4))*100,            
			R3: ((Number(T1) + Number(T2)) / (Number(T3)) * 100),	// Fixed to check if T3 is null. If null, the denominator is set to 1.	VN	
            R4: ((Number(T1)+Number(T2)) / (Number(M3) + Number(M4)) * 100), // Fixed to add assets T2 to the calculation. VN
            R5: (Number(M4) / Number(M1) * 100),
			R6: (Number(T3)/(Number(T1)+Number(T2)))*100, //New Result variable added to check the debt ratio if high. - VN
			R7: (Number(Slack)/Number(M1))*100  //New Result variable added to calculate the savings ratio. - VN
        });
        setCartData([
          
            {
            name: 'Total Savings',
            value: Number(T1),
            color: "green"
        },
        {
            name: 'Investments',
            value: Number(T2),
            color: "green"
        },
        {
            name: 'Total Debt',
            value: -Number(T3),
            color: "red"
        },
        {
            name: 'Mortgage',
            value: -Number(T4),
            color: "pink"
        },
        {
            name: 'Net Woth',
            value: Number(Networth),
            color: "green"
        }
    
    ]);
    dispatch(actions.updateFormData(formData))
	console.log("formData.M1 "+formData.M1)
	
	//	setTestArray(newArray);
        
    // alert(JSON.stringify(counter))

        setSlack(Number(M1) - (Number(M2) + Number(M3) + Number(M4)));
        setNetworth((Number(T1) + Number(T2)) - Number(T3));
        
    }, [formData, Slack,Networth]);

	useEffect(() => {
		if (sessionStorage.getItem('formData')){
				setFormData(JSON.parse(sessionStorage.getItem('formData')))
			}	
	},[])	

    const [chartData, setCartData] = useState([{
        name: 'Total Savings',
        value: Number(formData.T1),
        color: "green"
    },
    {
        name: 'Investments',
        value: Number(formData.T2),
        color: "green"
    },
    {
        name: 'Total Debt',
        value: -Number(formData.T3),
        color: "red"
    },
    {
        name: 'Mortgage',
        value: -Number(formData.T4),
        color: "pink"
    }]);

    return (
        <div className="w-full" id = "FHC_page">
			<form ref={form} onSubmit={sendEmail}>
            <div className="bg-gradient-to-b h-72 from-primary to-patric-blue">
                <Container classes={"flex flex-col p-4 px-0 space-y-6 md:space-y-0 md:py-0 md:flex-row items-center w-full justify-between"}>
					<div >
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-6 h-auto " >
							<div>
								<Link to={'/'}>
								<img src="./assets/images/LOGO_White.png" alt="logo" className = "object-scale-down w-[160px] h-[160px]" />
								</Link>								
							</div>
						</div>
					</div>	
                    
					<div className=" -ml-24 " >
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-6 mt-10 h-auto " >
							<a href="https://www.loom.com/share/33fcfbba490641f8abbde91adb68d924?sid=7a09cd35-b927-438b-9de1-e3aaad509724" download="SYMR-Glossary" target='_blank'>
							 <center><FontAwesomeIcon icon={faYoutube} style={{color: "white", fontSize: '18px',  marginTop: '5px' }} /> How-To  </center> 
							</a>
						</div>
						<div className="w-auto py-1 mt-[5%] text-white text-l bg-[#646cff] h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<a href="https://drive.google.com/file/d/16K6MSx9zpJxhAGi5ohJvTqZO_qiLoZww/view" download="SYMR-Glossary" target='_blank'>
							 <center> <FontAwesomeIcon icon={faDownload} style={{color: "white", fontSize: '20px'}} />  Glossary  </center> 
							</a>
						</div>
					</div>	
					
					<div className=" -ml-24 ">
						<div className="w-auto py-1 mt-[3%]  text-white text-l rounded-lg px-6 mt-12 h-auto " >
							<h1 className="text-white font-bold text-center text-2xl md:text-4xl">Know Your Metrics </h1>
						</div>
						<div className="w-auto py-1 mt-[6%] text-white text-l h-[50%]  rounded-lg px-6 mt-12 h-auto " >
							<div className="text-white  text-center mb:mx-0 mx-5 md:ml-30 -mt-3 md:-mt-7 text-lg md:text-2xl">See where you stand today. Awareness is where it all begins</div> 
						</div>
					</div>	
					
					
                    
					<div className="-ml-24 w-full md:w-auto cursor-pointer">
						
						<div className="w-auto py-1 mt-[3%]  text-white text-l bg-[#646cff] rounded-lg px-3 mt-10 h-auto cursor-pointer " >
							<input type="submit" className = "cursor-pointer" value="Send my Inputs" />
						</div>	
							{status === "sending" && (
								<div style={{ color: "white" }}>Sending...</div>
								)
							}
							{status === "sent" && (
								<div style={{ color: "green" }}>Data sent!</div>
							)
							}
							{status === "error" && (
								<div style={{ color: "red" }}>Failed to send data.</div>
							)
							
							}
                    </div>
					<div className="-ml-12 w-full md:w-auto">
					</div>
                </Container>
                    
            
			
			</div>
			
			
			
            <Container classes={"-mt-24 md:-mt-32 py-10 space-y-6"}>
            <div className="flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-6">
                    <div className="flex-1 w-full md:flex md:space-x-10 md:space-y-0 space-y-10 ">
                        <div className="shadow-md p-6 bg-white space-y-6 md:w-1/2">
                            <Heading text={"Basic Financial Inputs"} icon={"financial-input"} />
										
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-y-8 lg:gap-x-20">
								
                                <Input type={"number"} id={"M1"} value={formData.M1} label={"Monthly Income"} tooltip={"Enter your total monthly income from all sources: salary, business income, rental income etc."} name={"M1"} onChange={changeHandler} onKeyDown = {preventMinus} onPaste={preventPasteNegative} required />
                                <Input type={"number"} id={"T1"} value={formData.T1} label={"Total Savings (Liquid Funds)"} tooltip={"Refers to readily accessible funds held in cash or highly liquid assets, such as savings accounts or money market accounts, that can be quickly and easily utilized for immediate financial needs or emergencies."} name={"T1"} min={0} onChange={changeHandler}/>
                                <Input type={"number"} id={"M2"} value={formData.M2} label={"Monthly Expenses"} tooltip={"Your total monthly expenses for everything except loans and debt payments and rent. It should include everything else such as food, utilities, shopping, healthcare, transportation, education etc."} name={"M2"} min={0} onChange={changeHandler} required/>
                                <Input type={"number"} id={"T2"} value={formData.T2} label={"Total Investments (Non-Cash)"} name={"T2"} tooltip={"Total amount invested in stocks, bonds, 401K, IRA, real estate, or other financial instruments."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/}
                                <Input type={"number"} id={"M3"} value={formData.M3} label={"Monthly Debt Payments"} name={"M3"} tooltip={"Monthly payments you make for all loans such as credit card, car payments, etc."} min={0} onChange={changeHandler} /> {/* VN - 13Jul*/ }
                                <Input type={"number"} id={"T3"} value={formData.T3} label={"Total Debt (Except Mortgage)"} name={"T3"} tooltip={"Total amount of debt you need to repay. This includes credit card debt, car loan etc."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"M4"} value={formData.M4} label={"Housing/Rent, Tax, Insurance"} name={"M4"} tooltip={"This includes your monthly rent or mortgage payment + property taxes + home insurance + home warranty etc. Include this for all your properties."} min={0} onChange={changeHandler} />
                                <Input type={"number"} id={"T4"} value={formData.T4} label={"Max Debt Interest Rate (%)"}  name={"T4"} tooltip={"This is the highest interest rate on any of your loans or debts other than mortgage."} min={0} onChange={changeHandler} />
                            </div>
                        
                        </div>

						<YourFHC R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} Slack={Slack}/>                   
                    </div>
					<input type={"hidden"} id = {"page_name"} value = {"Know Your Metrics"} name={"page_name"} onChange={changeHandler}/>
					
            </div>

            <div className="block md:flex pt-10 px-5">
				<div className="hidden md:block w-7/12">
					<PieGraph heading="Monthly Expenses vs Savings" M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
				</div>
				<div className="md:hidden">
					<PieGraph heading="Monthly Expenses vs Savings"  M1={formData.M1} M2={formData.M2} M3={formData.M3} M4={formData.M4} />
				</div>
				<div className="md:pl-14 mt-10 md:mt-24">
					<Description R1={resultData.R1} R2={resultData.R2} R3={resultData.R3} R4={resultData.R4} R5={resultData.R5} R6={resultData.R6} R7={resultData.R7} Networth={Networth} Slack = {Slack} /> {/* Added R6, R7 to print the recommendation based on Debt/Asset ratio & Savings ratio. -VN */}
				</div>
			</div>
			<div className="space-y-10">
				<div className='flex justify-center'>
					<h2 className='bg-primary/10 text-primary rounded-xl py-2 px-4 text-base md:text-xl'>Total Assets vs Liabilities</h2>
				</div>
				<div className="bg-primary/5  rounded-xl p-4">
					<div className='w-full h-full'>
					   
				<BarChart1 T1={formData.T1} T2={formData.T2} T3={formData.T3} T4={formData.T4} Net={Networth}/>
					</div>
				</div>
				<BottomNav />
			</div>
            </Container>
		</form>	
        </div>
    );
};
export default FHC;
